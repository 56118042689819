import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type Dayjs } from 'dayjs'
import {
  Button,
  IconButton,
  MenuItem,
  Stack,
  Select,
  type SelectChangeEvent,
  useTheme,
  Box
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import AddIcon from '@mui/icons-material/Add'
import { RefreshButton, BooleanInput } from '@r40cap/ui'
import { useAuth } from '@r40cap/auth'
import {
  type LiteStrategy,
  type LiteDesk
} from '@r40cap/pms-sdk'

import BlotterViewSelection from './BlotterViewSelection'
import { TagContent } from './modals'
import { timingOptions } from './constants'
import {
  type BlotterOption,
  type TimingOption,
  type BlotterFilterType,
  type BlotterFilterPiece,
  TimingType
} from './types'
import type { FilterPiece } from '../common/types'
import {
  AccountFilterContent,
  BaseFxFilterContent,
  DeskFilterContent,
  FilterDescription,
  InstrumentFilterContent,
  StrategyFilterContent,
  TransactionSuperTypeFilterContent
} from './filters'
import ExportModalContent from './modals/ExportModalContent'

function TimingSelection (props: {
  timing: TimingOption
  timingChangeFunction: (option: TimingOption) => void
}): React.JSX.Element {
  const { timing, timingChangeFunction } = props

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const chosenOption = timingOptions.find((option) => option.value === event.target.value)
    if (chosenOption !== undefined) {
      timingChangeFunction(chosenOption)
    }
  }

  return (
    <Select
      value={timing.value}
      onChange={handleTypeChange}
      sx={{ width: '100%' }}
      color="accent"
    >
      {timingOptions.map(
        (option, index) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  )
}

function BlotterFilter (props: {
  openModal: (content: React.JSX.Element) => void
  closeModal: () => void
  addFilter: (piece: BlotterFilterPiece) => void
  clearFilter: (type: BlotterFilterType) => void
  existingFilters: BlotterFilterPiece[]
  untaggedOnly: boolean
  setUntaggedOnly: (value: boolean) => void
  includeFees: boolean
  setIncludeFees: (value: boolean) => void
}): React.JSX.Element {
  const {
    openModal,
    closeModal,
    addFilter,
    clearFilter,
    existingFilters,
    untaggedOnly,
    setUntaggedOnly,
    includeFees,
    setIncludeFees
  } = props
  const authContext = useAuth()
  const { palette } = useTheme()

  return (
    <Stack direction={'column'} spacing={1} sx={{ width: '100%', height: '100%', maxHeight: '100%' }}>
      <Stack direction={'row'} spacing={1} sx={{ width: '100%', height: '50%', maxHeight: '50%' }}>
        <Button
          variant='contained'
          sx={{
            color: palette.accent.main,
            fontSize: 10,
            width: '100%'
          }}
          onClick={() => {
            openModal(<BaseFxFilterContent
              submitFunction={(piece: FilterPiece) => {
                addFilter({ piece, type: 'baseFx' })
                closeModal()
              }}
            />)
          }}
        >
          Add BaseFX Filter
        </Button>
        <Button
          variant='contained'
          sx={{
            color: palette.accent.main,
            fontSize: 10,
            width: '100%'
          }}
          onClick={() => {
            openModal(<InstrumentFilterContent
              submitFunction={(piece: FilterPiece) => {
                addFilter({ piece, type: 'instrument' })
                closeModal()
              }}
            />)
          }}
        >
          Add Instrument Filter
        </Button>
        <Button
          variant='contained'
          sx={{
            color: palette.accent.main,
            fontSize: 10,
            width: '100%'
          }}
          onClick={() => {
            openModal(<AccountFilterContent
              submitFunction={(piece: FilterPiece) => {
                addFilter({ piece, type: 'account' })
                closeModal()
              }}
            />)
          }}
        >
          Add Account Filter
        </Button>
        <Button
          variant='contained'
          sx={{
            color: palette.accent.main,
            fontSize: 10,
            width: '100%'
          }}
          onClick={() => {
            openModal(<StrategyFilterContent
              submitFunction={(piece: FilterPiece) => {
                addFilter({ piece, type: 'strategy' })
                closeModal()
              }}
            />)
          }}
        >
          Add Strategy Filter
        </Button>
        {
          authContext.restrictedDeskId === undefined && authContext.restrictedBlotterDeskId === undefined && <Button
            variant='contained'
            sx={{
              color: palette.accent.main,
              fontSize: 10,
              width: '100%'
            }}
            onClick={() => {
              openModal(<DeskFilterContent
                submitFunction={(piece: FilterPiece) => {
                  addFilter({ piece, type: 'desk' })
                  closeModal()
                }}
              />)
            }}
          >
            Add Desk Filter
          </Button>
        }
        <Button
          variant='contained'
          sx={{
            color: palette.accent.main,
            fontSize: 10,
            width: '100%'
          }}
          onClick={() => {
            openModal(<TransactionSuperTypeFilterContent
              submitFunction={(piece: FilterPiece) => {
                addFilter({ piece, type: 'transactionSuperType' })
                closeModal()
              }}
            />)
          }}
        >
          Add Transaction Type Filter
        </Button>
        <Box
          width={'100%'}
          alignContent={'center'}
          justifyContent={'center'}
          sx={{ padding: 0, margin: 0 }}
        >
          <Stack direction={'column'} spacing={0}>
            <BooleanInput
              label='Untagged?'
              value={untaggedOnly}
              setValue={setUntaggedOnly}
              boxColor={palette.accent.main}
              textColor={palette.tableBodyText.main}
              fontSize={12}
              size='small'
            />
            <BooleanInput
              label='Fees?'
              value={includeFees}
              setValue={setIncludeFees}
              boxColor={palette.accent.main}
              textColor={palette.tableBodyText.main}
              fontSize={12}
              size='small'
            />
          </Stack>
        </Box>
      </Stack>
      <Stack direction={'row'} spacing={1} sx={{ width: '100%', height: '50%' }}>
        {
          existingFilters.filter((filt) => filt.type === 'baseFx').length > 0 && <FilterDescription
            name='BaseFx'
            values={
              existingFilters.filter((filt) => filt.type === 'baseFx').map((val) => val.piece)}
            clearFunction={() => { clearFilter('baseFx') }}
          />
        }
        {
          existingFilters.filter((filt) => filt.type === 'instrument').length > 0 && <FilterDescription
            name='Instrument'
            values={
              existingFilters.filter((filt) => filt.type === 'instrument').map((val) => val.piece)}
            clearFunction={() => { clearFilter('instrument') }}
          />
        }
        {
          existingFilters.filter((filt) => filt.type === 'account').length > 0 && <FilterDescription
            name='Account'
            values={
              existingFilters.filter((filt) => filt.type === 'account').map((val) => val.piece)}
            clearFunction={() => { clearFilter('account') }}
          />
        }
        {
          existingFilters.filter((filt) => filt.type === 'strategy').length > 0 && <FilterDescription
            name='Strategy'
            values={
              existingFilters.filter((filt) => filt.type === 'strategy').map((val) => val.piece)}
            clearFunction={() => { clearFilter('strategy') }}
          />
        }
        {
          existingFilters.filter((filt) => filt.type === 'desk').length > 0 && <FilterDescription
            name='Desk'
            values={
              existingFilters.filter((filt) => filt.type === 'desk').map((val) => val.piece)}
            clearFunction={() => { clearFilter('desk') }}
          />
        }
        {
          existingFilters.filter((filt) => filt.type === 'transactionSuperType').length > 0 && <FilterDescription
            name='Type'
            values={
              existingFilters.filter((filt) => filt.type === 'transactionSuperType').map((val) => val.piece)}
            clearFunction={() => { clearFilter('transactionSuperType') }}
          />
        }
      </Stack>
    </Stack>
  )
}

function BlotterControlsPanel (props: {
  viewType: BlotterOption
  setViewType: (type: BlotterOption) => void
  refreshFunction: () => void
  openModal: (content: React.JSX.Element) => void
  closeModal: () => void
  showTag: boolean
  tagRequest: (strategyId: string, deskId: string) => void
  time: Dayjs | null
  setTime: (value: Dayjs | null) => void
  addFilter: (piece: BlotterFilterPiece) => void
  clearFilter: (type: BlotterFilterType) => void
  existingFilters: BlotterFilterPiece[]
  untaggedOnly: boolean
  setUntaggedOnly: (value: boolean) => void
  includeFees: boolean
  setIncludeFees: (value: boolean) => void
}): React.JSX.Element {
  const {
    refreshFunction,
    openModal,
    closeModal,
    viewType,
    setViewType,
    showTag,
    tagRequest,
    time,
    setTime,
    addFilter,
    clearFilter,
    existingFilters,
    untaggedOnly,
    setUntaggedOnly,
    includeFees,
    setIncludeFees
  } = props
  const navigate = useNavigate()
  const { palette } = useTheme()
  const [timingOption, setTimingOption] = useState<TimingOption>(timingOptions[0])

  useEffect(() => {
    setTime(null)
  }, [timingOption])

  const styles = {
    dateTimePickerContainer: {
      height: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        height: '100%',
        width: '100%'
      },
      '& .MuiInputBase-root': {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }
    }
  }

  return (
    <Stack direction={'row'} spacing={1} sx={{ width: '100%', height: '100%' }}>
      <Stack direction={'column'} spacing={1} sx={{ width: '25%', height: '100%', maxHeight: '100%' }}>
        <Stack direction={'row'} spacing={1} sx={{ width: '100%', height: '50%', maxHeight: '50%' }}>
          <BlotterViewSelection
            currentType={viewType}
            typeChangeFunction={setViewType}
          />
          {
            viewType !== 'loans'
              ? <TimingSelection
                  timing={timingOption}
                  timingChangeFunction={setTimingOption}
                />
              : <Box sx={{ width: '100%' }}/>
          }
        </Stack>
        <Stack direction={'row'} spacing={1} sx={{ width: '100%', height: '50%' }}>
          {
            timingOption.type === TimingType.TIME
              ? <Box sx={styles.dateTimePickerContainer}>
                  <DateTimePicker
                    value={time}
                    onChange={setTime}
                    format='YYYY-MM-DD HH:mm'
                    closeOnSelect={false}
                    ampm={false}
                    timeSteps={{ hours: 1, minutes: 1 }}
                  />
                </Box>
              : <Box sx={{ width: '100%' }}/>
          }
        </Stack>
      </Stack>
      <Box sx={{ width: '58%', height: '100%' }}>
        {
          viewType === 'transactions' && <BlotterFilter
            openModal={openModal}
            closeModal={closeModal}
            addFilter={addFilter}
            clearFilter={clearFilter}
            existingFilters={existingFilters}
            untaggedOnly={untaggedOnly}
            setUntaggedOnly={setUntaggedOnly}
            includeFees={includeFees}
            setIncludeFees={setIncludeFees}
          />
        }
      </Box>
      <Stack
        direction={'row'}
        spacing={1}
        padding={0}
        alignSelf={'flex-end'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        sx={{ height: '50%', width: '17%' }}
      >
        {
          showTag && <Button
            variant='outlined'
            sx={{ color: palette.accent.main, height: '50%' }}
            onClick={() => {
              openModal(<TagContent
                closeModal={closeModal}
                submit={(strategy: LiteStrategy, desk: LiteDesk) => {
                  tagRequest(strategy.id, desk.id)
                }}
                prefillStrategyId={null}
              />)
            }}
          >
            Tag
          </Button>
        }
        <Button
          variant='outlined'
          sx={{ color: palette.accent.main, height: '50%' }}
          onClick={() => {
            openModal(<ExportModalContent />)
          }}
        >
          Export
        </Button>
        <IconButton
          onClick={() => { navigate(`/blotter/${viewType}/new`) }}
          sx={{ height: '50%' }}
        >
          <AddIcon />
        </IconButton>
        <RefreshButton
          refreshFunction={refreshFunction}
          iconColor={palette.primary.main}
          buttonColor={palette.accent.main}
        />
      </Stack>
    </Stack>
  )
}

export default BlotterControlsPanel
