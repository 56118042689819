export function getCurrencyPairForTicker (ticker: string): string {
  if (ticker.endsWith('SWAP')) {
    const splitRes = ticker.split('-', 3)
    return `${splitRes[0]}-${splitRes[1]}`
  } else {
    const splitRes = ticker.split('-', 3)
    return `${splitRes[0]}-${splitRes[1]}`
  }
}

export function getSettlementCurrencyForTicker (ticker: string): string {
  if (ticker.endsWith('SWAP')) {
    const splitRes = ticker.split('-', 3)
    const quoteCcy = splitRes[1]
    if (quoteCcy === 'USD') {
      return splitRes[0]
    } else {
      return quoteCcy
    }
  } else {
    const splitRes = ticker.split('-', 3)
    return splitRes[1]
  }
}

export function getBaseCurrencyForTicker (ticker: string): string {
  if (ticker.endsWith('SWAP')) {
    const splitRes = ticker.split('-', 2)
    return splitRes[0]
  } else {
    const splitRes = ticker.split('-', 2)
    return splitRes[0]
  }
}

export function getQuoteCurrencyForTicker (ticker: string): string {
  if (ticker.endsWith('SWAP')) {
    const splitRes = ticker.split('-', 3)
    return splitRes[1]
  } else {
    const splitRes = ticker.split('-', 3)
    return splitRes[1]
  }
}

export function isInverse (ticker: string): boolean { return getSettlementCurrencyForTicker(ticker) === 'USD' }

function isNumericChar (char: string): boolean { return /\d/.test(char) }

function getMonthNumberFromString (monthDescription: string): number {
  switch (monthDescription) {
    case 'JAN': return 0
    case 'FEB': return 1
    case 'MAR': return 2
    case 'APR': return 3
    case 'MAY': return 4
    case 'JUN': return 5
    case 'JUL': return 6
    case 'AUG': return 7
    case 'SEP': return 8
    case 'OCT': return 9
    case 'NOV': return 10
    case 'DEC': return 11
    default: return 0
  }
}

export function getExpiryTimestampMsForTicker (ticker: string): number {
  const expiryDescription = ticker.split('-', 3)[1]
  const isTwoDigitDay = isNumericChar(expiryDescription.charAt(1))
  const day = isTwoDigitDay ? parseInt(expiryDescription.slice(0, 2)) : parseInt(expiryDescription.charAt(0))
  const monthDescription = isTwoDigitDay ? expiryDescription.slice(2, 5) : expiryDescription.slice(1, 4)
  const yearLastTwoDigits = isTwoDigitDay ? parseInt(expiryDescription.slice(5)) : parseInt(expiryDescription.slice(4))
  const month = getMonthNumberFromString(monthDescription)
  const year = 2000 + yearLastTwoDigits
  const timestampMs = Date.UTC(year, month, day, 8, 0, 0, 0)
  return timestampMs
}

export function getStrikeForTicker (ticker: string): number {
  const strikeDesc = ticker.split('-', 4)[2]
  const strikeStr = strikeDesc.replace('d', '.')
  return parseFloat(strikeStr)
}

export function getPriceCurrencyDisplayForTicker (ticker: string): string {
  if (ticker.endsWith('SWAP')) {
    const splitRes = ticker.split('-', 3)
    return splitRes[1]
  } else {
    const splitRes = ticker.split('-', 3)
    return splitRes[1]
  }
}
