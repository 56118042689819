import React from 'react'
import {
  TableRow,
  useTheme
} from '@mui/material'
import { type ColumnDefinition, ValueCell } from '@r40cap/ui'

import type { AlgoOrderRow } from '../types'

function AlgoRow (props: {
  order: AlgoOrderRow
  columns: Array<ColumnDefinition<AlgoOrderRow, any>>
}): React.JSX.Element {
  const {
    order,
    columns
  } = props
  const { palette } = useTheme()

  return (
    <TableRow>
      {
        columns.map((column, idx) => (
          <ValueCell<AlgoOrderRow, any>
            column={column}
            item={order}
            key={column.id}
            defaultTextColor={palette.tableBodyText.main}
            redTextColor='red'
            greenTextColor='green'
            dense
          />
        ))
      }
    </TableRow>
  )
}

export default AlgoRow
