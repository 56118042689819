import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import AddIcon from '@mui/icons-material/Add'
import {
  EditableTable,
  Modal,
  ModalOpenButton,
  StringInput,
  useRequestSnackbar
} from '@r40cap/ui'
import {
  type LiteAccount,
  AccountInput,
  type LiteEntity,
  type LiteInstrument,
  EntityInput,
  type ExpenseCreation,
  type TransactionAssetChange,
  TransactionAssetChangeCreator,
  type TransactionCreation,
  type LiteDesk,
  type LiteStrategy,
  DeskInput,
  StrategyInput,
  blotterApi
} from '@r40cap/pms-sdk'

import type {
  AddAssetChangeRow,
  BlotterInputType,
  ExpenseAdditionSelectOption
} from '../common/types'
import { transactionAssetChangeColumns } from '../details/constants'
import { getBlotterEditModalContent } from '../utils'
import { expenseSelectOptions } from '../common/constants'
import LegInput from '../common/BlotterInputs/LegInput'
import { isApiError } from '../../../utils/errors'

function Custom (props: {
  submit: (expense: ExpenseCreation) => void
}): React.JSX.Element {
  const { submit } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [vendor, setVendor] = useState<LiteEntity | null>(null)
  const [reason, setReason] = useState<string>('')
  const [assetChanges, setAssetChanges] = useState<readonly TransactionAssetChange[]>([])

  const [assetChangeAddModalOpen, setAssetChangeAddModalOpen] = useState<boolean>(false)
  const [selectedAssetChangeIds, setSelectedAssetChangeIds] = useState<readonly string[]>([])

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function appendAssetChange (assetChange: TransactionAssetChange): void {
    setAssetChanges(assetChanges.concat(assetChange))
    setAssetChangeAddModalOpen(false)
  }

  const getAssetChangePreset = (rowId: string, property: keyof TransactionAssetChange): any => {
    const relevantObject = assetChanges.find((dataRow: TransactionAssetChange) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  const handleAssetChangeEdit = (property: keyof TransactionAssetChange, value: any, selected: readonly string[]): void => {
    const updatedData = assetChanges.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    setAssetChanges(updatedData)
  }

  function handleSubmission (): void {
    if (
      vendor !== null &&
      time !== null
    ) {
      const txnId = uuidv4()
      const expenseId = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: undefined,
        expectedSettlementTime: undefined,
        comments: `${vendor.name} Expense: ${reason}`,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges: assetChanges.map((value) => value),
        openedLiabilities: [],
        closedLiabilities: []
      }
      const expense: ExpenseCreation = {
        id: `new-${expenseId}`,
        vendor,
        reason,
        transactions: [txn]
      }
      submit(expense)
    }
  }

  return (
    <Stack direction={'column'} spacing={1}>
      <Stack direction={'row'} sx={{ width: '100%' }}>
        <Box sx={{ width: '50%' }}>
          <EntityInput
            setEntity={setVendor}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            labelOverride='Vendor'
            enhanced
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography>Time</Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
      <StringInput
        value={reason}
        setValue={setReason}
        title='Reason'
        color={palette.accent.main}
      />
      <Box sx={{ width: '100%' }} textAlign={'right'}>
        <ModalOpenButton
          modalOpen={assetChangeAddModalOpen}
          setModalOpen={setAssetChangeAddModalOpen}
          modalContents={<Box
            sx={{
              width: '40vw',
              padding: '40px'
            }}
          >
            <TransactionAssetChangeCreator
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              callColor={palette.success.main}
              putColor={palette.error.main}
              submitFunction={appendAssetChange}
              includeBaseFxFilter
              includeEntityFilter
              includeInstrumentTypeFilter
              includeSuperStrategyFilter
              enhanced
            />
          </Box>}
        >
          <AddIcon />
        </ModalOpenButton>
      </Box>
      <Box
        sx={{ height: '40vh' }}
      >
        <EditableTable<AddAssetChangeRow, TransactionAssetChange, BlotterInputType, string>
          rows={assetChanges.map((assetChange) => {
            return {
              id: assetChange.id,
              time: assetChange.time,
              settledTime: assetChange.settledTime ?? '',
              account: `${assetChange.account.platform.name} - ${assetChange.account.name}`,
              strategy: (
                assetChange.strategy === undefined || assetChange.strategy === null
                  ? ''
                  : `${assetChange.strategy.superStrategy.name} - ${assetChange.strategy.name}`
              ),
              desk: assetChange.desk?.name ?? '',
              instrument: assetChange.instrument.displayTicker,
              quantity: assetChange.quantity,
              price: assetChange.price,
              isFee: assetChange.isFee,
              quantityDecimals: assetChange.instrument.quantityDecimals,
              priceDecimals: assetChange.instrument.priceDecimals,
              transactionType: assetChange.transactionType?.name ?? ''
            }
          })}
          columns={transactionAssetChangeColumns}
          selected={selectedAssetChangeIds}
          setSelected={setSelectedAssetChangeIds}
          defaultOrderBy={'instrument'}
          defaultOrder={'desc'}
          isFetching={false}
          getPreset={getAssetChangePreset}
          getModalContent={(
            inputType: BlotterInputType,
            label: string,
            editProperty: keyof TransactionAssetChange,
            closeModal: () => void,
            newSelected: readonly string[],
            initialValue: any
          ) => {
            return getBlotterEditModalContent<TransactionAssetChange>(
              inputType,
              label,
              editProperty,
              closeModal,
              handleAssetChangeEdit,
              newSelected,
              initialValue
            )
          }}
          checkboxColor={palette.accent.main}
          backgroundColor={palette.primary.main}
          headerBackgroundColor={palette.background.default}
          headerTextColor={palette.primary.main}
          headerActiveTextColor={palette.accent.main}
          bodyDefaultTextColor={palette.tableBodyText.main}
          bodyPrimaryBackgroundColor={palette.tertiary.main}
          redTextColor='red'
          greenTextColor='green'
          dense
        />
      </Box>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

function BasicExpense (props: {
  submit: (expense: ExpenseCreation) => void
}): React.JSX.Element {
  const { submit } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [vendor, setVendor] = useState<LiteEntity | null>(null)
  const [account, setAccount] = useState<LiteAccount | null>(null)
  const [desk, setDesk] = useState<LiteDesk | null>(null)
  const [strategy, setStrategy] = useState<LiteStrategy | null>(null)
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [reason, setReason] = useState<string>('')

  const [instrument, setInstrument] = useState<LiteInstrument | null>(null)
  const [amountStr, setAmountStr] = useState<string>('')
  const [priceStr, setPriceStr] = useState<string>('')

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function handleSubmission (): void {
    if (
      time !== null &&
      vendor !== null &&
      account !== null &&
      instrument !== null &&
      amountStr !== ''
    ) {
      const txnId = uuidv4()
      const expenseId = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: undefined,
        expectedSettlementTime: undefined,
        comments: `${vendor.name} Fee: ${reason}`,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges: [
          {
            id: `new-${txnId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            desk: desk ?? undefined,
            strategy: strategy ?? undefined,
            instrument,
            quantity: -1 * Math.abs(parseFloat(amountStr)),
            price: Math.abs(parseFloat(priceStr)),
            isFee: false
          }
        ],
        openedLiabilities: [],
        closedLiabilities: []
      }
      const expenseCreation: ExpenseCreation = {
        id: `new-${expenseId}`,
        vendor,
        reason,
        transactions: [txn]
      }
      submit(expenseCreation)
    }
  }

  return (
    <Stack spacing={1}>
      <Grid spacing={1} container sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography>Time</Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <EntityInput
            setEntity={setVendor}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            labelOverride='Vendor'
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <AccountInput
            setAccount={setAccount}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            includeEntityFilter
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <StringInput
            value={reason}
            setValue={setReason}
            title='Reason'
            color={palette.accent.main}
          />
        </Grid>
        <Grid item xs={6}>
          <StrategyInput
            setStrategy={setStrategy}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            includeSuperStrategyFilter
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <DeskInput
            setDesk={setDesk}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Expense'
            amountStr={amountStr}
            setAmountStr={setAmountStr}
            priceStr={priceStr}
            setPriceStr={setPriceStr}
            setInstrument={setInstrument}
          />
        </Grid>
      </Grid>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

function AddExpenseModal (): React.JSX.Element {
  const { showSnackbar } = useRequestSnackbar()
  const navigate = useNavigate()
  const [viewOption, setViewOption] = useState<ExpenseAdditionSelectOption | undefined>(expenseSelectOptions.at(0))
  const [postAdditions] = blotterApi.useAddExpensesMutation()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/blotter/expenses')
    }
  }

  function addExpense (expense: ExpenseCreation): void {
    showSnackbar({
      isOpen: true,
      message: 'Pushing Expense',
      status: 'processing'
    })
    postAdditions({ additions: [expense] })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Expense Added',
            status: 'success'
          })
          exitModal()
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to add Expense',
          status: 'error'
        })
      })
  }

  let content: React.JSX.Element
  switch (viewOption?.type) {
    case 'basicExpense':
      content = <BasicExpense submit={addExpense}/>
      break
    default:
      content = <Custom submit={addExpense}/>
  }

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const newType = event.target.value
    const targetOption = expenseSelectOptions.find((option) => option.type === newType)
    setViewOption(targetOption)
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: `${viewOption?.width ?? 90}vw`,
          maxHeight: '95vh',
          overflow: 'scroll'
        }}
      >
        <Stack direction={'column'} padding={3} spacing={1}>
          <Box textAlign={'left'} sx={{ width: '100%' }}>
            <Select
              value={viewOption?.type ?? 'basicLoan'}
              onChange={handleTypeChange}
              sx={{ width: '15vw' }}
              color="accent"
            >
              {
                expenseSelectOptions.map(
                  (option, i) => {
                    return (
                      <MenuItem value={option.type} key={i}>
                        {option.display}
                      </MenuItem>
                    )
                  }
                )
              }
            </Select>
          </Box>
          {content}
        </Stack>
      </Box>
    </Modal>
  )
}

export default AddExpenseModal
