import React, { useEffect, useMemo, useState, useRef } from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import {
  type ColumnDefinition,
  RowSkeleton,
  getComparator,
  stableSort
} from '@r40cap/ui'
import { type Algo, type AlgoOrder } from '@r40cap/algos-sdk'

import type { AlgoExecutionRow, AlgoExecutionPlusOrders } from './types'
import TableErrorBody from '../utils/TableErrorBody'
import ExecutionRow from './rows/ExecutionRow'
import { getExecutionRowsForAlgo } from './matching'
import AlgoExecutionsSummaryFooter from './AlgoExecutionSummaryFooter'
import AlgoBotterTableHeader from './AlgoBlotterTableHeader'

function getExecutionRows (orders: AlgoOrder[], algoMap?: Map<string, Algo>): AlgoExecutionPlusOrders[] {
  if (algoMap === undefined) {
    return []
  }
  const uniqueAlgos = new Set(orders.map((order) => order.algo))
  const rows: AlgoExecutionPlusOrders[] = []
  for (const algo of Array.from(uniqueAlgos.values())) {
    const algoOrders = orders.filter((order) => order.algo === algo)
    rows.push(...getExecutionRowsForAlgo(algoOrders, algoMap))
  }
  return rows
}

function AlgoExecutionTableBody (props: {
  executionRows: AlgoExecutionPlusOrders[]
  numOrders: number
  columns: Array<ColumnDefinition<AlgoExecutionRow, any>>
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
  isLoading: boolean
  openedExecs: readonly string[]
  setOpenedExecs: (execs: readonly string[]) => void
  selectedIds: readonly string[]
  setSelectedIds: (ids: readonly string[]) => void
}): React.JSX.Element {
  const {
    executionRows,
    numOrders,
    columns,
    isError,
    error,
    isLoading,
    openedExecs,
    setOpenedExecs,
    selectedIds,
    setSelectedIds
  } = props
  const [lastSelectedId, setLastSelectedId] = useState<string | null>(null)

  function handleSelectClick (id: string, event: React.MouseEvent): void {
    const newSelection = new Set(selectedIds)
    const erroredIds = new Set(executionRows.filter((exec) => exec.row.error !== undefined).map((exec) => exec.execId))
    if (event.shiftKey && lastSelectedId !== null) {
      const lastIndex = executionRows.findIndex((exec) => exec.execId === lastSelectedId)
      const currentIndex = executionRows.findIndex((exec) => exec.execId === id)
      if (lastIndex !== -1 && currentIndex !== -1) {
        const [start, end] = [lastIndex, currentIndex].sort((a, b) => a - b)
        for (let i = start; i <= end; i++) {
          if (!erroredIds.has(executionRows[i].execId)) {
            newSelection.add(executionRows[i].execId)
          }
        }
      }
    } else {
      newSelection.has(id) ? newSelection.delete(id) : newSelection.add(id)
    }
    setLastSelectedId(id)
    setSelectedIds(Array.from(newSelection))
  }

  if (isError) {
    return <TableErrorBody
      colsToSpan={columns.length + 1}
      error={error}
    />
  } else if (isLoading) {
    return (
      <TableBody>
        {
          Array.from({ length: 5 }, (_, i) => (
            <RowSkeleton
              usedKey={i}
              columns={columns}
              frontBuffer={{
                key: 'toggle',
                alignment: 'center',
                variant: 'rectangular'
              }}
              key={i}
            />
          ))
        }
        <TableRow sx={{ height: '100%' }} />
      </TableBody>)
  } else if (numOrders === 0) {
    return (
      <TableBody>
        <TableRow sx={{ height: '100%' }}>
          <TableCell
            colSpan={columns.length}
            align='center'
          >
            <Typography sx={{ fontSize: 20 }}>No Data</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    )
  } else {
    return (
      <TableBody>
        {executionRows.map((exec, i) => {
          return (
            <ExecutionRow
              key={i}
              execution={exec.row}
              containedOrders={exec.orders}
              columns={columns}
              isOpen={openedExecs.includes(exec.execId)}
              toggleOpen={() => {
                if (openedExecs.includes(exec.execId)) {
                  setOpenedExecs(openedExecs.filter((val) => val !== exec.execId))
                } else {
                  setOpenedExecs([...openedExecs, exec.execId])
                }
              }}
              isSelected={selectedIds.includes(exec.execId)}
              handleSelect={(event) => { handleSelectClick(exec.execId, event) }}
            />
          )
        })}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  }
}

function AlgoExecutionsTableContent (props: {
  orders: AlgoOrder[]
  isLoading: boolean
  columns: Array<ColumnDefinition<AlgoExecutionRow, any>>
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
  algoMap: Map<string, Algo>
}): React.JSX.Element {
  const {
    orders,
    isLoading,
    columns,
    isError,
    error,
    algoMap
  } = props
  const [executionRows, setExecutionRows] = useState<AlgoExecutionPlusOrders[]>(getExecutionRows(orders, algoMap))
  const [openedExecs, setOpenedExecs] = useState<readonly string[]>([])
  const [selectedIds, setSelectedIds] = useState<readonly string[]>([])
  const tableRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (tableRef.current !== null && !tableRef.current.contains(event.target as Node)) {
        setSelectedIds([])
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => { document.removeEventListener('click', handleClickOutside) }
  }, [])

  const visibleExecs = useMemo(
    () => {
      return stableSort(executionRows, (a, b) => {
        return getComparator('desc', 'time')({ time: a.row.time }, { time: b.row.time })
      })
    },
    [executionRows]
  )

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const newSelected = executionRows.filter((exec) => exec.row.error === undefined).map((e) => e.execId)
      setSelectedIds(newSelected)
    } else {
      setSelectedIds([])
    }
  }

  useEffect(() => {
    setExecutionRows(getExecutionRows(orders, algoMap))
    setOpenedExecs([])
    setSelectedIds([])
  }, [orders, algoMap])

  return (
    <Table stickyHeader sx={{ height: '100%' }} ref={tableRef}>
      <AlgoBotterTableHeader<AlgoExecutionRow>
        columns={columns}
        includeCheckAllBox
        handleSelectAll={handleSelectAllClick}
        allSelected={selectedIds.length > 0 && selectedIds.length === executionRows.filter((exec) => exec.row.error === undefined).length}
        numSelected={selectedIds.length}
        disabled={isLoading || isError}
      />
      <AlgoExecutionTableBody
        executionRows={visibleExecs}
        numOrders={orders.length}
        columns={columns}
        isError={isError}
        error={error}
        isLoading={isLoading}
        openedExecs={openedExecs}
        setOpenedExecs={setOpenedExecs}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />
      {
        selectedIds.length > 0 && <AlgoExecutionsSummaryFooter
          selectedRows={executionRows.filter((exec) => selectedIds.includes(exec.execId))}
          columns={columns}
          algoMap={algoMap}
        />
      }
    </Table>
  )
}

export default AlgoExecutionsTableContent
