import React, { useState } from 'react'
import {
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { glossaryApi } from '@r40cap/pms-sdk'
import {
  configApi,
  usePermissions as useAlertsPermissions
} from '@r40cap/alerts-sdk'

import NoAccess from './NoAccess'
import Page from './Page'
import AlertsControlsPanel from '../components/alerts/AlertsControlsPanel'
import { type AlertsType } from '../components/alerts/types'
import {
  MarginAlerts,
  AddMarginAlertModal,
  AddMarginAlertThresholdModal,
  DeleteMarginAlertModal,
  DeleteMarginThresholdModal
} from '../components/alerts/MarginAlerts'
import {
  AggregateExposureAlerts,
  AddAggregateExposureAlertModal,
  AddAggregateExposureAlertThresholdModal,
  DeleteAggregateExposureAlertModal,
  DeleteAggregateExposureAlertThresholdModal
} from '../components/alerts/AggregateExposureAlerts'
import {
  AssetExposureAlerts,
  AddAssetExposureAlertModal,
  AddAssetExposureAlertThresholdModal,
  DeleteAssetExposureAlertModal,
  DeleteAssetExposureAlertThresholdModal
} from '../components/alerts/AssetExposureAlerts'
import {
  FcmLimitAlerts,
  AddFcmLimitAlertModal,
  AddFcmLimitAlertThresholdModal,
  DeleteFcmLimitAlertModal,
  DeleteFcmLimitAlertThresholdModal
} from '../components/alerts/FcmLimitAlerts'
import {
  PnlAlerts,
  AddPnlAlertModal,
  AddPnlAlertThresholdModal,
  DeletePnlAlertModal,
  DeletePnlThresholdModal
} from '../components/alerts/PnlAlerts'
import {
  PriceAlerts,
  AddPriceAlertModal,
  DeletePriceAlertModal
} from '../components/alerts/PriceAlerts'
import {
  AlertDeliveries,
  AddAlertDeliveryModal,
  DeleteAlertDelivery
} from '../components/alerts/AlertDelivery'
import {
  Cohorts,
  AddCohortModal,
  AddUserToCohortModal,
  DeleteCohortModal,
  RemoveUserFromCohortModal
} from '../components/alerts/Cohorts'

const REDIRECT_TYPE = 'margin' as AlertsType

function Alerts (): React.JSX.Element {
  const alertsPermissions = useAlertsPermissions()
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const [pushSignal, setPushSignal] = useState<boolean>(false)
  const [hasEdited, setHasEdited] = useState<boolean>(false)

  const { data: usersData } = configApi.useGetUsersQuery(undefined)
  const { data: accountsData } = glossaryApi.useGetAccountsLiteQuery({ getAll: false })
  const { data: desksData } = glossaryApi.useGetDesksLiteQuery({ getAll: false })

  const props = {
    users: usersData?.data ?? [],
    desks: desksData?.data ?? [],
    accounts: accountsData?.data ?? [],
    refreshSignal,
    pushSignal,
    setHasEdited
  }

  return (
    alertsPermissions.userId !== undefined && alertsPermissions.userId !== null
      ? <Page>
          <Stack
            spacing={1}
            direction={'column'}
            width={'100%'}
            sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
          >
            <Box sx={{ height: '7%' }}>
              <AlertsControlsPanel
                hasEdited={hasEdited}
                refreshFunction={() => { setRefreshSignal(!refreshSignal) }}
                pushEditsFunction={() => { setPushSignal(!pushSignal) }}
              />
            </Box>
            <Box sx={{ height: '93%' }}>
              <Routes>
                <Route path='' element={<Navigate to={REDIRECT_TYPE} replace />} />
                <Route path={'margin'} element={<MarginAlerts {...props}/>}>
                  <Route path={'new'} element={<AddMarginAlertModal />} />
                  <Route path={'delete/:marginAlertId'} element={<DeleteMarginAlertModal />} />
                  <Route path={'addThreshold/:alertId'} element={<AddMarginAlertThresholdModal />} />
                  <Route path={'threshold/delete/:thresholdId'} element={<DeleteMarginThresholdModal />} />
                </Route>
                <Route path={'aggregateExposure'} element={<AggregateExposureAlerts {...props}/>}>
                  <Route path={'new'} element={<AddAggregateExposureAlertModal />} />
                  <Route path={'delete/:aggregateExposureAlertId'} element={<DeleteAggregateExposureAlertModal />} />
                  <Route path={'addThreshold/:alertId'} element={<AddAggregateExposureAlertThresholdModal />} />
                  <Route path={'threshold/delete/:thresholdId'} element={<DeleteAggregateExposureAlertThresholdModal />} />
                </Route>
                <Route path={'assetExposure'} element={<AssetExposureAlerts {...props}/>}>
                  <Route path={'new'} element={<AddAssetExposureAlertModal />} />
                  <Route path={'addThreshold/:alertId'} element={<AddAssetExposureAlertThresholdModal />} />
                  <Route path={'delete/:assetExposureAlertId'} element={<DeleteAssetExposureAlertModal />} />
                  <Route path={'threshold/delete/:thresholdId'} element={<DeleteAssetExposureAlertThresholdModal />} />
                </Route>
                <Route path={'fcmLimit'} element={<FcmLimitAlerts {...props}/>}>
                  <Route path={'new'} element={<AddFcmLimitAlertModal />} />
                  <Route path={'addThreshold/:alertId'} element={<AddFcmLimitAlertThresholdModal />} />
                  <Route path={'delete/:fcmLimitAlertId'} element={<DeleteFcmLimitAlertModal />} />
                  <Route path={'threshold/delete/:thresholdId'} element={<DeleteFcmLimitAlertThresholdModal />} />
                </Route>
                <Route path={'pnl'} element={<PnlAlerts {...props}/>}>
                  <Route path={'new'} element={<AddPnlAlertModal />} />
                  <Route path={'addThreshold/:alertId'} element={<AddPnlAlertThresholdModal />} />
                  <Route path={'delete/:pnlAlertId'} element={<DeletePnlAlertModal />} />
                  <Route path={'threshold/delete/:thresholdId'} element={<DeletePnlThresholdModal />} />
                </Route>
                <Route path={'price'} element={<PriceAlerts {...props}/>}>
                  <Route path={'new'} element={<AddPriceAlertModal />} />
                  <Route path={'delete/:priceAlertId'} element={<DeletePriceAlertModal />} />
                </Route>
                <Route path={'alertDelivery'} element={<AlertDeliveries {...props}/>}>
                  <Route path={'new'} element={<AddAlertDeliveryModal />} />
                  <Route path={'delete/:deliveryId'} element={<DeleteAlertDelivery />} />
                </Route>
                <Route path={'cohort'} element={<Cohorts {...props}/>}>
                  <Route path={'new'} element={<AddCohortModal />} />
                  <Route path={'addUser/:cohortId'} element={<AddUserToCohortModal />} />
                  <Route path={'delete/:cohortId'} element={<DeleteCohortModal />} />
                  <Route path={'removeUser/:cohortId/:userId'} element={<RemoveUserFromCohortModal />} />
                </Route>
                <Route path='*' element={<Navigate to={REDIRECT_TYPE} replace />}/>
              </Routes>
            </Box>
          </Stack>
        </Page>
      : <NoAccess />
  )
}

export default Alerts
