import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { configApi } from '@r40cap/alerts-sdk'

import { isApiError } from '../../../utils/errors'
import DeletionConfirmation from '../DeletionConfirmation'

function DeleteCohortModal (): React.JSX.Element {
  const navigate = useNavigate()
  const { cohortId } = useParams()
  const { showSnackbar } = useRequestSnackbar()
  const [performDelete] = configApi.useDeleteCohortMutation()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/alerts/cohort')
    }
  }

  function deleteAlert (): void {
    if (cohortId !== undefined) {
      showSnackbar({
        isOpen: true,
        message: 'Deleting Alert',
        status: 'processing'
      })
      performDelete({ cohortId })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Deleted Cohort',
              status: 'success'
            })
            exitModal()
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to delete Cohort',
            status: 'error'
          })
        })
    }
  }

  return (
    <Modal open handleClose={exitModal}>
      <DeletionConfirmation
        resourceDescription='Cohort'
        executeDelete={deleteAlert}
        cancel={exitModal}
        strongWarning={true}
      />
    </Modal>
  )
}

export default DeleteCohortModal
