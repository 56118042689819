import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import type { Algo, AlgoOrder } from '@r40cap/algos-sdk'

import type { AlgoExecutionPlusOrders, AlgoExecutionRow, AlgoSummaryRow } from './types'
import { getDisplayTime } from './utils'

dayjs.extend(utc)

const CME_MONTH_CODES = ['F', 'G', 'H', 'J', 'K', 'M', 'N', 'Q', 'U', 'V', 'X', 'Z']
const CME_YEARS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']

function isCmeFuture (
  baseFx: string,
  instrument: string
): boolean {
  if (baseFx === 'ETH') {
    return (
      instrument.length === 5 &&
        instrument.startsWith('ETH') &&
          CME_MONTH_CODES.includes(instrument[3]) &&
            CME_YEARS.includes(instrument[4])
    )
  }
  if (baseFx === 'BTC') {
    return (
      instrument.length === 5 &&
        instrument.startsWith('BTC') &&
          CME_MONTH_CODES.includes(instrument[3]) &&
            CME_YEARS.includes(instrument[4])
    )
  }
  if (baseFx === 'SOL') {
    return (
      instrument.length === 5 &&
        instrument.startsWith('SOL') &&
          CME_MONTH_CODES.includes(instrument[3]) &&
            CME_YEARS.includes(instrument[4])
    )
  }
  return false
}

function isSpot (
  baseFx: string,
  instrument: string
): boolean {
  return baseFx === instrument
}

function getAllowedSizeError (baseFx: string): number {
  if (baseFx === 'ETH') { return 0.1 }
  if (baseFx === 'SOL') { return 0.5 }
  if (baseFx === 'BTC') { return 0.008 }
  throw new Error('Invalid base fx')
}

function getCmeMultiplierForBaseFx (baseFx: string): number {
  if (baseFx === 'ETH') { return 50 }
  if (baseFx === 'SOL') { return 500 }
  if (baseFx === 'BTC') { return 5 }
  throw new Error('Invalid base fx')
}

const ALLOWED_MS = 300

function getExecutionRowsForBaseBasis (
  orders: AlgoOrder[],
  baseFx: string
): AlgoExecutionPlusOrders[] {
  if (baseFx !== 'ETH' && baseFx !== 'BTC' && baseFx !== 'SOL') {
    return orders.map((order) => {
      return {
        execId: order.orderId,
        row: {
          time: order.time,
          displayTime: getDisplayTime(order.time),
          algo: order.algo,
          sizeDescription: '',
          spreadDescription: '',
          instrumentDescription: '',
          quotePrice: 0,
          latencyMs: 0,
          sizeSign: 0,
          quotePriceDecimals: 0,
          error: `BaseFX ${baseFx} not supported`
        },
        orders: [order]
      }
    })
  }
  const allowedSizeError = getAllowedSizeError(baseFx)
  const sortedOrders = orders.sort((a, b) => {
    return dayjs.utc(a.time).diff(dayjs.utc(b.time))
  })
  const consumed = new Set<string>()
  const matches: AlgoExecutionPlusOrders[] = []
  for (const quoteOrder of sortedOrders) {
    if (consumed.has(quoteOrder.orderId)) {
      continue
    }
    let nettedQuantity = quoteOrder.netQty * quoteOrder.multiplier
    const time = dayjs.utc(quoteOrder.time)

    const potentialMatches = orders.filter((matchOrder) => {
      if (consumed.has(matchOrder.orderId)) {
        return false
      }
      if (Math.sign(matchOrder.netQty) === Math.sign(quoteOrder.netQty)) {
        return false
      }
      if (Math.abs(matchOrder.netQty * matchOrder.multiplier) > (Math.abs(quoteOrder.netQty * quoteOrder.multiplier) + allowedSizeError)) {
        return false
      }
      const matchTime = dayjs.utc(matchOrder.time)
      const msAfter = matchTime.diff(time)
      if (msAfter < 0 || msAfter > ALLOWED_MS) {
        return false
      }
      return true
    })

    const timeSortedPotentialMatches = potentialMatches.sort((a, b) => {
      return dayjs.utc(a.time).diff(dayjs.utc(b.time))
    })

    const ordersForMatch: AlgoOrder[] = []
    for (const matchOrder of timeSortedPotentialMatches) {
      if (Math.abs(nettedQuantity) > allowedSizeError) {
        const newNettedQuantity = nettedQuantity + matchOrder.netQty * matchOrder.multiplier
        if (Math.abs(newNettedQuantity) < Math.abs(nettedQuantity)) {
          nettedQuantity = newNettedQuantity
          consumed.add(matchOrder.orderId)
          ordersForMatch.push(matchOrder)
        }
      }
    }
    if (Math.abs(nettedQuantity) > allowedSizeError || ordersForMatch.length === 0) {
      matches.push({
        execId: quoteOrder.orderId,
        row: {
          time: quoteOrder.time,
          displayTime: getDisplayTime(quoteOrder.time),
          algo: quoteOrder.algo,
          sizeDescription: '',
          spreadDescription: '',
          instrumentDescription: '',
          quotePrice: 0,
          latencyMs: 0,
          sizeSign: 0,
          quotePriceDecimals: 0,
          error: `Execution Leftover: ${nettedQuantity} ${quoteOrder.instrument}`
        },
        orders: [quoteOrder, ...ordersForMatch]
      })
    } else {
      const isCme = isCmeFuture(baseFx, quoteOrder.instrument)
      const sizeInQuote = quoteOrder.netQty
      const sizeInUly = sizeInQuote * quoteOrder.multiplier
      const lastNonCmeFillTime = ordersForMatch[ordersForMatch.length - 1].time
      const latencyMs = dayjs.utc(lastNonCmeFillTime).diff(time)
      const totalValue = ordersForMatch.map((order) => order.price * order.netQty).reduce((a, b) => a + b, 0)
      const totalQty = ordersForMatch.map((order) => order.netQty).reduce((a, b) => a + b, 0)
      if (totalQty === 0 || totalValue === 0) {
        matches.push({
          execId: quoteOrder.orderId,
          row: {
            time: quoteOrder.time,
            displayTime: getDisplayTime(quoteOrder.time),
            algo: quoteOrder.algo,
            sizeDescription: '',
            spreadDescription: '',
            instrumentDescription: '',
            quotePrice: 0,
            latencyMs: 0,
            sizeSign: 0,
            quotePriceDecimals: 0,
            error: `Error computing quote price: ${totalValue} / ${totalQty}`
          },
          orders: [quoteOrder, ...ordersForMatch]
        })
      } else {
        const avgPrice = totalValue / totalQty
        const spread = quoteOrder.price - avgPrice
        const spreadBps = spread / avgPrice * 10000
        matches.push({
          execId: quoteOrder.orderId,
          row: {
            time: quoteOrder.time,
            displayTime: getDisplayTime(quoteOrder.time),
            algo: quoteOrder.algo,
            sizeDescription: isCme
              ? `${sizeInQuote} (${sizeInUly})`
              : `${sizeInQuote.toFixed(3)}`,
            spreadDescription: `${spread.toFixed(2)} (${spreadBps.toFixed(2)} bps)`,
            instrumentDescription: `${quoteOrder.instrument} - ${ordersForMatch[0].instrument}`,
            quotePrice: avgPrice,
            latencyMs,
            quotePriceDecimals: 2,
            sizeSign: Math.sign(sizeInQuote)
          },
          orders: [quoteOrder, ...ordersForMatch]
        })
      }
    }
    consumed.add(quoteOrder.orderId)
  }
  return matches
}

function getExecutionRowsForCmeBasis (
  ordersForAlgo: AlgoOrder[]
): AlgoExecutionPlusOrders[] {
  const uniqueBases = new Set(ordersForAlgo.map((order) => order.baseFx))
  const matches: AlgoExecutionPlusOrders[] = []
  for (const baseFx of Array.from(uniqueBases.values())) {
    const baseOrders = ordersForAlgo.filter((order) => order.baseFx === baseFx)
    matches.push(...getExecutionRowsForBaseBasis(baseOrders, baseFx))
  }
  return matches
}

export function getExecutionRowsForAlgo (
  ordersForAlgo: AlgoOrder[],
  algoMap: Map<string, Algo>
): AlgoExecutionPlusOrders[] {
  if (ordersForAlgo.length === 0) { throw new Error('No orders for algo') }
  const algo = algoMap.get(ordersForAlgo[0].algo)
  if (algo === undefined) {
    return ordersForAlgo.map((order) => {
      return {
        execId: order.orderId,
        row: {
          time: order.time,
          displayTime: getDisplayTime(order.time),
          algo: order.algo,
          sizeDescription: '',
          spreadDescription: '',
          instrumentDescription: '',
          quotePrice: 0,
          latencyMs: 0,
          sizeSign: 0,
          quotePriceDecimals: 0,
          error: `Algo ${order.algo} not found`
        },
        orders: [order]
      }
    })
  }
  switch (algo.algoType) {
    case 'basis':
      return getExecutionRowsForCmeBasis(ordersForAlgo)
    default:
      return ordersForAlgo.map((order) => {
        return {
          execId: order.orderId,
          row: {
            time: order.time,
            displayTime: getDisplayTime(order.time),
            algo: order.algo,
            sizeDescription: '',
            spreadDescription: '',
            instrumentDescription: '',
            quotePrice: 0,
            latencyMs: 0,
            sizeSign: 0,
            quotePriceDecimals: 0,
            error: `Algo Type ${algo.algoType} not supported`
          },
          orders: [order]
        }
      })
  }
}

function getQuoteHedge (baseFx: string, instrument1: string, instrument2: string): [string, string] {
  if (isCmeFuture(baseFx, instrument1)) {
    return [instrument1, instrument2]
  } else if (isCmeFuture(baseFx, instrument2)) {
    return [instrument2, instrument1]
  } else if (isSpot(baseFx, instrument1)) {
    return [instrument1, instrument2]
  } else if (isSpot(baseFx, instrument2)) {
    return [instrument2, instrument1]
  }
  throw new Error('Couldn\t Pick Quote/Hedge')
}

function getSummaryRowForCmeBasis (executions: AlgoExecutionPlusOrders[]): AlgoSummaryRow {
  const orders = executions.map((exec) => exec.orders).flat()
  const baseFx = orders[0].baseFx
  const distinctInstruments = new Set(orders.map((order) => order.instrument))
  if (distinctInstruments.size !== 2) { throw new Error('Not exactly 2 instruments') }
  const [inst1, inst2] = Array.from(distinctInstruments.values())
  const [quote, hedge] = getQuoteHedge(baseFx, inst1, inst2)
  const quoteOrders = orders.filter((order) => order.instrument === quote)
  const hedgeOrders = orders.filter((order) => order.instrument === hedge)
  if (quoteOrders.length === 0) { throw new Error('No Quote orders') }
  if (hedgeOrders.length === 0) { throw new Error('No Hedge orders') }
  const totalQuoteQuantity = quoteOrders.map((order) => order.netQty).reduce((a, b) => a + b, 0)
  const averageHedgePrice = hedgeOrders.map((order) => order.price * order.netQty).reduce((a, b) => a + b, 0) / hedgeOrders.map((order) => order.netQty).reduce((a, b) => a + b, 0)
  const averageQuotePrice = quoteOrders.map((order) => order.price * order.netQty).reduce((a, b) => a + b, 0) / totalQuoteQuantity
  const averageSpread = averageQuotePrice - averageHedgePrice
  const spreadBps = averageSpread / averageHedgePrice * 10000
  const sizeInUly = totalQuoteQuantity * getCmeMultiplierForBaseFx(baseFx)
  const averageLatencyMs = executions.map((exec) => {
    const execQuoteOrders = exec.orders.filter((order) => order.instrument === quote)
    return exec.row.latencyMs * execQuoteOrders.map((order) => order.netQty).reduce((a, b) => a + b, 0)
  }).reduce((a, b) => a + b, 0) / totalQuoteQuantity
  const isCme = isCmeFuture(baseFx, quote)
  return {
    algo: orders[0].algo,
    totalSizeDescription: isCme
      ? `${totalQuoteQuantity} (${sizeInUly})`
      : `${totalQuoteQuantity.toFixed(3)}`,
    instrumentDescription: `${quoteOrders[0].instrument} - ${hedgeOrders[0].instrument}`,
    averageSpreadDescription: `${averageSpread.toFixed(2)} (${spreadBps.toFixed(2)} bps)`,
    averageQuotePrice: averageHedgePrice,
    averageLatencyMs,
    quotePriceDecimals: 2,
    totalSizeSign: Math.sign(totalQuoteQuantity)
  }
}

export function getExecutionSummaryRow (
  executions: AlgoExecutionPlusOrders[],
  algoMap: Map<string, Algo>
): AlgoSummaryRow {
  if (executions.length === 0) { throw new Error('No executions') }
  const algoId = executions[0].orders[0].algo
  const algo = algoMap.get(algoId)
  if (algo === undefined) {
    return {
      algo: algoId,
      totalSizeDescription: '',
      instrumentDescription: '',
      averageSpreadDescription: '',
      averageQuotePrice: 0,
      averageLatencyMs: 0,
      totalSizeSign: 0,
      quotePriceDecimals: 0,
      error: `Algo ${algoId} not found`
    }
  }
  switch (algo.algoType) {
    case 'basis':
      return getSummaryRowForCmeBasis(executions)
  }
}

export function getGroupingKeyForAlgoExecution (execution: AlgoExecutionRow): string {
  if (execution.error !== undefined) {
    return execution.error
  }
  const algoDesc = execution.algo
  const signDesc = execution.sizeSign > 0 ? 'Buy' : 'Sell'
  const instDesc = execution.instrumentDescription
  return `${algoDesc} - ${signDesc} - ${instDesc}`
}
