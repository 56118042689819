import React, { useState, useMemo, type Key } from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  ValueCell,
  type Order,
  getComparator,
  stableSort,
  TableSkeleton,
  type ColumnDefinition
} from '@r40cap/ui'
import { type AggregateExposureAlert } from '@r40cap/alerts-sdk'

import { aggregateExposureAlertColumns } from './constants'
import type { AlertRow, InputType, RowKeysExceptThresholds } from './types'
import SortableHeader from '../SortableHeader'
import TableErrorBody from '../../utils/TableErrorBody'
import AlertThresholdsCellContent from '../AlertThresholdsCellContent'

function AggregateExposureAlertsTableBody (props: {
  rows: AlertRow[]
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof AggregateExposureAlert
  ) => void
  columns: Array<ColumnDefinition<AlertRow, InputType, AggregateExposureAlert, string>>
  proposeDelete: (aggregateExposureAlertId: string) => void
  proposeDeleteThreshold: (thresholdId: string) => void
  openAddThreshold: (alertId: string) => void
}): React.JSX.Element {
  const {
    rows,
    handleOpenEdit,
    columns,
    proposeDelete,
    proposeDeleteThreshold,
    openAddThreshold
  } = props
  const { palette } = useTheme()

  return <TableBody>
    {
      rows.map((row, index) => {
        return (
          <TableRow
            key={row.id}
            sx={{
              cursor: 'pointer',
              backgroundColor: palette.primary.main
            }}
          >
            {columns.map((column) => (
              <ValueCell<AlertRow, any>
                column={column}
                item={row}
                key={column.id as Key}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                onDoubleClick={() => {
                  if (column.editId !== undefined && column.inputType !== undefined) {
                    handleOpenEdit(
                      row.id,
                      column.inputType,
                      column.label,
                      column.editId
                    )
                  }
                }}
              />
            ))}
            <TableCell align='center' sx={{ alignContent: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <AlertThresholdsCellContent
                  thresholds={row.thresholds}
                  proposeDeleteAlert={() => { proposeDelete(row.id) }}
                  proposeDeleteThreshold={proposeDeleteThreshold}
                  proposeAddThreshold={() => { openAddThreshold(row.id) }}
                  formatThreshold={(thresh) => {
                    const numStr = thresh.toLocaleString('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })
                    return `$${numStr}`
                  }}
                  sortDirection='asc'
                />
              </Box>
            </TableCell>
          </TableRow>
        )
      })
    }
    {
      rows.length === 0
        ? <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length + 1}
              sx={{ justifyItems: 'center', alignItems: 'center' }}
            >
              <Typography textAlign={'center'}>No Alerts</Typography>
            </TableCell>
          </TableRow>
        : <TableRow sx={{ height: '100%' }} />
    }
  </TableBody>
}

function AggregateExposureAlertsTable (props: {
  rows: readonly AlertRow[]
  isFetching: boolean
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof AggregateExposureAlert
  ) => void
  proposeDelete: (aggregateExposureAlertId: string) => void
  proposeDeleteThreshold: (thresholdId: string) => void
  openAddThreshold: (alertId: string) => void
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    rows,
    isFetching,
    handleOpenEdit,
    proposeDelete,
    proposeDeleteThreshold,
    openAddThreshold,
    isError,
    error
  } = props
  const { palette } = useTheme()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<RowKeysExceptThresholds>('desk')

  const handleRequestSort = (property: keyof AlertRow): void => {
    if (property !== 'thresholds') {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    }
  }

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        borderRadius: '5px',
        height: '100%'
      }}
    >
      <Table
        stickyHeader
        sx={{
          tableLayout: 'fixed',
          height: '100%'
        }}
      >
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={aggregateExposureAlertColumns}
          aditionalEndingKeys={['delete-button']}
        />
        {
          isError
            ? <TableErrorBody
                colsToSpan={aggregateExposureAlertColumns.length + 2}
                error={error}
              />
            : isFetching
              ? <TableSkeleton
                numRows={10}
                columns={aggregateExposureAlertColumns}
              />
              : <AggregateExposureAlertsTableBody
                rows={visibleRows}
                handleOpenEdit={handleOpenEdit}
                columns={aggregateExposureAlertColumns}
                proposeDelete={proposeDelete}
                proposeDeleteThreshold={proposeDeleteThreshold}
                openAddThreshold={openAddThreshold}
              />
        }
      </Table>
    </TableContainer>
  )
}

export default AggregateExposureAlertsTable
