import { type Order } from '@r40cap/ui'

import { type PositionWithPrice } from './types'

export function getAbsoluteValueComparator<Key extends keyof any> (
  order: Order,
  orderBy: Key
) {
  return (
    a: Partial<{ [key in Key]: number | string | boolean | null }>,
    b: Partial<{ [key in Key]: number | string | boolean | null }>
  ) => {
    const aValue = Math.abs(a[orderBy] as number)
    const bValue = Math.abs(b[orderBy] as number)

    return order === 'desc'
      ? bValue - aValue
      : aValue - bValue
  }
}

export function getMarketValueNoUpl (position: PositionWithPrice): number {
  return (position.instrumentIsCoinMargined ? 1 : position.price ?? 0) * position.multiplier * position.quantity
}

export function getDeltaMarketValueNoUpl (position: PositionWithPrice): number {
  const priceToUse = position.baseFxPrice ?? (
    position.delta === 1 && !position.instrumentName.toLowerCase().includes('call') && !position.instrumentName.toLowerCase().includes('put')
      ? position.price
      : 0
  )
  const deltaQty = getDeltaQuantityNoUpl(position)
  return deltaQty * (priceToUse ?? 0)
}

export function getMarketValueUpl (position: PositionWithPrice): number {
  return position.price === undefined || (position.coinMarginedUnsettledInfo?.price) === undefined
    ? 0
    : position.coinMarginedUnsettledInfo.price * position.quantity * position.multiplier * ((1 / position.coinMarginedUnsettledInfo.averageEntryPrice) - (1 / position.price))
}

export function getMarketValueForPosition (position: PositionWithPrice): number {
  const positionValue = getMarketValueNoUpl(position)
  const uplValue = getMarketValueUpl(position)
  return positionValue + uplValue
}

export function getDeltaMarketValueForPosition (position: PositionWithPrice): number {
  const positionValue = getDeltaMarketValueNoUpl(position)
  const uplValue = getMarketValueUpl(position)
  return positionValue + uplValue
}

export function getDeltaQuantityNoUpl (position: PositionWithPrice): number {
  return (position.delta ?? 1) * (position.instrumentIsCoinMargined ? (position.price === undefined ? 0 : position.quantity / position.price) : position.quantity) * position.multiplier
}

export function getDeltaQuantityUpl (position: PositionWithPrice): number {
  return position.price === undefined || position.coinMarginedUnsettledInfo?.averageEntryPrice === undefined
    ? 0
    : position.quantity * position.multiplier * ((1 / position.coinMarginedUnsettledInfo.averageEntryPrice) - (1 / position.price))
}

export function getDeltaQuantityForPosition (position: PositionWithPrice): number {
  const deltaQtyPosition = getDeltaQuantityNoUpl(position)
  const uplQty = getDeltaQuantityUpl(position)
  return deltaQtyPosition + uplQty
}
