import React, { useEffect, useState } from 'react'
import { Box, Typography, Tooltip, useTheme } from '@mui/material'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import DangerousIcon from '@mui/icons-material/Dangerous'

import { riskApi, type RiskComponent } from '@r40cap/pms-sdk'

function getHealthScoreFromComponents (
  components: readonly RiskComponent[],
  riskEngineSlug: string
): number | null {
  if (riskEngineSlug === 'okx' || riskEngineSlug === 'okx-pm') {
    return components.find((component) => component.description === 'Margin Ratio')?.value ?? null
  } else if (riskEngineSlug === 'deribit-xpm') {
    const mmr = components.find((component) => component.description === 'Maintenance Margin')?.value ?? null
    const eq = components.find((component) => component.description === 'Total Equity')?.value ?? null
    return mmr === null || eq === null ? null : eq / mmr
  } else if (riskEngineSlug === 'bybit') {
    const mmr = components.find((component) => component.description === 'Maintenance Margin Requirement')?.value ?? null
    const eq = components.find((component) => component.description === 'Margin Balance')?.value ?? null
    return mmr === null || eq === null ? null : eq / mmr
  } else {
    return null
  }
}

const REFRESH_INTERVAL = 1000 * 10

function HealthScorePeek (props: {
  accountId: string
  riskEngineSlug: string
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    accountId,
    riskEngineSlug
  } = props
  const [score, setScore] = useState<number | null>(null)
  const {
    data,
    refetch,
    isLoading,
    isError
  } = riskApi.useGetPlatformRiskQuery({ accountId })

  const textColor = score === null || isLoading
    ? palette.tableBodyText.main
    : score >= 3.5
      ? palette.goodHealthColor.main
      : score >= 1.1
        ? palette.dangerHealthColor.main
        : palette.badHealthColor.main

  useEffect(() => {
    const newScore = getHealthScoreFromComponents(
      data?.data.components ?? [],
      riskEngineSlug
    )
    setScore(newScore)
  }, [data, riskEngineSlug])

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
        .catch((error) => {
          console.error('Error fetching platform risk:', error)
        })
    }, REFRESH_INTERVAL)
    return () => { clearInterval(interval) }
  }, [refetch])

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: palette.tertiary.main,
        borderRadius: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: '5px',
        paddingRight: '5px'
      }}
    >
      {
        isError
          ? <Tooltip
              title='Error fetching health score'
              placement='right'
            >
            <DangerousIcon
              sx={{
                fontSize: 14,
                color: palette.error.main,
                alignContent: 'center'
              }}
            />
          </Tooltip>
          : score === null || isLoading
            ? <HorizontalRuleIcon
                sx={{
                  fontSize: 14,
                  color: textColor,
                  alignContent: 'center'
                }}
              />
            : score === Infinity
              ? <AllInclusiveIcon
                  sx={{
                    fontSize: 14,
                    color: textColor,
                    alignContent: 'center'
                  }}
                />
              : (
                <Box sx={{ textAlign: 'center', position: 'relative' }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      color: textColor,
                      fontSize: 14
                    }}
                  >
                    {score.toLocaleString('en-US', { maximumFractionDigits: 3 })}
                  </Typography>
                </Box>
                )
      }
    </Box>
  )
}

export default HealthScorePeek
