import React from 'react'
import type { AlertDelivery } from '@r40cap/alerts-sdk'

import { InputType } from './types'
import {
  StringEditContent
} from '../EditModals'

export function getModalContent (
  inputType: InputType,
  label: string,
  editProperty: keyof AlertDelivery,
  handleSubmission: (value: any, alertId: string, property: keyof AlertDelivery) => void,
  alertId: string,
  closeModal: () => void
): React.JSX.Element {
  let content: React.JSX.Element = <></>

  function submit (value: any): void {
    handleSubmission(value, alertId, editProperty)
  }

  switch (inputType) {
    case InputType.STRING:
    default:
      content = <StringEditContent
        closeModal={closeModal}
        submit={submit}
        title={label}
      />
  }
  return content
}
