import React, { useState, useMemo, type Key } from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  ValueCell,
  type Order,
  getComparator,
  stableSort,
  TableSkeleton,
  type ColumnDefinition
} from '@r40cap/ui'
import type { Cohort } from '@r40cap/alerts-sdk'

import { aggregateExposureAlertColumns } from './constants'
import type { CohortRow, InputType, RowKeysExceptUsers } from './types'
import SortableHeader from '../SortableHeader'
import TableErrorBody from '../../utils/TableErrorBody'
import CohortUsersCellContent from './CohortUsersCellContent'

function CohortsTableBody (props: {
  rows: CohortRow[]
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof Cohort
  ) => void
  columns: Array<ColumnDefinition<CohortRow, InputType, Cohort, string>>
  proposeDelete: (cohortId: string) => void
  proposeRemoveUser: (cohortId: string, userId: string) => void
  openAddUser: (alertId: string) => void
}): React.JSX.Element {
  const {
    rows,
    handleOpenEdit,
    columns,
    proposeDelete,
    proposeRemoveUser,
    openAddUser
  } = props
  const { palette } = useTheme()

  return <TableBody>
    {
      rows.map((row, index) => {
        return (
          <TableRow
            key={row.id}
            sx={{
              cursor: 'pointer',
              backgroundColor: palette.primary.main
            }}
          >
            {columns.map((column) => (
              <ValueCell<CohortRow, any>
                column={column}
                item={row}
                key={column.id as Key}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                onDoubleClick={() => {
                  if (column.editId !== undefined && column.inputType !== undefined) {
                    handleOpenEdit(
                      row.id,
                      column.inputType,
                      column.label,
                      column.editId
                    )
                  }
                }}
              />
            ))}
            <TableCell align='center' sx={{ alignContent: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CohortUsersCellContent
                  users={row.users}
                  proposeDeleteCohort={() => { proposeDelete(row.id) }}
                  proposeRemoveUser={(userId: string) => { proposeRemoveUser(row.id, userId) }}
                  proposeAddUser={() => { openAddUser(row.id) }}
                />
              </Box>
            </TableCell>
          </TableRow>
        )
      })
    }
    {
      rows.length === 0
        ? <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length + 1}
              sx={{ justifyItems: 'center', alignItems: 'center' }}
            >
              <Typography textAlign={'center'}>No Alerts</Typography>
            </TableCell>
          </TableRow>
        : <TableRow sx={{ height: '100%' }} />
    }
  </TableBody>
}

function CohortsTable (props: {
  rows: readonly CohortRow[]
  isFetching: boolean
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof Cohort
  ) => void
  proposeDelete: (cohortId: string) => void
  proposeRemoveUser: (cohortId: string, userId: string) => void
  openAddUser: (cohortId: string) => void
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    rows,
    isFetching,
    handleOpenEdit,
    proposeDelete,
    proposeRemoveUser,
    openAddUser,
    isError,
    error
  } = props
  const { palette } = useTheme()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<RowKeysExceptUsers>('name')

  const handleRequestSort = (property: keyof CohortRow): void => {
    if (property !== 'users') {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    }
  }

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        borderRadius: '5px',
        height: '100%'
      }}
    >
      <Table
        stickyHeader
        sx={{
          tableLayout: 'fixed',
          height: '100%'
        }}
      >
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={aggregateExposureAlertColumns}
          aditionalEndingKeys={['delete-button']}
        />
        {
          isError
            ? <TableErrorBody
                colsToSpan={aggregateExposureAlertColumns.length + 2}
                error={error}
              />
            : isFetching
              ? <TableSkeleton
                numRows={10}
                columns={aggregateExposureAlertColumns}
              />
              : <CohortsTableBody
                rows={visibleRows}
                handleOpenEdit={handleOpenEdit}
                columns={aggregateExposureAlertColumns}
                proposeDelete={proposeDelete}
                proposeRemoveUser={proposeRemoveUser}
                openAddUser={openAddUser}
              />
        }
      </Table>
    </TableContainer>
  )
}

export default CohortsTable
