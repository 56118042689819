export interface AlertRow {
  id: string
  tradingviewId: string
  isLive: boolean
  delivery: string
  lastTriggered?: string
}

export enum InputType {
  STRING,
  DELIVERY
}
