import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import {
  type Cohort,
  configApi,
  usePermissions
} from '@r40cap/alerts-sdk'

import type { CohortRow, InputType } from './types'
import CohortsTable from './CohortsTable'
import { isApiError } from '../../../utils/errors'
import { getModalContent } from './utils'

function Cohorts (props: { refreshSignal: boolean }): React.JSX.Element {
  const { refreshSignal } = props
  const [rows, setRows] = useState<CohortRow[]>([])
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)
  const { userId } = usePermissions()
  const { showSnackbar } = useRequestSnackbar()
  const navigate = useNavigate()

  const {
    data: cohortsData,
    isFetching: cohortsIsFetching,
    refetch: cohortsRefetch,
    isError: cohortsIsError,
    error: cohortsError
  } = configApi.useGetCohortsQuery(undefined)
  const [postEditMutation] = configApi.useEditCohortMutation()

  useEffect(() => {
    cohortsRefetch()
      .catch((error) => { console.error(error) })
  }, [refreshSignal])

  const handleEdit = (value: any, cohortId: string, property: keyof Cohort): void => {
    const cohortToEdit = (cohortsData?.data ?? []).find(
      (cohort) => cohort.cohortId === cohortId
    )
    if (cohortToEdit !== undefined) {
      const editedCohort: Record<string, any> = { ...cohortToEdit }
      editedCohort[property] = value
      postEditMutation({ edit: editedCohort as Cohort })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Edits Pushed',
              status: 'success'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }

  useEffect(() => {
    const newRows = (cohortsData?.data ?? []).map((cohort) => {
      return {
        id: cohort.cohortId,
        name: cohort.name,
        users: cohort.users
      }
    })
    setRows(newRows)
  }, [cohortsData?.data])

  if (userId === undefined || userId === null) {
    return <Box sx={{
      height: '100%',
      width: '100%',
      alignContent: 'center',
      justifyItems: 'center'
    }}>
      <Typography align='center' variant='h4'>Insufficient Permissions</Typography>
    </Box>
  }

  return (
    <Box sx={{ height: '100%' }}>
      <CohortsTable
        rows={rows}
        isFetching={cohortsIsFetching}
        handleOpenEdit={
          (
            itemId: string,
            inputType: InputType,
            label: string,
            editProperty: keyof Cohort
          ) => {
            setEditModalOpen(true)
            setEditModalContent(
              getModalContent(
                inputType,
                label,
                editProperty,
                handleEdit,
                itemId,
                () => { setEditModalOpen(false) }
              )
            )
          }
        }
        proposeDelete={(cohortId: string) => { navigate(`/alerts/cohort/delete/${cohortId}`) }}
        proposeRemoveUser={(cohortId: string, userId: string) => { navigate(`/alerts/cohort/removeUser/${cohortId}/${userId}`) }}
        openAddUser={(cohortId: string) => { navigate(`/alerts/cohort/addUser/${cohortId}`) }}
        isError={cohortsIsError}
        error={cohortsError}
      />
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
      <Outlet />
    </Box>
  )
}

export default Cohorts
