import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { v4 as uuidv4 } from 'uuid'
import { Box, Button, Stack, useTheme } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import AddIcon from '@mui/icons-material/Add'
import {
  EditableTable,
  Modal,
  ModalOpenButton,
  StringInput,
  useRequestSnackbar
} from '@r40cap/ui'
import {
  type LiteTrader,
  TraderInput,
  type TransactionAssetChange,
  TransactionAssetChangeCreator,
  blotterApi
} from '@r40cap/pms-sdk'
import { getBlotterEditModalContent } from '../utils'
import type { AddAssetChangeRow, BlotterInputType } from '../common/types'
import { transactionAssetChangeColumns } from '../details/constants'
import { isApiError } from '../../../utils/errors'

function CloseLoanModal (): React.JSX.Element {
  const { loanId } = useParams()
  const navigate = useNavigate()
  const { showSnackbar } = useRequestSnackbar()
  const { palette } = useTheme()
  dayjs.extend(utc)
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [trader, setTrader] = useState<LiteTrader | null>(null)
  const [comments, setComments] = useState<string>('')
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false)
  const [postTransactions] = blotterApi.useAddTransactionsMutation()
  const { data: loanData, isFetching: loanFetching } = blotterApi.useGetLoanByIdQuery({
    loanId: loanId ?? ''
  })
  const { data: liabilitiesData, isFetching: liabilitiesFetching } = blotterApi.useGetLoanLiabilitiesQuery({
    loanId: loanId ?? ''
  })
  const [selectedIds, setSelectedIds] = useState<readonly string[]>([])

  const [editedData, setEditedData] = useState<readonly TransactionAssetChange[]>([])

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/blotter/loans')
    }
  }

  useEffect(() => {
    if (time !== null) {
      const newData = (liabilitiesData?.data ?? []).map((incurredLiability) => {
        const changeId = uuidv4()
        const change: TransactionAssetChange = {
          id: `new-${changeId}`,
          time: time.format('YYYY-MM-DD HH:mm'),
          settledTime: time.format('YYYY-MM-DD HH:mm'),
          account: incurredLiability.account,
          strategy: incurredLiability.strategy,
          instrument: incurredLiability.instrument,
          quantity: -1 * incurredLiability.quantity,
          price: incurredLiability.price,
          isFee: false
        }
        return change
      })
      setEditedData(newData)
    }
  }, [loanData?.data, liabilitiesData?.data, time])

  function handleSubmission (): void {
    if (time !== null && loanData?.data !== undefined) {
      showSnackbar({
        isOpen: true,
        message: 'Pushing Closure',
        status: 'processing'
      })
      const txnId = uuidv4()
      postTransactions({
        additions: [
          {
            id: `new-${txnId}`,
            trader: trader ?? undefined,
            expectedSettlementTime: undefined,
            comments: comments === ''
              ? `${loanData.data.counterparty.name} Loan Repayment`
              : comments,
            entryPlatform: 'Frontend',
            entryPlatformId: `FE-${txnId}`,
            assetChanges: editedData.map((value) => value),
            openedLiabilities: [],
            closedLiabilities: (liabilitiesData?.data ?? []).map((incurredLiability) => {
              return {
                id: incurredLiability.id,
                settledTime: time.format('YYYY-MM-DD HH:mm'),
                time: time.format('YYYY-MM-DD HH:mm')
              }
            })
          }
        ]
      })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Closed Loan',
              status: 'success'
            })
            exitModal()
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to close Loan',
            status: 'error'
          })
        })
    }
  }

  function addAssetChange (addition: TransactionAssetChange): void {
    setEditedData(editedData.concat(addition))
  }

  const getPreset = (rowId: string, property: keyof TransactionAssetChange): any => {
    const relevantObject = editedData.find((dataRow: TransactionAssetChange) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  const handleEdit = (property: keyof TransactionAssetChange, value: any, selected: readonly string[]): void => {
    const updatedData = editedData.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    setEditedData(updatedData)
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{ width: '70vw' }}
      >
        <Stack direction={'column'} spacing={2} padding={3}>
          <Stack direction={'row'} spacing={1}>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={setTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
            <Box sx={{ width: '50%' }}>
              <TraderInput
                setNone={(dummy: boolean) => {}}
                allowNone={false}
                mainColor={palette.accent.main}
                secondaryColor={palette.tableBodyText.main}
                setTrader={setTrader}
                enhanced
              />
            </Box>
          </Stack>
          <StringInput
            value={comments}
            setValue={setComments}
            color={palette.accent.main}
            title='Comments'
          />
          <Box sx={{ width: '100%' }} textAlign={'right'}>
            <ModalOpenButton
              modalOpen={addModalOpen}
              setModalOpen={setAddModalOpen}
              modalContents={<Box
                sx={{
                  width: '40vw',
                  padding: '40px'
                }}
              >
                <TransactionAssetChangeCreator
                  mainColor={palette.accent.main}
                  secondaryColor={palette.tableBodyText.main}
                  callColor={palette.success.main}
                  putColor={palette.error.main}
                  submitFunction={addAssetChange}
                  enhanced
                />
              </Box>}
            >
              <AddIcon />
            </ModalOpenButton>
          </Box>
          <Box sx={{ height: '40vh' }}>
            <EditableTable<AddAssetChangeRow, TransactionAssetChange, BlotterInputType, string>
              rows={editedData.map((assetChange) => {
                return {
                  id: assetChange.id,
                  time: assetChange.time,
                  settledTime: assetChange.settledTime ?? '',
                  account: `${assetChange.account.platform.name} - ${assetChange.account.name}`,
                  strategy: (
                    assetChange.strategy === undefined || assetChange.strategy === null
                      ? ''
                      : `${assetChange.strategy.superStrategy.name} - ${assetChange.strategy.name}`
                  ),
                  desk: assetChange.desk?.name ?? '',
                  instrument: assetChange.instrument.displayTicker,
                  quantity: assetChange.quantity,
                  price: assetChange.price,
                  isFee: assetChange.isFee,
                  quantityDecimals: assetChange.instrument.quantityDecimals,
                  priceDecimals: assetChange.instrument.priceDecimals,
                  transactionType: assetChange.transactionType?.name ?? ''
                }
              })}
              columns={transactionAssetChangeColumns}
              selected={selectedIds}
              setSelected={setSelectedIds}
              defaultOrderBy={'instrument'}
              defaultOrder={'desc'}
              isFetching={liabilitiesFetching || loanFetching}
              getPreset={getPreset}
              getModalContent={(
                inputType: BlotterInputType,
                label: string,
                editProperty: keyof TransactionAssetChange,
                closeModal: () => void,
                newSelected: readonly string[],
                initialValue: any
              ) => {
                return getBlotterEditModalContent<TransactionAssetChange>(
                  inputType,
                  label,
                  editProperty,
                  closeModal,
                  handleEdit,
                  newSelected,
                  initialValue
                )
              }}
              checkboxColor={palette.accent.main}
              backgroundColor={palette.primary.main}
              headerBackgroundColor={palette.background.default}
              headerTextColor={palette.tableHeaderText.main}
              headerActiveTextColor={palette.accent.main}
              bodyDefaultTextColor={palette.tableBodyText.main}
              bodyPrimaryBackgroundColor={palette.tertiary.main}
              redTextColor='red'
              greenTextColor='green'
              dense
            />
          </Box>
          <Button
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.secondary.main,
              width: '50%',
              alignSelf: 'center'
            }}
            variant='contained'
            onClick={handleSubmission}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default CloseLoanModal
