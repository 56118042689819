import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Checkbox,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  type ColumnDefinition,
  HeaderCell,
  ValueCell,
  stableSort,
  getComparator
} from '@r40cap/ui'
import type { AlgoOrder } from '@r40cap/algos-sdk'

import type { AlgoExecutionRow, AlgoOrderRow } from '../types'
import { algoOrderColumnsNoAlgo } from '../constants'
import { getDisplayTime } from '../utils'
import { getDecimals } from '../../../utils/decimals'

dayjs.extend(utc)

function ExecutionRow (props: {
  execution: AlgoExecutionRow
  containedOrders: AlgoOrder[]
  columns: Array<ColumnDefinition<AlgoExecutionRow, any>>
  isOpen: boolean
  toggleOpen: () => void
  allowance?: number
  isSelected: boolean
  handleSelect: (event: React.MouseEvent) => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    execution,
    containedOrders,
    isOpen,
    toggleOpen,
    columns,
    isSelected,
    handleSelect
  } = props

  const orderRows = useMemo(
    () => {
      return stableSort(containedOrders, getComparator('desc', 'time')).map((order) => {
        const [qDec, pDec] = getDecimals(order.price, order.multiplier)
        const dispTime = getDisplayTime(order.time)
        return {
          orderId: order.orderId,
          time: order.time,
          displayTime: dispTime,
          algo: order.algo,
          netQuantity: order.netQty,
          instrument: order.instrument,
          price: order.price,
          account: order.account,
          quantityDecimals: qDec,
          priceDecimals: pDec
        }
      })
    },
    [containedOrders]
  )

  return (
    <>
      <TableRow>
        <TableCell sx={{ padding: 1 }}>
          <Stack direction={'row'} spacing={1}>
            <Checkbox
              style={{ color: palette.accent.main }}
              size="small"
              onClick={(event) => { handleSelect(event) }}
              checked={isSelected}
              disabled={execution.error !== undefined}
            />
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={toggleOpen}
            >
              {
                isOpen
                  ? <KeyboardArrowUpIcon fontSize='small'/>
                  : <KeyboardArrowDownIcon fontSize='small'/>
              }
            </IconButton>
          </Stack>
        </TableCell>
        {
          execution.error !== undefined
            ? <>
              <ValueCell<AlgoExecutionRow, any>
                column={columns[0]}
                item={execution}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                dense
              />
              <TableCell colSpan={columns.length - 1} sx={{ color: 'red' }}>{execution.error}</TableCell>
            </>
            : columns.map((column, idx) => (
              <ValueCell<AlgoExecutionRow, any>
                column={column}
                item={execution}
                key={column.id}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                dense
              />
            ))
        }
      </TableRow>
      {
        isOpen && <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
          <TableCell colSpan={1}/>
          <TableCell colSpan={columns.length}>
            <Table size='small' sx={{ borderRadius: '3px' }}>
              <TableBody>
                <TableRow>
                  {
                    algoOrderColumnsNoAlgo.map((column, idx) => (
                      <HeaderCell<AlgoOrderRow, any>
                        column={column}
                        isActiveSort={false}
                        order={'asc'}
                        sortHandler={(): void => {}}
                        key={idx}
                        backgroundColor={palette.background.default}
                        textColor={palette.tableHeaderText.main}
                        activeTextColor={palette.accent.main}
                        dense
                      />
                    ))
                  }
                </TableRow>
                {
                  orderRows.map((order, idx1) => (
                    <TableRow key={idx1}>
                      {
                        algoOrderColumnsNoAlgo.map((column, idx2) => (
                          <ValueCell<AlgoOrderRow, any>
                            column={column}
                            item={order}
                            key={idx2}
                            defaultTextColor={palette.tableBodyText.main}
                            redTextColor='red'
                            greenTextColor='green'
                          />
                        ))
                      }
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      }
    </>
  )
}

export default ExecutionRow
