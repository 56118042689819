import React, { useEffect, useState } from 'react'
import type { Dayjs } from 'dayjs'
import { Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { DatePicker } from '@mui/x-date-pickers'
import { type Price, type Instrument, glossaryApi, priceApi } from '@r40cap/pms-sdk'
import { NumberInput, useRequestSnackbar } from '@r40cap/ui'

import { getLastMarkDay, getMarkForDay } from '../../utils/times'
import { isApiError } from '../../utils/errors'

interface ReducedPriceCreation {
  priceStr: string
  deltaStr: string
  instrument: Instrument
}

const MANUAL_OVERRIDE_IDS: string[] = [
  '05324b40-cbec-4d38-8032-6d22d4bcdaa6',
  '0269eaf9-3c74-4aa0-a9d0-e2f0a053c79b',
  'fcdadb00-6163-462a-ba2a-da68fb0a0d7d',
  '9e991750-2029-4099-92f6-736ad9a61c7d',
  '841dab0d-6ddd-4342-849f-3e329036319b'
]

const IGNORE_MANUAL_IDS: string[] = [
  'ba54fd4b-1c40-48c6-97f8-955e663ca299'
]

function MassManualPricingModal (props: {
  closeModal: () => void
}): React.JSX.Element {
  const { closeModal } = props
  const [date, setDate] = useState<Dayjs>(getLastMarkDay())
  const [priceInputs, setPriceInputs] = useState<Map<string, ReducedPriceCreation>>(new Map<string, ReducedPriceCreation>())

  const { showSnackbar } = useRequestSnackbar()
  const { data, isFetching } = glossaryApi.useGetInstrumentsQuery({})
  const [pushPrices] = priceApi.useUpdateMarkPricesMutation()
  const { palette } = useTheme()

  const handleChangeDate = (newDate: Dayjs | null): void => {
    if (newDate !== null) {
      setDate(newDate)
    }
  }

  useEffect(() => {
    if (data !== null && data !== undefined) {
      const newMap = new Map<string, ReducedPriceCreation>()
      data.data.forEach((inst) => {
        newMap.set(inst.id, { priceStr: '', deltaStr: '1.0', instrument: inst })
      })
      setPriceInputs(newMap)
    }
  }, [data])

  const handlePriceChange = (instId: string, price: string): void => {
    const newMap = new Map<string, ReducedPriceCreation>()
    Array.from(priceInputs.values()).forEach((element) => {
      if (instId === element.instrument.id) {
        newMap.set(element.instrument.id, {
          instrument: element.instrument,
          deltaStr: element.deltaStr,
          priceStr: price
        })
      } else {
        newMap.set(element.instrument.id, element)
      }
    })
    setPriceInputs(newMap)
  }

  const handleDeltaChange = (instId: string, delta: string): void => {
    const newMap = new Map<string, ReducedPriceCreation>()
    Array.from(priceInputs.values()).forEach((element) => {
      if (instId === element.instrument.id) {
        newMap.set(element.instrument.id, {
          instrument: element.instrument,
          priceStr: element.priceStr,
          deltaStr: delta
        })
      } else {
        newMap.set(element.instrument.id, element)
      }
    })
    setPriceInputs(newMap)
  }

  function handleSubmit (): void {
    const prices = Array.from(priceInputs.values()).filter((input) => input.priceStr !== '').map((input) => {
      const priceObj: Price = {
        id: '',
        time: getMarkForDay(date).format('YYYY-MM-DD HH:mm'),
        instrument: {
          ...input.instrument,
          pricingMethodName: input.instrument.pricingMethod.name
        },
        price: parseFloat(input.priceStr),
        delta: parseFloat(input.deltaStr),
        beta: 1
      }
      return priceObj
    })
    if (prices.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Updating Prices',
        status: 'processing'
      })
      pushPrices({ date: date.format('YYYY-MM-DD'), updates: prices })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Created Strategy',
              status: 'success'
            })
            closeModal()
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to update Prices',
            status: 'error'
          })
        })
    } else {
      closeModal()
    }
  }

  return (
    <Stack
      direction='column'
      spacing={1}
      sx={{
        width: '100%',
        maxHeight: '80vh',
        overflow: 'scroll'
      }}
    >
      <DatePicker
        value={date}
        onChange={(newValue) => { handleChangeDate(newValue) }}
      />
      {
        (isFetching as boolean) && <CircularProgress style={{ alignSelf: 'center', color: palette.accent.main }} />
      }
      {
        data?.data.filter(
          (inst) => (
            (inst.pricingMethod.name === 'Manual' || MANUAL_OVERRIDE_IDS.includes(inst.id)) &&
            !(IGNORE_MANUAL_IDS.includes(inst.id))
          )
        ).map((inst, index) => {
          return (
            <Grid container sx={{ width: '100%', alignItems: 'center' }} spacing={1} key={inst.id}>
              <Grid item xs={4}>
                <Typography sx={{ color: palette.tableBodyText.main }}>{inst.displayTicker}</Typography>
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  title='Price'
                  intOnly={false}
                  valueString={priceInputs.get(inst.id)?.priceStr ?? ''}
                  setValueString={(value: string) => { handlePriceChange(inst.id, value) }}
                  color={palette.accent.main}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  title='Delta'
                  intOnly={false}
                  valueString={priceInputs.get(inst.id)?.deltaStr ?? ''}
                  setValueString={(value: string) => { handleDeltaChange(inst.id, value) }}
                  color={palette.accent.main}
                />
              </Grid>
            </Grid>
          )
        })
      }
      <Button
        onClick={handleSubmit}
        variant='contained'
        style={{
          width: '50%',
          backgroundColor: palette.accent.main,
          color: palette.primary.main,
          alignSelf: 'center'
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

export default MassManualPricingModal
