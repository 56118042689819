import React, { useEffect, useMemo, useState } from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import {
  type ColumnDefinition,
  RowSkeleton,
  getComparator,
  stableSort
} from '@r40cap/ui'
import { type AlgoOrder } from '@r40cap/algos-sdk'

import { type AlgoOrderRow } from './types'
import TableErrorBody from '../utils/TableErrorBody'
import OrderRow from './rows/OrderRow'
import { getDecimals } from '../../utils/decimals'
import AlgoBotterTableHeader from './AlgoBlotterTableHeader'

function getOrderRows (orders: AlgoOrder[]): AlgoOrderRow[] {
  const rows = orders.map((order) => {
    const [qDec, pDec] = getDecimals(order.price, order.multiplier)
    const dispTime = `${order.time.slice(0, 10)} ${order.time.slice(11, 19)}`
    return {
      orderId: order.orderId,
      time: order.time,
      displayTime: dispTime,
      algo: order.algo,
      netQuantity: order.netQty,
      instrument: order.instrument,
      price: order.price,
      account: order.account,
      quantityDecimals: qDec,
      priceDecimals: pDec
    }
  })
  return rows
}

function AlgoOrdersTableBody (props: {
  orders: AlgoOrderRow[]
  isLoading: boolean
  columns: Array<ColumnDefinition<AlgoOrderRow, any>>
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    orders,
    isLoading,
    columns,
    isError,
    error
  } = props

  if (isError) {
    return (
      <TableErrorBody
        colsToSpan={columns.length + 1}
        error={error}
      />
    )
  } else if (isLoading) {
    const rows = []
    for (let i = 0; i < 5; i++) {
      rows.push(
        <RowSkeleton
          usedKey={i}
          columns={columns}
          key={i}
        />
      )
    }
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  } else if (orders.length === 0) {
    return (
      <TableBody>
        <TableRow sx={{ height: '100%' }}>
          <TableCell
            colSpan={columns.length}
            align='center'
          >
            <Typography sx={{ fontSize: 20 }}>No Data</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    )
  } else {
    const rows = orders.map((order, i) => {
      return (
        <OrderRow
          key={i}
          order={order}
          columns={columns}
        />
      )
    })
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  }
}

function AlgoOrdersTableContent (props: {
  orders: AlgoOrder[]
  isLoading: boolean
  columns: Array<ColumnDefinition<AlgoOrderRow, any>>
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    orders,
    isLoading,
    columns,
    isError,
    error
  } = props
  const [orderRows, setOrderRows] = useState<AlgoOrderRow[]>(getOrderRows(orders))

  const visibleOrders = useMemo(
    () => {
      return stableSort(orderRows, getComparator('desc', 'time'))
    },
    [orderRows]
  )

  useEffect(() => {
    setOrderRows(getOrderRows(orders))
  }, [orders])

  return (
    <Table stickyHeader sx={{ height: '100%' }}>
      <AlgoBotterTableHeader<AlgoOrderRow>
        columns={columns}
        includeCheckAllBox={false}
        handleSelectAll={() => {}}
        allSelected={false}
        numSelected={0}
        disabled={true}
      />
      <AlgoOrdersTableBody
        orders={visibleOrders}
        columns={columns}
        isError={isError}
        error={error}
        isLoading={isLoading}
      />
    </Table>
  )
}

export default AlgoOrdersTableContent
