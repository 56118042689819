import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import {
  priceAlertsApi,
  type PriceAlert,
  usePermissions,
  PriceAlertState,
  AlertDeliveryMethod
} from '@r40cap/alerts-sdk'

import type { AlertRow, InputType } from './types'
import PriceAlertsTable from './PriceAlertsTable'
import { isApiError } from '../../../utils/errors'
import { getModalContent } from './utils'

function formatUtcTimestamp (timestamp: number): string {
  const date = new Date(timestamp * 1000)
  const dateStr = date.toISOString().slice(0, 19).replace('T', ' ')
  const now = new Date()
  const diffMs = now.getTime() - date.getTime()
  const diffSeconds = Math.floor(diffMs / 1000)
  const diffMinutes = Math.floor(diffMs / 60000)
  const diffHours = Math.floor(diffMinutes / 60)
  const diffDays = Math.floor(diffHours / 24)
  let relativeTime
  if (diffSeconds < 1) {
    relativeTime = 'now'
  } else if (diffSeconds < 60) {
    relativeTime = `${diffSeconds}s ago`
  } else if (diffMinutes < 60) {
    relativeTime = `${diffMinutes}m ago`
  } else if (diffHours < 24) {
    relativeTime = `${diffHours}h ago`
  } else {
    relativeTime = `${diffDays}d ago`
  }
  return `${dateStr} (${relativeTime})`
}

function PriceAlerts (props: {
  refreshSignal: boolean
  pushSignal: boolean
  setHasEdited: (hasEdited: boolean) => void
}): React.JSX.Element {
  const { refreshSignal } = props
  const [rows, setRows] = useState<AlertRow[]>([])
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)
  const { userId } = usePermissions()
  const { showSnackbar } = useRequestSnackbar()
  const navigate = useNavigate()

  const {
    data: alertsData,
    isLoading: alertsIsLoading,
    refetch: alertsRefetch,
    isError: alertsIsError,
    error: alertsError
  } = priceAlertsApi.useGetPriceAlertsQuery(undefined)
  const [postEditMutation] = priceAlertsApi.useEditPriceAlertMutation()

  useEffect(() => {
    alertsRefetch()
      .catch((error) => { console.error(error) })
  }, [refreshSignal])

  const handleEdit = (value: any, priceAlertId: string, property: keyof PriceAlert): void => {
    const alertToEdit = (alertsData?.data ?? []).find(
      (alert) => alert.alertId === priceAlertId
    )
    if (alertToEdit !== undefined) {
      const editedAlert: Record<string, any> = { ...alertToEdit }
      editedAlert[property] = value
      postEditMutation({ edit: editedAlert as PriceAlert })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Edits Pushed',
              status: 'success'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }

  function toggleLive (priceAlertId: string): void {
    const alertToEdit = (alertsData?.data ?? []).find(
      (alert) => alert.alertId === priceAlertId
    )
    if (alertToEdit !== undefined) {
      const editedAlert: PriceAlert = {
        ...alertToEdit,
        alertState: alertToEdit.alertState === PriceAlertState.Active
          ? PriceAlertState.Paused
          : PriceAlertState.Active
      }
      postEditMutation({ edit: editedAlert })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to edits Toggle Alert',
            status: 'error'
          })
        })
    }
  }

  useEffect(() => {
    const newRows = (alertsData?.data ?? []).map((alert) => {
      const methodDescription = alert.delivery.deliveryMethod === AlertDeliveryMethod.SlackChannel
        ? 'Slack'
        : alert.delivery.deliveryMethod === AlertDeliveryMethod.SlackDM
          ? 'Slack DM'
          : alert.delivery.deliveryMethod === AlertDeliveryMethod.Telegram
            ? 'Fink GPT'
            : 'Call'
      const recipientDescription = alert.delivery.deliveryMethod === AlertDeliveryMethod.SlackChannel
        ? alert.delivery.methodId ?? 'undefined'
        : alert.delivery.cohort?.name ?? 'undefined'
      const ltDesc = alert.lastTriggeredTs === undefined || alert.lastTriggeredTs === null
        ? undefined
        : formatUtcTimestamp(alert.lastTriggeredTs)
      return {
        id: alert.alertId,
        tradingviewId: alert.tradingviewId,
        isLive: alert.alertState === PriceAlertState.Active,
        delivery: `${methodDescription} - ${recipientDescription}`,
        lastTriggered: ltDesc
      }
    })
    setRows(newRows)
  }, [alertsData?.data])

  useEffect(() => {
    const updateRows = (): void => {
      const newRows = (alertsData?.data ?? []).map((alert) => {
        const methodDescription =
          alert.delivery.deliveryMethod === AlertDeliveryMethod.SlackChannel
            ? 'Slack'
            : alert.delivery.deliveryMethod === AlertDeliveryMethod.SlackDM
              ? 'Slack DM'
              : alert.delivery.deliveryMethod === AlertDeliveryMethod.Telegram
                ? 'Fink GPT'
                : 'Call'
        const recipientDescription =
          alert.delivery.deliveryMethod === AlertDeliveryMethod.SlackChannel
            ? alert.delivery.methodId ?? 'undefined'
            : alert.delivery.cohort?.name ?? 'undefined'
        const ltDesc =
          alert.lastTriggeredTs === undefined || alert.lastTriggeredTs === null
            ? undefined
            : formatUtcTimestamp(alert.lastTriggeredTs)
        return {
          id: alert.alertId,
          tradingviewId: alert.tradingviewId,
          isLive: alert.alertState === PriceAlertState.Active,
          delivery: `${methodDescription} - ${recipientDescription}`,
          lastTriggered: ltDesc
        }
      })
      setRows(newRows)
    }
    updateRows()
    const interval = setInterval(updateRows, 10000)
    return () => { clearInterval(interval) }
  }, [alertsData?.data])

  if (userId === undefined || userId === null) {
    return <Box sx={{
      height: '100%',
      width: '100%',
      alignContent: 'center',
      justifyItems: 'center'
    }}>
      <Typography align='center' variant='h4'>Insufficient Permissions</Typography>
    </Box>
  }

  return (
    <Box sx={{ height: '100%' }}>
      <PriceAlertsTable
        rows={rows}
        isFetching={alertsIsLoading}
        handleOpenEdit={
          (
            itemId: string,
            inputType: InputType,
            label: string,
            editProperty: keyof PriceAlert
          ) => {
            setEditModalOpen(true)
            setEditModalContent(
              getModalContent(
                inputType,
                label,
                editProperty,
                handleEdit,
                itemId,
                () => { setEditModalOpen(false) }
              )
            )
          }
        }
        proposeDelete={(priceAlertId: string) => { navigate(`/alerts/price/delete/${priceAlertId}`) }}
        toggleLive={toggleLive}
        isError={alertsIsError}
        error={alertsError}
      />
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
      <Outlet />
    </Box>
  )
}

export default PriceAlerts
