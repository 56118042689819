import React, { useEffect, useState, useMemo } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  useTheme
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import { type Order, stableSort, getComparator } from '@r40cap/ui'
import { type Price } from '@r40cap/pms-sdk'

import type { PositionObject, PositionRow, ColumnDefinitionWithColor } from '../types'
import { ValueCellWithColor } from './cells'
import {
  getDeltaQuantityForPosition,
  getMarketValueForPosition,
  getMarketValueNoUpl,
  getMarketValueUpl,
  getDeltaQuantityNoUpl,
  getDeltaQuantityUpl,
  getDeltaMarketValueForPosition,
  getDeltaMarketValueNoUpl
} from '../utils'

function BaseFxRowWithPriority (props: {
  bottomRows: PositionObject[]
  referencePrice?: Price
  priority: number
  strategyColor: string
  includeSmallAmounts: boolean
  smallThreshold: number
  order: Order
  orderBy: keyof PositionRow
  columns: Array<ColumnDefinitionWithColor<PositionRow, any>>
  requestedTime?: Dayjs
  isOpen: boolean
  toggleOpen: () => void
}): React.JSX.Element {
  dayjs.extend(minMax)
  dayjs.extend(utc)
  const { palette } = useTheme()
  const {
    bottomRows,
    priority,
    columns,
    order,
    orderBy,
    strategyColor,
    referencePrice,
    includeSmallAmounts,
    smallThreshold,
    requestedTime,
    isOpen,
    toggleOpen
  } = props
  const [instruments, setInstruments] = useState<string []>(bottomRows.map((row) => row.instrumentId))

  useEffect(() => {
    setInstruments(bottomRows.map((row) => row.instrumentId))
  }, [bottomRows])

  const baseFxRowObject: PositionRow = {
    name: bottomRows.length > 0 ? bottomRows[0].baseFxName : '',
    priorityLevel: undefined,
    strategyColor,
    marketValue: bottomRows.reduce((partialSum, a) => partialSum + getMarketValueForPosition(a), 0) ?? 0,
    deltaMarketValue: bottomRows.reduce((partialSum, a) => partialSum + getDeltaMarketValueForPosition(a), 0) ?? 0,
    referenceMarketValue: (referencePrice !== undefined)
      ? bottomRows.reduce((partialSum, a) => partialSum + getMarketValueForPosition(a), 0) / referencePrice.price
      : 0,
    deltaQuantity: bottomRows.reduce((sum, current) => sum + getDeltaQuantityForPosition(current), 0),
    priceDecimals: 0,
    quantityDecimals: bottomRows.length === 0 ? 2 : Math.max(...bottomRows.map(row => row.quantityDecimals)),
    requestedTime,
    receivedTime: dayjs.min(bottomRows.map((row) => dayjs.utc(row.priceTime))) ?? dayjs().utc()
  }

  const visibleInstruments = useMemo(
    () => {
      const filteredInstruments = includeSmallAmounts
        ? instruments
        : instruments.filter((inst) => {
          const instRows = bottomRows.filter((val) => val.instrumentId === inst)
          const absMv = instRows.reduce((sum, current) => sum + Math.abs(getMarketValueForPosition(current)), 0)
          return absMv >= smallThreshold
        })
      return stableSort(filteredInstruments, (a, b) => {
        const instARows = bottomRows.filter((val) => val.baseFxId === a)
        const psnRowA: PositionRow = {
          name: bottomRows.find((val) => val.baseFxId === a)?.baseFxName ?? '',
          priorityLevel: priority,
          strategyColor,
          marketValue: instARows.reduce((sum, current) => sum + getMarketValueForPosition(current), 0),
          deltaMarketValue: instARows.reduce((sum, current) => sum + getDeltaMarketValueForPosition(current), 0),
          referenceMarketValue: referencePrice !== undefined
            ? instARows.reduce((sum, current) => sum + getMarketValueForPosition(current), 0) / referencePrice.price
            : instARows.reduce((sum, current) => sum + getMarketValueForPosition(current), 0),
          priceDecimals: 0,
          quantityDecimals: 0
        }
        const instBRows = bottomRows.filter((val) => val.baseFxId === b)
        const psnRowB: PositionRow = {
          name: bottomRows.find((val) => val.baseFxId === a)?.baseFxName ?? '',
          priorityLevel: priority,
          strategyColor,
          marketValue: instBRows.reduce((sum, current) => sum + getMarketValueForPosition(current), 0),
          deltaMarketValue: instBRows.reduce((sum, current) => sum + getDeltaMarketValueForPosition(current), 0),
          referenceMarketValue: referencePrice !== undefined
            ? instBRows.reduce((sum, current) => sum + getMarketValueForPosition(current), 0) / referencePrice.price
            : instBRows.reduce((sum, current) => sum + getMarketValueForPosition(current), 0),
          priceDecimals: 0,
          quantityDecimals: 0
        }
        return getComparator(order, orderBy)({ ...psnRowA, requestedTime: undefined, receivedTime: undefined }, { ...psnRowB, requestedTime: undefined, receivedTime: undefined })
      })
    },
    [order, orderBy, bottomRows, includeSmallAmounts, smallThreshold]
  )

  return (
    <>
      <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
        <TableCell sx={{ padding: 1 }}>
          <Box alignItems={'center'}>
            <IconButton
              aria-label="expand row"
              size="small"
              disabled
            >
              <SubdirectoryArrowRightIcon fontSize='small'/>
            </IconButton>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={toggleOpen}
            >
              {
                isOpen
                  ? <KeyboardArrowUpIcon fontSize='small'/>
                  : <KeyboardArrowDownIcon fontSize='small'/>
              }
            </IconButton>
          </Box>
        </TableCell>
        {
          columns.map((column, idx) => (
            <ValueCellWithColor<PositionRow, any>
              column={column}
              item={baseFxRowObject}
              key={column.id}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
              dense
            />
          ))
        }
      </TableRow>
      {
        isOpen && visibleInstruments.map((inst) => {
          const obj = bottomRows.find((row) => row.instrumentId === inst)
          const mainRowObject: PositionRow = {
            name: obj !== undefined ? `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${obj.instrumentName}` : '',
            quantity: obj?.quantity,
            priorityLevel: undefined,
            strategyColor,
            multiplier: obj?.multiplier,
            price: obj?.price,
            delta: obj?.delta,
            beta: obj?.beta,
            marketValue: obj === undefined ? 0 : getMarketValueNoUpl(obj),
            deltaMarketValue: obj === undefined ? 0 : getDeltaMarketValueNoUpl(obj),
            referenceMarketValue: (obj === undefined || referencePrice === undefined) ? 0 : (obj.beta ?? 1) * getMarketValueNoUpl(obj) / referencePrice.price,
            deltaQuantity: obj === undefined
              ? 0
              : getDeltaQuantityNoUpl(obj),
            priceDecimals: obj?.priceDecimals ?? 0,
            quantityDecimals: obj?.quantityDecimals ?? 0,
            requestedTime,
            receivedTime: obj?.priceTime === undefined ? dayjs().utc() : dayjs.utc(obj.priceTime)
          }
          const uplRowObject: PositionRow = {
            name: obj !== undefined ? `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${obj.instrumentName} UPL` : '',
            quantity: obj === undefined
              ? 0
              : getDeltaQuantityUpl(obj),
            priorityLevel: undefined,
            strategyColor,
            multiplier: 1,
            price: obj?.coinMarginedUnsettledInfo?.price,
            delta: 1,
            beta: 1,
            marketValue: obj === undefined ? 0 : getMarketValueUpl(obj),
            deltaMarketValue: obj === undefined ? 0 : getMarketValueUpl(obj),
            referenceMarketValue: (obj === undefined || referencePrice === undefined) ? 0 : (obj.beta ?? 1) * getMarketValueUpl(obj) / referencePrice.price,
            deltaQuantity: obj === undefined
              ? 0
              : getDeltaQuantityUpl(obj),
            priceDecimals: 2,
            quantityDecimals: 4,
            requestedTime,
            receivedTime: obj?.coinMarginedUnsettledInfo?.priceTime === undefined ? dayjs().utc() : dayjs.utc(obj.priceTime)
          }
          return (
            <>
              <TableRow
                key={inst}
                sx={{ backgroundColor: palette.secondary.main }}
              >
                <TableCell sx={{ padding: 1 }}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    disabled
                  >
                    <SubdirectoryArrowRightIcon fontSize='small'/>
                  </IconButton>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    disabled
                  >
                    <SubdirectoryArrowRightIcon fontSize='small'/>
                  </IconButton>
                </TableCell>
                {
                  columns.map((column, columnIndex) => {
                    return (
                      <ValueCellWithColor
                        key={columnIndex}
                        column={column}
                        item={mainRowObject}
                        defaultTextColor={palette.tableBodyText.main}
                        redTextColor='red'
                        greenTextColor='green'
                        dense
                      />
                    )
                  })
                }
              </TableRow>
              {(obj?.instrumentIsCoinMargined ?? false) && <TableRow
                key={inst}
                sx={{ backgroundColor: palette.secondary.main }}
              >
                <TableCell sx={{ padding: 1 }}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    disabled
                  >
                    <SubdirectoryArrowRightIcon fontSize='small'/>
                  </IconButton>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    disabled
                  >
                    <SubdirectoryArrowRightIcon fontSize='small'/>
                  </IconButton>
                </TableCell>
                {
                  columns.map((column, columnIndex) => {
                    return (
                      <ValueCellWithColor
                        key={columnIndex}
                        column={column}
                        item={uplRowObject}
                        defaultTextColor={palette.tableBodyText.main}
                        redTextColor='red'
                        greenTextColor='green'
                        dense
                      />
                    )
                  })
                }
              </TableRow>}
            </>
          )
        })
      }
    </>
  )
}

export default BaseFxRowWithPriority
