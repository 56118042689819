import React from 'react'
import { MenuItem, Select, type SelectChangeEvent } from '@mui/material'

import type { AlgoView } from './types'
import { algoViewOptions } from './constants'

function AlgoViewInput (props: {
  currentView: AlgoView
  viewChangeFunction: (view: AlgoView) => void
}): React.JSX.Element {
  const { currentView, viewChangeFunction } = props

  const handleViewChange = (event: SelectChangeEvent): void => {
    const newType: AlgoView = event.target.value as AlgoView
    viewChangeFunction(newType)
  }

  return (
    <Select
      value={currentView as string}
      onChange={handleViewChange}
      sx={{ width: '100%', height: '100%' }}
      color="accent"
    >
      {algoViewOptions.map(
        (option) => (
          <MenuItem key={option.display} value={option.type as string}>
            {option.display}
          </MenuItem>
        ))}
    </Select>
  )
}

export default AlgoViewInput
