import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { type LiteAccount } from '@r40cap/pms-sdk'
import {
  type MarginAlert,
  MarginMeasurement,
  MarginSide,
  MarginStateType,
  marginAlertsApi,
  usePermissions
} from '@r40cap/alerts-sdk'

import type { AlertRow, InputType } from './types'
import MarginAlertsTable from './MarginAlertsTable'
import { isApiError } from '../../../utils/errors'
import { getModalContent, getParamsString } from './utils'

function MarginAlerts (props: {
  accounts: LiteAccount[]
  refreshSignal: boolean
}): React.JSX.Element {
  const { accounts, refreshSignal } = props
  const [rows, setRows] = useState<AlertRow[]>([])
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)
  const { userId } = usePermissions()
  const { showSnackbar } = useRequestSnackbar()
  const navigate = useNavigate()

  const {
    data: alertsData,
    isFetching: alertsIsFetching,
    refetch: alertsRefetch,
    isError: alertsIsError,
    error: alertsError
  } = marginAlertsApi.useGetMarginAlertsQuery({})
  const [postEditMutation] = marginAlertsApi.useEditMarginAlertMutation()

  useEffect(() => {
    alertsRefetch()
      .catch((error) => { console.error(error) })
  }, [refreshSignal])

  const handleEdit = (value: any, marginAlertId: string, property: keyof MarginAlert): void => {
    const alertToEdit = (alertsData?.data ?? []).find(
      (alert) => alert.alertId === marginAlertId
    )
    if (alertToEdit !== undefined) {
      const editedAlert: Record<string, any> = { ...alertToEdit }
      editedAlert[property] = value
      postEditMutation({ edit: editedAlert as MarginAlert })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Edits Pushed',
              status: 'success'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }

  useEffect(() => {
    const accountIdToDisplay = accounts.reduce((acc: Record<string, string>, account: LiteAccount) => {
      acc[account.id] = `${account.platform.name} - ${account.name}`
      return acc
    }, {})
    const newRows = (alertsData?.data ?? []).map((alert) => {
      return {
        id: alert.alertId,
        account: accountIdToDisplay[alert.accountId] ?? alert.accountId,
        cooldownMinutes: alert.cooldownMinutes,
        stateType: alert.stateType === MarginStateType.Live
          ? 'Live'
          : alert.stateType === MarginStateType.PriceShocked
            ? 'Price Shocked'
            : alert.stateType === MarginStateType.CapacityShocked
              ? 'Capacity Shocked'
              : 'Algo Capacity',
        side: alert.side === MarginSide.Under ? 'Under' : 'Over',
        measurement: alert.measurement === MarginMeasurement.MaintenanceMarginRatio ? 'MM Ratio' : 'IM Ratio',
        params: getParamsString(alert.stateType, alert.stateParams),
        thresholds: alert.thresholds ?? undefined,
        thresholdSortDirection: (alert.side === MarginSide.Under ? 'desc' : 'asc') as 'asc' | 'desc'
      }
    })
    setRows(newRows)
  }, [alertsData?.data, accounts])

  if (userId === undefined || userId === null) {
    return <Box sx={{
      height: '100%',
      width: '100%',
      alignContent: 'center',
      justifyItems: 'center'
    }}>
      <Typography align='center' variant='h4'>Insufficient Permissions</Typography>
    </Box>
  }

  return (
    <Box sx={{ height: '100%' }}>
      <MarginAlertsTable
        rows={rows}
        isFetching={alertsIsFetching}
        handleOpenEdit={
          (
            itemId: string,
            inputType: InputType,
            label: string,
            editProperty: keyof MarginAlert
          ) => {
            setEditModalOpen(true)
            setEditModalContent(
              getModalContent(
                inputType,
                label,
                editProperty,
                handleEdit,
                itemId,
                () => { setEditModalOpen(false) }
              )
            )
          }
        }
        showAccount
        proposeDelete={(marginAlertId: string) => { navigate(`/alerts/margin/delete/${marginAlertId}`) }}
        proposeDeleteThreshold={(thresholdId: string) => { navigate(`/alerts/margin/threshold/delete/${thresholdId}`) }}
        openAddThreshold={(alertId: string) => { navigate(`/alerts/margin/addThreshold/${alertId}`) }}
        isError={alertsIsError}
        error={alertsError}
      />
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
      <Outlet />
    </Box>
  )
}

export default MarginAlerts
