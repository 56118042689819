import React from 'react'
import { Button, IconButton, Stack, Typography, useTheme } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import type { User } from '@r40cap/alerts-sdk'

function CohortUsersCellContent (props: {
  users: User[]
  proposeDeleteCohort: () => void
  proposeRemoveUser: (userId: string) => void
  proposeAddUser: () => void
}): React.JSX.Element {
  const {
    users,
    proposeDeleteCohort,
    proposeRemoveUser,
    proposeAddUser
  } = props
  const { palette } = useTheme()

  return (
    <Stack direction='column' spacing={1} alignItems={'center'} width={'80%'}>
      <Stack direction={'row'} spacing={1} width={'100%'}>
        <Button
          onClick={proposeAddUser}
          variant='outlined'
          sx={{
            width: '50%',
            color: palette.accent.main,
            borderColor: palette.accent.main,
            padding: '0px',
            fontSize: 9
          }}
        >
          Add User
        </Button>
        <Button
          variant='outlined'
          onClick={proposeDeleteCohort}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            '&:disabled': {
              opacity: 0.5,
              color: palette.background.default
            },
            '&:hover': {
              opacity: 0.9,
              backgroundColor: palette.accent.main
            },
            width: '50%',
            fontSize: 9
          }}
        >
          Delete Cohort
        </Button>
      </Stack>
      {
        users.map((user) => {
          return (
            <Stack
              key={user.userId}
              direction={'row'}
              spacing={2}
              sx={{
                backgroundColor: palette.tertiary.main,
                padding: '5px',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%'
              }}
            >
              <Typography sx={{
                fontSize: 12,
                color: palette.tableBodyText.main
              }}>{user.username}</Typography>
              <IconButton onClick={() => { proposeRemoveUser(user.userId) }}>
                <DeleteIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Stack>
          )
        })
      }
    </Stack>
  )
}

export default CohortUsersCellContent
