import React, { useEffect, useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  TableContainer,
  useTheme
} from '@mui/material'
import { type Algo, algosApi } from '@r40cap/algos-sdk'

import { algoExecutionColumns, algoExecutionColumnsNoAlgo, algoOrderColumns, algoOrderColumnsNoAlgo } from './constants'
import AlgoExecutionsTableContent from './AlgoExecutionsTableContent'
import AlgoOrdersTableBody from './AlgoOrdersTableContent'

function AlgoBotterTable (props: {
  algos?: Algo[]
  refreshSignal: boolean
  isRawBlotterView: boolean
  algoId?: string
}): React.JSX.Element {
  dayjs.extend(utc)
  const {
    algos,
    refreshSignal,
    isRawBlotterView,
    algoId
  } = props
  const { palette } = useTheme()
  const [time, setTime] = useState<Dayjs>(dayjs().utc()) // eslint-disable-line
  const [algosMap, setAlgosMap] = useState<Map<string, Algo>>(new Map())

  const {
    data: algoOrdersData,
    isFetching: algoOrdersIsFetching,
    refetch: algoOrdersRefetch,
    isError: algoOrdersIsError,
    error: algoOrdersError
  } = algosApi.useGetAlgoOrdersQuery({
    start: time.subtract(4, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[000Z]'),
    end: time.format('YYYY-MM-DDTHH:mm:ss.SSS[000Z]'),
    algoId
  })

  useEffect(() => {
    if (algos !== undefined) {
      setAlgosMap(new Map(algos.map((val) => [val.algoId, val])))
    }
  }, [algos])

  useEffect(() => {
    algoOrdersRefetch()
      .catch((error) => { console.error(error) })
  }, [refreshSignal])

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        height: '100%',
        borderRadius: '5px'
      }}
    >
      {
        isRawBlotterView
          ? <AlgoOrdersTableBody
            isLoading={algoOrdersIsFetching}
            orders={algoOrdersData?.data ?? []}
            columns={algoId === undefined ? algoOrderColumns : algoOrderColumnsNoAlgo}
            isError={algoOrdersIsError}
            error={algoOrdersError}
          />
          : <AlgoExecutionsTableContent
            orders={algoOrdersData?.data ?? []}
            columns={algoId === undefined ? algoExecutionColumns : algoExecutionColumnsNoAlgo}
            isLoading={algoOrdersIsFetching}
            isError={algoOrdersIsError}
            error={algoOrdersError}
            algoMap={algosMap}
          />
      }
    </TableContainer>
  )
}

export default AlgoBotterTable
