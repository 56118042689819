import { type ColumnDefinition } from '@r40cap/ui'
import type { Cohort } from '@r40cap/alerts-sdk'

import { type CohortRow, InputType } from './types'

export const aggregateExposureAlertColumns: Array<ColumnDefinition<CohortRow, InputType, Cohort, string>> = [
  {
    label: 'Name',
    id: 'name',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: InputType.STRING,
    editId: 'name',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]
