import { type ColumnDefinition } from '@r40cap/ui'
import type { FcmLimitAlert } from '@r40cap/alerts-sdk'

import { type AlertRow, InputType } from './types'

export const fcmLimitAlertColumns: Array<ColumnDefinition<AlertRow, InputType, FcmLimitAlert, string>> = [
  {
    label: 'Cooldown',
    id: 'cooldownMinutes',
    columnWidthPercentage: 5,
    overflowType: 'scroll',
    inputType: InputType.POS_INTEGER,
    editId: 'cooldownMinutes',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: ' min'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]
