import React, { useState, useMemo, type Key } from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  ValueCell,
  type Order,
  getComparator,
  stableSort,
  TableSkeleton,
  type ColumnDefinition
} from '@r40cap/ui'
import { type AlertDelivery } from '@r40cap/alerts-sdk'

import { alertDeliveryColumns } from './constants'
import type { DeliveryRow, InputType } from './types'
import SortableHeader from '../SortableHeader'
import TableErrorBody from '../../utils/TableErrorBody'

function AlertDeliveriesTableBody (props: {
  rows: DeliveryRow[]
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof AlertDelivery
  ) => void
  columns: Array<ColumnDefinition<DeliveryRow, InputType, AlertDelivery, string>>
  proposeDelete: (pnlAlertId: string) => void
}): React.JSX.Element {
  const {
    rows,
    handleOpenEdit,
    columns,
    proposeDelete
  } = props
  const { palette } = useTheme()

  return <TableBody>
    {
      rows.map((row, index) => {
        return (
          <TableRow
            key={row.id}
            sx={{
              cursor: 'pointer',
              backgroundColor: palette.primary.main
            }}
          >
            {columns.map((column) => (
              <ValueCell<DeliveryRow, any>
                column={column}
                item={row}
                key={column.id as Key}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                onDoubleClick={() => {
                  if (column.editId !== undefined && column.inputType !== undefined) {
                    handleOpenEdit(
                      row.id,
                      column.inputType,
                      column.label,
                      column.editId
                    )
                  }
                }}
              />
            ))}
            <TableCell align='center' sx={{ alignContent: 'center' }}>
              <Button
                variant='outlined'
                onClick={() => { proposeDelete(row.id) }}
                sx={{
                  backgroundColor: palette.accent.main,
                  color: palette.background.default,
                  '&:disabled': {
                    opacity: 0.5,
                    color: palette.background.default
                  },
                  '&:hover': {
                    opacity: 0.9,
                    backgroundColor: palette.accent.main
                  }
                }}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        )
      })
    }
    {
      rows.length === 0
        ? <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length + 1}
              sx={{ justifyItems: 'center', alignItems: 'center' }}
            >
              <Typography textAlign={'center'}>No Alerts</Typography>
            </TableCell>
          </TableRow>
        : <TableRow sx={{ height: '100%' }} />
    }
  </TableBody>
}

function AlertDeliveriesTable (props: {
  rows: readonly DeliveryRow[]
  isFetching: boolean
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof AlertDelivery
  ) => void
  proposeDelete: (deliveryId: string) => void
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    rows,
    isFetching,
    handleOpenEdit,
    proposeDelete,
    isError,
    error
  } = props
  const { palette } = useTheme()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof DeliveryRow>('method')

  const handleRequestSort = (property: keyof DeliveryRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        borderRadius: '5px',
        height: '100%'
      }}
    >
      <Table
        stickyHeader
        sx={{
          tableLayout: 'fixed',
          height: '100%'
        }}
      >
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={alertDeliveryColumns}
          aditionalEndingKeys={['delete-button']}
        />
        {
          isError
            ? <TableErrorBody
                colsToSpan={alertDeliveryColumns.length + 2}
                error={error}
              />
            : isFetching
              ? <TableSkeleton
                numRows={10}
                columns={alertDeliveryColumns}
                endBuffer={{
                  key: 'delete-button',
                  variant: 'rectangular',
                  alignment: 'center'
                }}
              />
              : <AlertDeliveriesTableBody
                rows={visibleRows}
                handleOpenEdit={handleOpenEdit}
                columns={alertDeliveryColumns}
                proposeDelete={proposeDelete}
              />
        }
      </Table>
    </TableContainer>
  )
}

export default AlertDeliveriesTable
