import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  Box,
  Grid,
  Stack,
  Table,
  TableContainer,
  TableFooter,
  Typography,
  useTheme
} from '@mui/material'
import {
  type Order,
  TableSkeleton,
  getComparator,
  stableSort,
  Modal
} from '@r40cap/ui'
import {
  type OkxContractDetails,
  type OkxPortfolioMarginConfig,
  type OkxPortfolioMarginPosition,
  type OkxCurrencyEquityTier,
  type OkxPortfolioMarginBalance,
  type OkxPortfolioMarginRiskUnit,
  riskApi
} from '@r40cap/pms-sdk'
import {
  type WorkingSpreadDefinition,
  vortexApi
} from '@r40cap/warwick-sdk'

import HealthScoreDisplay from '../HealthScoreDisplay'
import SortableHeader from '../SortableHeader'
import { collateralColumns, positionColumns } from './columns'
import type { CollateralRow, DummyCollateralObject, DummyPositionObject, PositionRow } from './types'
import AccountTableBody from '../AccountTableBody'
import type { InputType, ShockEffect } from '../types'
import { getModalContent } from '../utils'
import { getDecimals } from '../../tables/rows/utils'
import {
  getCurrencyPairForTicker,
  getPriceCurrencyDisplayForTicker,
  getSettlementCurrencyForTicker
} from './tickerUtils'
import AccountTableFooterRow from '../AccountTableFooterRow'
import ShockModalContent from '../ShockModalContent'
import TableErrorBody from '../../../utils/TableErrorBody'
import {
  getOkxDiscountedEquity,
  getOkxDerivitivesMaintenanceMarginRequirement,
  overallHaircutForCurrency,
  getOkxCurrencyEquities,
  getOkxBorrowMmr,
  getOkxBorrowImr,
  getSpotInUseAmountForRiskUnit
} from './calculations'
import AlgoParamsDisplay from '../algo/AlgoParamsDisplay'
import {
  getMultiplierForWarwickSymbol,
  getInstrumentTypeForWarwickSymbol,
  getOurSymbol,
  getBaseQuoteForWarwickSymbol,
  getSpreadId
} from '../algo/utils'

function OkxPMCollateralView (props: {
  isFetching: boolean
  balances: OkxPortfolioMarginBalance[]
  riskUnits: OkxPortfolioMarginRiskUnit[]
  priceMap: Record<string, number>
  ccyToEquityTiers: Record<string, OkxCurrencyEquityTier[]>
  contractToDetails: Record<string, OkxContractDetails>
  discountedEquity?: number
  editBalance: (currency: string, newBalance: number) => void
  editCurrencyPrice: (currency: string, newPrice: number) => void
  editMaxSpotInUse: (currency: string, newMaxSpotInUse: number) => void
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    isFetching,
    balances,
    riskUnits,
    priceMap,
    ccyToEquityTiers,
    contractToDetails,
    discountedEquity,
    editBalance,
    editCurrencyPrice,
    editMaxSpotInUse,
    isError,
    error
  } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<CollateralRow[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof CollateralRow>('marketValue')
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)

  const handleRequestSort = (property: keyof CollateralRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    const positions = riskUnits.flatMap((ru) => ru.positions)
    const ccyToEquity = getOkxCurrencyEquities(balances, positions)
    const newRows = Object.entries(ccyToEquity).map(([ccy, equity]) => {
      const price = priceMap[`${ccy}-USD`]
      const [quantDec, pxDec] = getDecimals(price)
      const haricut = overallHaircutForCurrency(equity, ccyToEquityTiers[ccy])
      const balance = balances.find((bal) => bal.ccy === ccy)
      const relevantRiskUnit = riskUnits.find((ru) => ru.ccy === ccy)
      const spotInUseUsed = relevantRiskUnit === undefined || balance === undefined
        ? 0
        : getSpotInUseAmountForRiskUnit(relevantRiskUnit, contractToDetails, balance)
      const usedStr = spotInUseUsed.toLocaleString('en-US', {
        minimumFractionDigits: quantDec,
        maximumFractionDigits: quantDec
      })
      const maxSIU = balance?.maxSpotInUse ?? Math.abs(equity)
      const maxStr = maxSIU.toLocaleString('en-US', {
        minimumFractionDigits: quantDec,
        maximumFractionDigits: quantDec
      })
      return {
        id: ccy,
        ticker: ccy,
        balance: balance?.balance ?? 0,
        equity,
        price,
        haircut: haricut,
        priceDecimals: pxDec,
        quantityDecimals: quantDec,
        marketValue: price * (balance?.balance ?? 0),
        spotInUseDesc: `${usedStr} / ${maxStr}`
      }
    })
    setRows(newRows)
  }, [balances, riskUnits, priceMap, ccyToEquityTiers, contractToDetails])

  const visibleRows = useMemo(
    () => {
      return stableSort(rows, (a, b) => {
        return getComparator(order, orderBy)({
          ...a,
          marketValue: Math.abs(a.marketValue)
        },
        {
          ...b,
          marketValue: Math.abs(b.marketValue)
        })
      })
    },
    [order, orderBy, rows]
  )

  const equityStr = discountedEquity === undefined
    ? 'not set'
    : discountedEquity.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

  function handleSubmission (value: any, selectedIds: readonly string[], property?: keyof DummyCollateralObject): void {
    if (selectedIds.length === 1) {
      if (property === 'balance') {
        editBalance(selectedIds[0], value)
      } else if (property === 'price') {
        editCurrencyPrice(selectedIds[0], value)
      } else if (property === 'maxSpotInUse') {
        editMaxSpotInUse(selectedIds[0], value)
      }
    }
  }

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center'
      }}
    >
      <Box sx={{ height: '8%' }}>
        <Typography
          sx={{
            color: palette.tableBodyText.main,
            fontSize: '1.5rem'
          }}
        >
          Collateral
        </Typography>
      </Box>
      <Box sx={{ width: '97%', height: '92%' }}>
        <TableContainer
          sx={{
            backgroundColor: palette.primary.main,
            borderRadius: '5px',
            height: '100%'
          }}
        >
          <Table
            sx={{
              tableLayout: 'fixed',
              height: '100%'
            }}
            stickyHeader
          >
            <SortableHeader
              columns={collateralColumns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {
              isError
                ? <TableErrorBody
                    error={error}
                    colsToSpan={collateralColumns.length}
                  />
                : isFetching
                  ? <TableSkeleton numRows={5} columns={collateralColumns}/>
                  : <AccountTableBody
                    rows={visibleRows}
                    handleOpenEdit={
                      (
                        itemId: string,
                        inputType: InputType,
                        label: string,
                        editProperty: keyof DummyCollateralObject
                      ) => {
                        setEditModalOpen(true)
                        setEditModalContent(
                          getModalContent(
                            inputType,
                            label,
                            editProperty,
                            () => { setEditModalOpen(false) },
                            handleSubmission,
                            [itemId],
                            () => {}
                          )
                        )
                      }
                    }
                    columns={collateralColumns}
                  />
            }
            {
              !isFetching && discountedEquity !== undefined && <TableFooter
                sx={{
                  position: 'sticky',
                  bottom: 0
                }}
              >
                <AccountTableFooterRow
                  name='Discounted Equity'
                  value={`$${equityStr}`}
                  isTopRow
                  numColumns={collateralColumns.length}
                />
              </TableFooter>
            }
          </Table>
        </TableContainer>
      </Box>
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
    </Stack>
  )
}

function OkxPMPositionsView (props: {
  isFetching: boolean
  positions: OkxPortfolioMarginPosition[]
  priceMap: Record<string, number>
  contractToDetails: Record<string, OkxContractDetails>
  maintnenaceMarginRequirement?: number
  initialMarginRequirement?: number
  editPositionSize: (instId: string, newSize: number) => void
  editPositionPrice: (instId: string, newSize: number) => void
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    isFetching,
    positions,
    priceMap,
    contractToDetails,
    maintnenaceMarginRequirement,
    initialMarginRequirement,
    editPositionSize,
    editPositionPrice,
    isError,
    error
  } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<PositionRow[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof PositionRow>('marketValue')
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)

  const handleRequestSort = (property: keyof PositionRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    const newRows: PositionRow[] = positions.map((psn) => {
      const price = psn.markPrice
      const [quantDec, pxDec] = getDecimals(price)
      const priceCurrency = getPriceCurrencyDisplayForTicker(psn.instId)
      const indexPrice = priceCurrency === 'USD'
        ? 1.0
        : priceMap[`${priceCurrency}-USD`]
      const ctSize = contractToDetails[psn.instId].size
      return {
        id: `${psn.instId}:${psn.instType}`,
        ticker: psn.instId,
        quantity: psn.amt * ctSize,
        price,
        priceCurrency,
        priceDecimals: pxDec,
        quantityDecimals: quantDec,
        marketValue: psn.amt * ctSize * price * indexPrice,
        annualizedFundingRatePct: psn.fundingRate === undefined ? undefined : psn.fundingRate * 100,
        unrealizedPnl: psn.upl
      }
    })
    setRows(newRows)
  }, [positions, contractToDetails])

  const visibleRows = useMemo(
    () => {
      return stableSort(rows, (a, b) => {
        return getComparator(order, orderBy)({
          ...a,
          marketValue: Math.abs(a.marketValue)
        },
        {
          ...b,
          marketValue: Math.abs(b.marketValue)
        })
      })
    },
    [order, orderBy, rows]
  )

  const grossNotionalStr = visibleRows.reduce((acc, row) => acc + Math.abs(row.marketValue), 0).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  const mmrStr = maintnenaceMarginRequirement === undefined
    ? 'not set'
    : maintnenaceMarginRequirement.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  const imrStr = initialMarginRequirement === undefined
    ? 'not set'
    : initialMarginRequirement.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

  function handleSubmission (value: any, selectedIds: readonly string[], property?: keyof DummyPositionObject): void {
    if (selectedIds.length === 1) {
      if (property === 'quantity') {
        const rowId = selectedIds[0]
        const instId = rowId.split(':', 2)[0]
        editPositionSize(instId, value)
      } else if (property === 'price') {
        const rowId = selectedIds[0]
        const instId = rowId.split(':', 2)[0]
        editPositionPrice(instId, value)
      }
    }
  }

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center'
      }}
    >
      <Box sx={{ height: '8%' }}>
        <Typography
          sx={{
            color: palette.tableBodyText.main,
            fontSize: '1.5rem'
          }}
        >
          Positions
        </Typography>
      </Box>
      <Box sx={{ width: '97%', height: '92%' }}>
        <TableContainer
          sx={{
            backgroundColor: palette.primary.main,
            borderRadius: '5px',
            height: '100%'
          }}
        >
          <Table
            sx={{
              tableLayout: 'fixed',
              height: '100%'
            }}
            stickyHeader
          >
            <SortableHeader
              columns={positionColumns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {
              isError
                ? <TableErrorBody
                    error={error}
                    colsToSpan={positionColumns.length}
                  />
                : isFetching
                  ? <TableSkeleton numRows={5} columns={positionColumns}/>
                  : <AccountTableBody
                    rows={visibleRows}
                    handleOpenEdit={
                      (
                        itemId: string,
                        inputType: InputType,
                        label: string,
                        editProperty: keyof DummyPositionObject
                      ) => {
                        setEditModalOpen(true)
                        setEditModalContent(
                          getModalContent(
                            inputType,
                            label,
                            editProperty,
                            () => { setEditModalOpen(false) },
                            handleSubmission,
                            [itemId],
                            () => {}
                          )
                        )
                      }
                    }
                    columns={positionColumns}
                  />
            }
            {
              !isFetching && <TableFooter
                sx={{
                  position: 'sticky',
                  bottom: 0
                }}
              >
                <AccountTableFooterRow
                  name='Gross Notional'
                  value={`$${grossNotionalStr}`}
                  isTopRow
                  numColumns={positionColumns.length}
                />
                {
                  maintnenaceMarginRequirement !== undefined && <AccountTableFooterRow
                    name='MMR'
                    value={`$${mmrStr}`}
                    isTopRow={false}
                    numColumns={positionColumns.length}
                  />
                }
                {
                  initialMarginRequirement !== undefined && <AccountTableFooterRow
                    name='IMR'
                    value={`$${imrStr}`}
                    isTopRow={false}
                    numColumns={positionColumns.length}
                  />
                }
              </TableFooter>
            }
          </Table>
        </TableContainer>
      </Box>
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
    </Stack>
  )
}

const ALGO_DEFINITIONS = [
  {
    name: 'R40BTC',
    accountId: 'c2c95711-f289-45b9-be8a-7ef76dee281f'
  },
  {
    name: 'R40ETH',
    accountId: 'c2c95711-f289-45b9-be8a-7ef76dee281f'
  },
  {
    name: 'R40SOL',
    accountId: 'c2c95711-f289-45b9-be8a-7ef76dee281f'
  }
]

function isOkxSymbol (symbol: string): boolean {
  return getInstrumentTypeForWarwickSymbol(symbol) === 'SPOT' || symbol.endsWith('OKX')
}

function isOkxShort (spread: WorkingSpreadDefinition): boolean {
  if (isOkxSymbol(spread.quoteSymbol) && getInstrumentTypeForWarwickSymbol(spread.quoteSymbol) !== 'SPOT') {
    return spread.executeQuantity < 0
  } else if (isOkxSymbol(spread.leanSymbol) && getInstrumentTypeForWarwickSymbol(spread.leanSymbol) !== 'SPOT') {
    return spread.executeQuantity > 0
  } else {
    return false
  }
}

function isOkxLong (spread: WorkingSpreadDefinition): boolean {
  if (isOkxSymbol(spread.quoteSymbol) && getInstrumentTypeForWarwickSymbol(spread.quoteSymbol) !== 'SPOT') {
    return spread.executeQuantity > 0
  } else if (isOkxSymbol(spread.leanSymbol) && getInstrumentTypeForWarwickSymbol(spread.leanSymbol) !== 'SPOT') {
    return spread.executeQuantity < 0
  } else {
    return false
  }
}

function applySpreadsToConfig (config: OkxPortfolioMarginConfig, spreads: WorkingSpreadDefinition[], priceMap: Record<string, number>): OkxPortfolioMarginConfig {
  const editableConfig = JSON.parse(JSON.stringify(config)) as OkxPortfolioMarginConfig
  spreads.forEach((spread) => {
    const [psnUpdates, balUpdates] = getOkxImpactOfWorkingSpread(spread, priceMap)
    psnUpdates.forEach((psn) => {
      const existingPsn = editableConfig.riskUnits.flatMap((ru) => ru.positions).find((pos) => pos.instId === psn.instId)
      if (existingPsn !== undefined) {
        existingPsn.amt += psn.amt
      } else {
        const riskUnitCcy = getCurrencyPairForTicker(psn.instId).split('-', 2)[0]
        const riskUnitToUpdate = editableConfig.riskUnits.find((ru) => ru.ccy === riskUnitCcy)
        if (riskUnitToUpdate !== undefined) {
          riskUnitToUpdate.positions.push(psn)
        } else {
          editableConfig.riskUnits.push({
            ccy: riskUnitCcy,
            positions: [psn],
            maxPxChange: riskUnitCcy === 'BTC' || riskUnitCcy === 'ETH' ? 0.12 : 0.18
          })
        }
      }
    })
    balUpdates.forEach((bal) => {
      const existingBal = editableConfig.balances.find((b) => b.ccy === bal.ccy)
      if (existingBal !== undefined) {
        existingBal.balance += bal.balance
      } else {
        editableConfig.balances.push(bal)
      }
    })
  })
  return editableConfig
}

function unapplySpreadsFromConfig (config: OkxPortfolioMarginConfig, spreads: WorkingSpreadDefinition[], priceMap: Record<string, number>): OkxPortfolioMarginConfig {
  const editableConfig = JSON.parse(JSON.stringify(config)) as OkxPortfolioMarginConfig
  spreads.forEach((spread) => {
    const [psnUpdates, balUpdates] = getOkxImpactOfWorkingSpread(spread, priceMap)
    psnUpdates.forEach((psn) => {
      const existingPsn = editableConfig.riskUnits.flatMap((ru) => ru.positions).find((pos) => pos.instId === psn.instId)
      if (existingPsn !== undefined) {
        existingPsn.amt -= psn.amt
      } else {
        const riskUnitCcy = getCurrencyPairForTicker(psn.instId).split('-', 2)[0]
        const riskUnitToUpdate = editableConfig.riskUnits.find((ru) => ru.ccy === riskUnitCcy)
        if (riskUnitToUpdate !== undefined) {
          riskUnitToUpdate.positions.push({ ...psn, amt: -1 * psn.amt })
        } else {
          editableConfig.riskUnits.push({
            ccy: riskUnitCcy,
            positions: [{ ...psn, amt: -1 * psn.amt }],
            maxPxChange: riskUnitCcy === 'BTC' || riskUnitCcy === 'ETH' ? 0.12 : 0.18
          })
        }
      }
    })
    balUpdates.forEach((bal) => {
      const existingBal = editableConfig.balances.find((b) => b.ccy === bal.ccy)
      if (existingBal !== undefined) {
        existingBal.balance -= bal.balance
      } else {
        editableConfig.balances.push({ ...bal, balance: -1 * bal.balance })
      }
    })
  })
  return editableConfig
}

function getOkxImpactOfWorkingSpread (spread: WorkingSpreadDefinition, priceMap: Record<string, number>): [OkxPortfolioMarginPosition[], OkxPortfolioMarginBalance[]] {
  const quoteInstrumentMultiplier = getMultiplierForWarwickSymbol(spread.quoteSymbol)
  const leanInstrumentMultiplier = getMultiplierForWarwickSymbol(spread.leanSymbol)
  const positions: OkxPortfolioMarginPosition[] = []
  const balances: OkxPortfolioMarginBalance[] = []
  if (isOkxSymbol(spread.quoteSymbol)) {
    const quoteType = getInstrumentTypeForWarwickSymbol(spread.quoteSymbol)
    if (quoteType === 'SPOT') {
      const { base, quote } = getBaseQuoteForWarwickSymbol(spread.quoteSymbol)
      const baseChange = spread.executeQuantity
      const quoteChange = -1 * (spread.executeQuantity * priceMap[spread.quoteSymbol])
      balances.push({
        ccy: base,
        balance: baseChange,
        maxSpotInUse: 0
      })
      balances.push({
        ccy: quote,
        balance: quoteChange,
        maxSpotInUse: 0
      })
    } else {
      positions.push({
        amt: spread.executeQuantity,
        instType: quoteType,
        instId: getOurSymbol(spread.quoteSymbol),
        markPrice: priceMap[spread.quoteSymbol],
        fundingRate: 0,
        upl: 0
      })
    }
  }
  if (isOkxSymbol(spread.leanSymbol)) {
    const leanType = getInstrumentTypeForWarwickSymbol(spread.leanSymbol)
    const leanChange = -1 * (spread.executeQuantity * quoteInstrumentMultiplier) / leanInstrumentMultiplier
    if (leanType === 'SPOT') {
      const { base, quote } = getBaseQuoteForWarwickSymbol(spread.leanSymbol)
      const baseChange = leanChange
      const quoteChange = -1 * (spread.executeQuantity * priceMap[spread.quoteSymbol])
      balances.push({
        ccy: base,
        balance: baseChange,
        maxSpotInUse: 0
      })
      balances.push({
        ccy: quote,
        balance: quoteChange,
        maxSpotInUse: 0
      })
    } else {
      positions.push({
        amt: leanChange,
        instType: leanType,
        instId: getOurSymbol(spread.leanSymbol),
        markPrice: priceMap[spread.leanSymbol],
        fundingRate: 0,
        upl: 0
      })
    }
  }
  return [positions, balances]
}

function OkxPMRiskContent (props: {
  hasEdited: boolean
  setHasEdited: (hasEdited: boolean) => void
  refreshSignal: boolean
  resetSignal: boolean
  shockModalOpen: boolean
  setShockModalOpen: (shockModalOpen: boolean) => void
}): React.JSX.Element {
  const { accountId } = useParams()
  const {
    hasEdited,
    setHasEdited,
    refreshSignal,
    resetSignal,
    shockModalOpen,
    setShockModalOpen
  } = props
  const [derivativesMmr, setDerivitivesMmr] = useState<number | null>(null)
  const [borrowMmr, setBorrowMmr] = useState<number | null>(null)
  const [borrowImr, setBorrowImr] = useState<number | null>(null)
  const [discountedEquity, setDiscountedEquity] = useState<number | null>(null)
  const [editedConfig, setEditedConfig] = useState<OkxPortfolioMarginConfig | null>(null)
  const [timestamp, setTimestamp] = useState<number>(Date.now())
  const [appliedMap, setAppliedMap] = useState(new Map<string, string[]>())
  const [priceMap, setPriceMap] = useState<Record<string, number>>({})
  const [dataIsEdited, setDataIsEdited] = useState<boolean>(false)

  const {
    data: riskConfigData,
    refetch: riskConfigRefetch,
    isFetching: riskConfigIsFetching,
    isError: riskConfigIsError,
    error: riskConfigError
  } = riskApi.useGetOkxPortfolioMarginConfigQuery({ accountId: accountId ?? '' })

  const algoStateQueries = ALGO_DEFINITIONS.filter((algo) => algo.accountId === accountId).map(algo => {
    return {
      algoName: algo.name,
      query: vortexApi.useGetAlgoParamsQuery({ algoName: algo.name })
    }
  })
  const algoResults = algoStateQueries.map((query) => {
    return {
      algoName: query.algoName,
      data: query.query.data,
      isFetching: query.query.isFetching,
      isError: query.query.isError,
      error: query.query.error
    }
  })

  useEffect(() => {
    const hasValidResults = algoResults.length > 0 && algoResults.every(res => !res.isFetching)
    if (hasValidResults) {
      const newMap = new Map<string, string[]>()
      const newPriceMap: Record<string, number> = {}
      algoResults.forEach((res) => {
        if (!appliedMap.has(res.algoName)) {
          newMap.set(res.algoName, [])
        } else {
          newMap.set(res.algoName, appliedMap.get(res.algoName) ?? [])
        }
      })
      algoResults.forEach((res) => {
        if (res.data !== undefined) {
          res.data.forEach((algo) => {
            algo.symbols.forEach((symbol) => {
              if (symbol.marketFair !== -1) {
                priceMap[symbol.symbol] = symbol.marketFair
              }
            })
          })
        }
      })
      if (newMap.size !== appliedMap.size || Array.from(newMap.keys()).some(k => !appliedMap.has(k))) {
        setAppliedMap(newMap)
        setPriceMap(newPriceMap)
      }
    }
  }, [algoResults.map(res => res.algoName).join(','), algoResults.map(res => res.isFetching).join(',')])

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimestamp = Date.now()
      setTimestamp(newTimestamp)
    }, 20 * 1000)
    return () => { clearInterval(interval) }
  }, [riskConfigRefetch])

  useEffect(() => {
    if (editedConfig === null) {
      setDerivitivesMmr(null)
      setBorrowMmr(null)
      setBorrowImr(null)
      setDiscountedEquity(null)
    } else {
      const derivMmr = getOkxDerivitivesMaintenanceMarginRequirement(editedConfig, timestamp)
      const borrowMmr = getOkxBorrowMmr(editedConfig)
      const borrowImr = getOkxBorrowImr(editedConfig)
      const eq = getOkxDiscountedEquity(editedConfig)
      setDerivitivesMmr(derivMmr)
      setBorrowMmr(borrowMmr)
      setBorrowImr(borrowImr)
      setDiscountedEquity(eq)
    }
  }, [editedConfig, timestamp])

  useEffect(() => {
    riskConfigRefetch().catch((err) => { console.error(err) })
    algoStateQueries.forEach((query) => {
      query.query.refetch().catch((err) => { console.error(err) })
    })
    setAppliedMap(new Map<string, string[]>())
  }, [refreshSignal])

  useEffect(() => {
    setEditedConfig(riskConfigData?.data ?? null)
    setDataIsEdited(false)
    setAppliedMap(new Map<string, string[]>())
  }, [resetSignal])

  useEffect(() => {
    setEditedConfig(riskConfigData?.data ?? null)
    setDataIsEdited(false)
  }, [riskConfigData])

  useEffect(() => {
    const numApplied = Array.from(appliedMap.values()).reduce((acc, val) => acc + val.length, 0)
    setHasEdited(numApplied > 0 || dataIsEdited)
  }, [dataIsEdited, appliedMap])

  function editBalance (currency: string, newBalance: number): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    const newConfig = JSON.parse(JSON.stringify(editedConfig)) as OkxPortfolioMarginConfig
    newConfig.balances.forEach((balance) => {
      if (balance.ccy === currency) {
        balance.balance = newBalance
      }
    })
    setEditedConfig(newConfig)
    setDataIsEdited(true)
  }

  function editMaxSpotInUse (currency: string, newMaxSpotInUse: number): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    const newConfig = JSON.parse(JSON.stringify(editedConfig)) as OkxPortfolioMarginConfig
    newConfig.balances.forEach((balance) => {
      if (balance.ccy === currency) {
        balance.maxSpotInUse = newMaxSpotInUse
      }
    })
    setEditedConfig(newConfig)
    setDataIsEdited(true)
  }

  function editPositionSize (instId: string, newSize: number): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    const newConfig = JSON.parse(JSON.stringify(editedConfig)) as OkxPortfolioMarginConfig
    const instSize = newConfig.contractToDetails[instId].size
    newConfig.riskUnits.forEach((riskUnit) => {
      riskUnit.positions.forEach((position) => {
        if (position.instId === instId) {
          position.amt = newSize / instSize
        }
      })
    })
    setEditedConfig(newConfig)
    setDataIsEdited(true)
  }

  function getCurrentCurrencyPrice (currency: string): number | undefined {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return undefined
    }
    const pair = `${currency}-USD`
    if (pair in editedConfig.priceMap) { return editedConfig.priceMap[pair] }
    return undefined
  }

  function getMarkPrice (ticker: string): number | undefined {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return undefined
    }
    const allPositions = editedConfig.riskUnits.flatMap((riskUnit) => riskUnit.positions)
    const position = allPositions.find((pos) => pos.instId === ticker)
    if (position === undefined) {
      console.log('No Position for %s', ticker)
      return undefined
    } else { return position.markPrice }
  }

  function editCurrencyPrice (currency: string, newPrice: number): void {
    console.log('Editing Currency Price for %s to %f', currency, newPrice)
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    const currentPrice = getCurrentCurrencyPrice(currency)
    if (currentPrice === undefined) {
      console.log('No Current Price Available for %s', currency)
      return
    }
    const priceFactor = newPrice / currentPrice
    const editableConfig = JSON.parse(JSON.stringify(editedConfig)) as OkxPortfolioMarginConfig
    const newConfig = modifyCurrencyPriceByFactor(editableConfig, currency, priceFactor)
    setEditedConfig(newConfig)
    setDataIsEdited(true)
  }

  function editPositionPrice (instId: string, newPrice: number): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    const currentPrice = getMarkPrice(instId)
    if (currentPrice === undefined) {
      console.log('No Mark Price Available for %s', instId)
      return
    }
    const priceFactor = newPrice / currentPrice
    const currencyPair = getCurrencyPairForTicker(instId)
    const base = currencyPair.split('-', 2)[0]
    const editableConfig = JSON.parse(JSON.stringify(editedConfig)) as OkxPortfolioMarginConfig
    const newConfig = modifyCurrencyPriceByFactor(editableConfig, base, priceFactor)
    setEditedConfig(newConfig)
    setDataIsEdited(true)
  }

  function handlePriceShock (effects: ShockEffect[]): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    let editableConfig = JSON.parse(JSON.stringify(editedConfig)) as OkxPortfolioMarginConfig
    for (const shockEffect of effects) {
      editableConfig = modifyCurrencyPriceByFactor(editableConfig, shockEffect.currencyId, shockEffect.priceFactor)
    }
    setEditedConfig(editableConfig)
    setDataIsEdited(true)
  }

  function modifyCurrencyPriceByFactor (configToEdit: OkxPortfolioMarginConfig, currency: string, factor: number): OkxPortfolioMarginConfig {
    for (const index in configToEdit.priceMap) {
      const coin = index.split('-', 2)[0]
      if (coin === currency) {
        configToEdit.priceMap[index] *= factor
      }
    }
    configToEdit.riskUnits.forEach((riskUnit) => {
      riskUnit.positions.forEach((position) => {
        const currencyPair = getCurrencyPairForTicker(position.instId)
        const [base, quote] = currencyPair.split('-', 2)
        if (base !== currency && quote !== currency) {
          return
        }
        if (position.instType === 'SWAP' || position.instType === 'FUTURES') {
          const settlementCurrency = getSettlementCurrencyForTicker(position.instId)
          const settlementCurrencyPrice = configToEdit.priceMap[`${settlementCurrency}-USD`]
          let newMarkPrice = position.markPrice
          if (base === currency) {
            newMarkPrice *= factor
          }
          if (quote === currency) {
            newMarkPrice /= factor
          }
          const pnlUsd = (newMarkPrice - position.markPrice) * position.amt * configToEdit.contractToDetails[position.instId].size
          const pnlInKind = pnlUsd / settlementCurrencyPrice
          position.markPrice = newMarkPrice
          position.upl += pnlInKind
        } else {
          console.error('Option positions not supported')
        }
      })
    })
    return configToEdit
  }

  const showAlgoDisplay = (algoResults.flatMap(res => res.data ?? []).length > 1 || algoResults.some(res => res.isError) || algoResults.some(res => res.isFetching))

  function toggleSpread (algoName: string, spreadId: string): void {
    const newMap = new Map(appliedMap)
    const newSpreads = newMap.get(algoName) ?? []
    const spreadToApply = algoResults.flatMap((res) => res.data ?? []).flatMap((algo) => algo.workingSpreads).find((spread) => getSpreadId(spread) === spreadId)
    const editableConfig = JSON.parse(JSON.stringify(editedConfig)) as OkxPortfolioMarginConfig
    if (newSpreads.includes(spreadId)) {
      if (spreadToApply !== undefined && editedConfig !== null) {
        const newConfig = unapplySpreadsFromConfig(editableConfig, [spreadToApply], priceMap)
        setEditedConfig(newConfig)
      }
      newMap.set(algoName, newSpreads.filter((sp) => sp !== spreadId))
    } else {
      if (spreadToApply !== undefined && editedConfig !== null) {
        const newConfig = applySpreadsToConfig(editableConfig, [spreadToApply], priceMap)
        setEditedConfig(newConfig)
      }
      newMap.set(algoName, newSpreads.concat(spreadId))
    }
    setAppliedMap(newMap)
  }

  function goMaxLong (): void {
    console.log(appliedMap)
    const alreadyApplied = new Set(Array.from(appliedMap.values()).flat())
    console.log(alreadyApplied)
    const allWorkingSpreads = algoResults.flatMap((res) => res.data ?? []).flatMap((algo) => algo.workingSpreads)
    const spreadsToApply = allWorkingSpreads.filter((spread) => isOkxLong(spread) && !alreadyApplied.has(getSpreadId(spread)))
    const spreadsToUnapply = allWorkingSpreads.filter((spread) => !isOkxLong(spread) && alreadyApplied.has(getSpreadId(spread)))
    const spreadsToUnapplyP1 = allWorkingSpreads.filter((spread) => !isOkxLong(spread))
    const spreadsToUnapplyP2 = allWorkingSpreads.filter((spread) => alreadyApplied.has(getSpreadId(spread)))
    console.log(spreadsToUnapply)
    console.log(spreadsToUnapplyP1)
    console.log(spreadsToUnapplyP2)
    const editableConfig = JSON.parse(JSON.stringify(editedConfig)) as OkxPortfolioMarginConfig
    const newConfig = applySpreadsToConfig(unapplySpreadsFromConfig(editableConfig, spreadsToUnapply, priceMap), spreadsToApply, priceMap)
    const spreadIdToAlgoName = new Map<string, string>()
    algoResults.forEach((res) => {
      res.data?.forEach((algo) => {
        algo.workingSpreads.forEach((spread) => {
          spreadIdToAlgoName.set(getSpreadId(spread), algo.algoName)
        })
      })
    })
    const newAppliedMap = new Map<string, string[]>(appliedMap)
    spreadsToApply.forEach((spread) => {
      const algoName = spreadIdToAlgoName.get(getSpreadId(spread))
      if (algoName !== undefined) {
        const newSpreads = newAppliedMap.get(algoName) ?? []
        newAppliedMap.set(algoName, newSpreads.concat(getSpreadId(spread)))
      }
    })
    spreadsToUnapply.forEach((spread) => {
      const algoName = spreadIdToAlgoName.get(getSpreadId(spread))
      if (algoName !== undefined) {
        const newSpreads = newAppliedMap.get(algoName) ?? []
        newAppliedMap.set(algoName, newSpreads.filter((sp) => sp !== getSpreadId(spread)))
      }
    })
    setEditedConfig(newConfig)
    setAppliedMap(newAppliedMap)
  }

  function goMaxShort (): void {
    const alreadyApplied = new Set(Array.from(appliedMap.values()).flat())
    const allWorkingSpreads = algoResults.flatMap((res) => res.data ?? []).flatMap((algo) => algo.workingSpreads)
    const spreadsToApply = allWorkingSpreads.filter((spread) => isOkxShort(spread) && !alreadyApplied.has(getSpreadId(spread)))
    const spreadsToUnapply = allWorkingSpreads.filter((spread) => !isOkxShort(spread) && alreadyApplied.has(getSpreadId(spread)))
    const editableConfig = JSON.parse(JSON.stringify(editedConfig)) as OkxPortfolioMarginConfig
    const newConfig = applySpreadsToConfig(unapplySpreadsFromConfig(editableConfig, spreadsToUnapply, priceMap), spreadsToApply, priceMap)
    const spreadIdToAlgoName = new Map<string, string>()
    algoResults.forEach((res) => {
      res.data?.forEach((algo) => {
        algo.workingSpreads.forEach((spread) => {
          spreadIdToAlgoName.set(getSpreadId(spread), algo.algoName)
        })
      })
    })
    const newAppliedMap = new Map<string, string[]>(appliedMap)
    spreadsToApply.forEach((spread) => {
      const algoName = spreadIdToAlgoName.get(getSpreadId(spread))
      if (algoName !== undefined) {
        const newSpreads = newAppliedMap.get(algoName) ?? []
        newAppliedMap.set(algoName, newSpreads.concat(getSpreadId(spread)))
      }
    })
    spreadsToUnapply.forEach((spread) => {
      const algoName = spreadIdToAlgoName.get(getSpreadId(spread))
      if (algoName !== undefined) {
        const newSpreads = newAppliedMap.get(algoName) ?? []
        newAppliedMap.set(algoName, newSpreads.filter((sp) => sp !== getSpreadId(spread)))
      }
    })
    setEditedConfig(newConfig)
    setAppliedMap(newAppliedMap)
  }

  return (
    <Stack
      sx={{ height: '100%', width: '100%', alignItems: 'center' }}
      spacing={1}
    >
      <Stack direction={'row'} spacing={1} sx={{ height: '20vh', width: '100%', justifyContent: 'space-evenly' }}>
        {
          showAlgoDisplay && <Box sx={{ width: '30%', height: '100%', minWidth: '200px' }}>
            <AlgoParamsDisplay
              algoResults={algoResults}
              isAppliedMap={appliedMap}
              toggleSpread={toggleSpread}
              goMaxLong={goMaxLong}
              goMaxShort={goMaxShort}
              configIsLoaded={editedConfig !== null}
            />
          </Box>
        }
        <Box sx={{ width: '50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '50%', height: '100%', minWidth: '250px' }}>
            <HealthScoreDisplay
              accountId={accountId ?? ''}
              score={discountedEquity !== null && derivativesMmr !== null && borrowMmr !== null ? discountedEquity / (derivativesMmr + borrowMmr) : null}
              isFetching={riskConfigIsError}
              isEdited={hasEdited}
              isError={riskConfigIsError}
            />
          </Box>
        </Box>
      </Stack>
      <Grid
        container
        overflow={'scroll'}
        sx={{ height: 'calc(78vh - 7% - 90px - 10px)' }}
      >
        <Grid item xs={12} lg={6} sx={{ height: '100%' }}>
          <OkxPMCollateralView
            isFetching={riskConfigIsFetching}
            balances={editedConfig?.balances ?? []}
            riskUnits={editedConfig?.riskUnits ?? []}
            priceMap={editedConfig?.priceMap ?? {}}
            ccyToEquityTiers={editedConfig?.ccyToEquityTiers ?? {}}
            contractToDetails={editedConfig?.contractToDetails ?? {}}
            discountedEquity={discountedEquity ?? undefined}
            editBalance={editBalance}
            editCurrencyPrice={editCurrencyPrice}
            editMaxSpotInUse={editMaxSpotInUse}
            isError={riskConfigIsError}
            error={riskConfigError}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ height: '100%' }}>
          <OkxPMPositionsView
            isFetching={riskConfigIsFetching}
            positions={editedConfig?.riskUnits.flatMap((ru) => ru.positions) ?? []}
            priceMap={editedConfig?.priceMap ?? {}}
            contractToDetails={editedConfig?.contractToDetails ?? {}}
            maintnenaceMarginRequirement={derivativesMmr !== null && borrowMmr !== null ? derivativesMmr + borrowMmr : undefined}
            initialMarginRequirement={borrowImr !== null && derivativesMmr !== null ? borrowImr + (1.3 * derivativesMmr) : undefined}
            editPositionSize={editPositionSize}
            editPositionPrice={editPositionPrice}
            isError={riskConfigIsError}
            error={riskConfigError}
          />
        </Grid>
      </Grid>
      <Modal
        handleClose={() => { setShockModalOpen(false) }}
        open={shockModalOpen}
      >
        <ShockModalContent
          processShock={handlePriceShock}
          closeModal={() => { setShockModalOpen(false) }}
        />
      </Modal>
    </Stack>
  )
}

export default OkxPMRiskContent
