import { type ColumnDefinition } from '@r40cap/ui'

import {
  type AlgoExecutionRow,
  type AlgoOrderRow,
  type AlgoPositionRow,
  type AlgoViewOption,
  AlgoView
} from './types'

export const REDIRECT_VIEW = 'positions' as AlgoView

export const algoViewOptions: AlgoViewOption[] = [
  {
    display: 'Positions',
    type: AlgoView.POSITIONS
  },
  {
    display: 'Blotter',
    type: AlgoView.BLOTTER
  }
]

export const algoPositionColumns: Array<ColumnDefinition<AlgoPositionRow, any>> = [
  {
    id: 'rowName',
    label: 'Name',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'multiplier',
    label: 'Mult',
    columnWidthPercentage: 5,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'netQuantity',
    label: 'Net',
    columnWidthPercentage: 25,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals',
    signOverrideId: 'colorSign'
  },
  {
    id: 'grossQuantity',
    label: 'Gross',
    columnWidthPercentage: 28,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  }
]

export const algoOrderColumns: Array<ColumnDefinition<AlgoOrderRow, any>> = [
  {
    id: 'displayTime',
    label: 'Time',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'algo',
    label: 'Algo',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 12,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'netQuantity',
    label: 'Qty',
    columnWidthPercentage: 17,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Inst',
    columnWidthPercentage: 17,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Px',
    columnWidthPercentage: 17,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'orderId',
    label: 'OID',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]

export const algoOrderColumnsNoAlgo: Array<ColumnDefinition<AlgoOrderRow, any>> = [
  {
    id: 'displayTime',
    label: 'Time',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 12,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'netQuantity',
    label: 'Qty',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Inst',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Px',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'orderId',
    label: 'OID',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]

export const algoExecutionColumns: Array<ColumnDefinition<AlgoExecutionRow, any>> = [
  {
    id: 'displayTime',
    label: 'Time',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'algo',
    label: 'Algo',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'sizeDescription',
    label: 'Size',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    signOverrideId: 'sizeSign'
  },
  {
    id: 'instrumentDescription',
    label: 'Instrument',
    columnWidthPercentage: 25,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'spreadDescription',
    label: 'Spread',
    columnWidthPercentage: 12,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quotePrice',
    label: 'Quote Px',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quotePriceDecimals'
  },
  {
    id: 'latencyMs',
    label: 'Latency',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: 'ms'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  }
]

export const algoExecutionColumnsNoAlgo: Array<ColumnDefinition<AlgoExecutionRow, any>> = [
  {
    id: 'displayTime',
    label: 'Time',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'sizeDescription',
    label: 'Size',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    signOverrideId: 'sizeSign'
  },
  {
    id: 'instrumentDescription',
    label: 'Instrument',
    columnWidthPercentage: 27,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'spreadDescription',
    label: 'Spread',
    columnWidthPercentage: 14,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quotePrice',
    label: 'Quote Px',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quotePriceDecimals'
  },
  {
    id: 'latencyMs',
    label: 'Latency',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: 'ms'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  }
]
