import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  Button,
  MenuItem,
  Grid,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { DateTimePicker } from '@mui/x-date-pickers'
import { EditableTable, ModalOpenButton, StringInput } from '@r40cap/ui'
import {
  type LiteAccount,
  AccountInput,
  type LiteInstrument,
  type LiteStrategy,
  StrategyInput,
  type LiteDesk,
  DeskInput,
  type LiteTrader,
  TraderInput,
  type TransactionAssetChange,
  type TransactionCreation,
  type TransactionLiability,
  type Loan,
  blotterApi,
  type TransactionLiabilityClosure,
  type Liability,
  TransactionAssetChangeCreator,
  TransactionLiabilityCreator
} from '@r40cap/pms-sdk'

import {
  existingLiabilityColumns,
  loanTransactionSelectOptions,
  transactionAssetChangeColumns,
  transactionLiabilityColumns
} from '../constants'
import type {
  LoanTransactionAdditionSelectOption,
  ExistingLiabilityRow
} from '../types'
import type {
  AddAssetChangeRow,
  AddLiabilityRow,
  BlotterInputType
} from '../../common/types'
import SmallLegTable from '../SmallLegTable'
import { getBlotterEditModalContent } from '../../utils'
import RatedLegInput from '../../common/BlotterInputs/RatedLegInput'
import LegInput from '../../common/BlotterInputs/LegInput'

function Custom (props: {
  submit: (transaction: TransactionCreation) => void
  loan: Loan
}): React.JSX.Element {
  const { submit, loan } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [trader, setTrader] = useState<LiteTrader | null>(null)
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [comments, setComments] = useState<string>('')
  const [assetChanges, setAssetChanges] = useState<readonly TransactionAssetChange[]>([])
  const [openedLiabilities, setOpenedLiabilities] = useState<readonly TransactionLiability[]>([])
  const [closedLiabilities, setClosedLiabilities] = useState<readonly string[]>([])

  const [assetChangeAddModalOpen, setAssetChangeAddModalOpen] = useState<boolean>(false)
  const [selectedAssetChangeIds, setSelectedAssetChangeIds] = useState<readonly string[]>([])
  const [liabilityAddModalOpen, setLiabilityAddModalOpen] = useState<boolean>(false)
  const [selectedLiabilityIds, setSelectedLiabilityIds] = useState<readonly string[]>([])

  const {
    data: existingLiabilitiesData,
    isFetching: isExistingLiabilitiesFetching
  } = blotterApi.useGetLoanLiabilitiesQuery({ loanId: loan.id })
  const [existingLiabilitiesSetDataSignal, setExistingLiabilitiesSetDataSignal] = useState<boolean>(false)
  useEffect(() => {
    setExistingLiabilitiesSetDataSignal(!existingLiabilitiesSetDataSignal)
  }, [existingLiabilitiesData])

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function appendAssetChange (assetChange: TransactionAssetChange): void {
    setAssetChanges(assetChanges.concat(assetChange))
    setAssetChangeAddModalOpen(false)
  }

  const getAssetChangePreset = (rowId: string, property: keyof TransactionAssetChange): any => {
    const relevantObject = assetChanges.find((dataRow: TransactionAssetChange) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  const handleAssetChangeEdit = (property: keyof TransactionAssetChange, value: any, selected: readonly string[]): void => {
    const updatedData = assetChanges.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    setAssetChanges(updatedData)
  }

  function appendIncurredLiability (liability: TransactionLiability): void {
    setOpenedLiabilities(openedLiabilities.concat(liability))
    setLiabilityAddModalOpen(false)
  }

  const getLiabilityPreset = (rowId: string, property: keyof TransactionLiability): any => {
    const relevantObject = openedLiabilities.find((dataRow: TransactionLiability) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  const handleLiabilityEdit = (property: keyof TransactionLiability, value: any, selected: readonly string[]): void => {
    const updatedData = openedLiabilities.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    setOpenedLiabilities(updatedData)
  }

  function handleSubmission (): void {
    if (
      time !== null
    ) {
      const txnId = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: trader ?? undefined,
        expectedSettlementTime: undefined,
        comments,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges: assetChanges.map((value) => value),
        openedLiabilities: openedLiabilities.map((value) => value),
        closedLiabilities: closedLiabilities.map((value) => {
          const closure: TransactionLiabilityClosure = {
            id: value,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm')
          }
          return closure
        })
      }
      submit(txn)
    }
  }

  function closeSelected (selectedIds: readonly string[]): void {
    const closedSet = new Set(closedLiabilities.concat(selectedIds))
    setClosedLiabilities(Array.from(closedSet))
  }

  function uncloseSelected (selectedIds: readonly string[]): void {
    setClosedLiabilities(
      closedLiabilities.filter((liability) => !selectedIds.includes(liability))
    )
  }

  return (
    <Stack spacing={1}>
      <Grid container spacing={1} sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <TraderInput
            setTrader={setTrader}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography>Time</Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <StringInput
            value={comments}
            setValue={setComments}
            title='Comments'
            color={palette.accent.main}
          />
        </Grid>
      </Grid>
      <SmallLegTable<ExistingLiabilityRow, Liability>
        title='Existing Liabilities'
        columns={existingLiabilityColumns}
        data={
          existingLiabilitiesData !== undefined && existingLiabilitiesData !== null
            ? {
                data: existingLiabilitiesData.data.filter((liability) => (
                  !(closedLiabilities.includes(liability.id) as boolean) &&
                  (
                    liability.closedTransaction === undefined ||
                    liability.closedTransaction === null
                  )
                ))
              }
            : null
        }
        translationFunciton={(change) => {
          return {
            ...change,
            account: change.account.name,
            strategy: (
              change.strategy === undefined || change.strategy === null ? '' : `${change.strategy.superStrategy.name}: ${change.strategy.name}`
            ),
            desk: change.desk?.name ?? '',
            interestRate: `${change.interestRate * 100}%`,
            topUpLevel: change.topUpLevel === undefined || change.topUpLevel === null ? '' : `${change.topUpLevel}`,
            instrument: change.instrument.displayTicker,
            quantityDecimals: change.instrument.quantityDecimals,
            priceDecimals: change.instrument.priceDecimals,
            openTime: change.openedTime,
            counterparty: change.associatedLoan.counterparty.name,
            loanId: change.associatedLoan.id
          }
        }}
        setDataSignal={existingLiabilitiesSetDataSignal}
        isFetching={isExistingLiabilitiesFetching}
        handleAction={closeSelected}
        actionLabel={'Close'}
      />
      <SmallLegTable<ExistingLiabilityRow, Liability>
        title='Closed Liabilities'
        columns={existingLiabilityColumns}
        data={
          existingLiabilitiesData !== undefined && existingLiabilitiesData !== null
            ? {
                data: existingLiabilitiesData.data.filter((liability) => closedLiabilities.includes(liability.id)).map((liab) => {
                  return {
                    ...liab,
                    time: liab.closedTime ?? '',
                    settledTime: liab.closedSettledTime ?? ''
                  }
                })
              }
            : null
        }
        translationFunciton={(change) => {
          return {
            ...change,
            account: change.account.name,
            strategy: (
              change.strategy === undefined || change.strategy === null ? '' : `${change.strategy.superStrategy.name}: ${change.strategy.name}`
            ),
            desk: change.desk?.name ?? '',
            topUpLevel: change.topUpLevel === undefined || change.topUpLevel === null ? '' : `${change.topUpLevel}`,
            interestRate: `${change.interestRate * 100}%`,
            instrument: change.instrument.displayTicker,
            quantityDecimals: change.instrument.quantityDecimals,
            priceDecimals: change.instrument.priceDecimals,
            openTime: change.openedTime,
            counterparty: change.associatedLoan.counterparty.name,
            loanId: change.associatedLoan.id
          }
        }}
        setDataSignal={existingLiabilitiesSetDataSignal}
        isFetching={isExistingLiabilitiesFetching}
        handleAction={uncloseSelected}
        actionLabel={'Keep'}
      />
      <Stack direction={'row'} sx={{ width: '100%' }}>
        <Box sx={{ width: '50%' }} textAlign={'left'}>
          <Typography>Asset Changes</Typography>
        </Box>
        <Box sx={{ width: '50%' }} textAlign={'right'}>
          <ModalOpenButton
            modalOpen={assetChangeAddModalOpen}
            setModalOpen={setAssetChangeAddModalOpen}
            modalContents={<Box
              sx={{
                width: '40vw',
                padding: '40px'
              }}
            >
              <TransactionAssetChangeCreator
                mainColor={palette.accent.main}
                secondaryColor={palette.tableBodyText.main}
                callColor={palette.success.main}
                putColor={palette.error.main}
                submitFunction={appendAssetChange}
                includeBaseFxFilter
                includeEntityFilter
                includeInstrumentTypeFilter
                includeSuperStrategyFilter
                enhanced
              />
            </Box>}
          >
            <AddIcon />
          </ModalOpenButton>
        </Box>
      </Stack>
      <Box
        sx={{ height: '40vh' }}
      >
        <EditableTable<AddAssetChangeRow, TransactionAssetChange, BlotterInputType, string>
          rows={assetChanges.map((assetChange) => {
            return {
              id: assetChange.id,
              time: assetChange.time,
              settledTime: assetChange.settledTime ?? '',
              account: `${assetChange.account.platform.name} - ${assetChange.account.name}`,
              strategy: (
                assetChange.strategy === undefined || assetChange.strategy === null
                  ? ''
                  : `${assetChange.strategy.superStrategy.name} - ${assetChange.strategy.name}`
              ),
              desk: assetChange.desk?.name ?? '',
              instrument: assetChange.instrument.displayTicker,
              quantity: assetChange.quantity,
              price: assetChange.price,
              isFee: assetChange.isFee,
              quantityDecimals: assetChange.instrument.quantityDecimals,
              priceDecimals: assetChange.instrument.priceDecimals,
              transactionType: assetChange.transactionType?.name ?? ''
            }
          })}
          columns={transactionAssetChangeColumns}
          selected={selectedAssetChangeIds}
          setSelected={setSelectedAssetChangeIds}
          defaultOrderBy={'instrument'}
          defaultOrder={'desc'}
          isFetching={false}
          getPreset={getAssetChangePreset}
          getModalContent={(
            inputType: BlotterInputType,
            label: string,
            editProperty: keyof TransactionAssetChange,
            closeModal: () => void,
            newSelected: readonly string[],
            initialValue: any
          ) => {
            return getBlotterEditModalContent<TransactionAssetChange>(
              inputType,
              label,
              editProperty,
              closeModal,
              handleAssetChangeEdit,
              newSelected,
              initialValue
            )
          }}
          checkboxColor={palette.accent.main}
          backgroundColor={palette.primary.main}
          headerBackgroundColor={palette.background.default}
          headerTextColor={palette.tableHeaderText.main}
          headerActiveTextColor={palette.accent.main}
          bodyDefaultTextColor={palette.tableBodyText.main}
          bodyPrimaryBackgroundColor={palette.tertiary.main}
          redTextColor='red'
          greenTextColor='green'
          dense
        />
      </Box>
      <Stack direction={'row'} sx={{ width: '100%' }}>
        <Box sx={{ width: '50%' }} textAlign={'left'}>
          <Typography>Opened Liabilities</Typography>
        </Box>
        <Box sx={{ width: '50%' }} textAlign={'right'}>
          <ModalOpenButton
            modalOpen={liabilityAddModalOpen}
            setModalOpen={setLiabilityAddModalOpen}
            modalContents={<Box
              sx={{
                width: '40vw',
                padding: '40px'
              }}
            >
              <TransactionLiabilityCreator
                mainColor={palette.accent.main}
                secondaryColor={palette.tableBodyText.main}
                textColor={palette.tableBodyText.main}
                callColor={palette.success.main}
                putColor={palette.error.main}
                submitFunction={appendIncurredLiability}
                includeBaseFxFilter
                includeEntityFilter
                includeInstrumentTypeFilter
                includeSuperStrategyFilter
                enhanced
                loanId={loan.id}
              />
            </Box>}
          >
            <AddIcon />
          </ModalOpenButton>
        </Box>
      </Stack>
      <Box
        sx={{ height: '40vh' }}
      >
        <EditableTable<AddLiabilityRow, TransactionLiability, BlotterInputType, string>
          rows={openedLiabilities.map((liability) => {
            return {
              id: liability.id,
              account: `${liability.account.platform.name} - ${liability.account.name}`,
              openTime: liability.time,
              strategy: (
                liability.strategy === undefined || liability.strategy === null
                  ? ''
                  : `${liability.strategy.superStrategy.name} - ${liability.strategy.name}`
              ),
              desk: liability.desk?.name ?? '',
              instrument: liability.instrument.displayTicker,
              quantity: liability.quantity,
              price: liability.price,
              interestRate: `${liability.interestRate * 100}%`,
              topUpLevel: liability.topUpLevel === undefined || liability.topUpLevel === null ? '' : `${liability.topUpLevel}`,
              quantityDecimals: liability.instrument.quantityDecimals,
              priceDecimals: liability.instrument.priceDecimals
            }
          })}
          columns={transactionLiabilityColumns}
          selected={selectedLiabilityIds}
          setSelected={setSelectedLiabilityIds}
          defaultOrderBy={'instrument'}
          defaultOrder={'desc'}
          isFetching={false}
          getPreset={getLiabilityPreset}
          getModalContent={(
            inputType: BlotterInputType,
            label: string,
            editProperty: keyof TransactionLiability,
            closeModal: () => void,
            newSelected: readonly string[],
            initialValue: any
          ) => {
            return getBlotterEditModalContent<TransactionLiability>(
              inputType,
              label,
              editProperty,
              closeModal,
              handleLiabilityEdit,
              newSelected,
              initialValue
            )
          }}
          checkboxColor={palette.accent.main}
          backgroundColor={palette.primary.main}
          headerBackgroundColor={palette.background.default}
          headerTextColor={palette.tableHeaderText.main}
          headerActiveTextColor={palette.accent.main}
          bodyDefaultTextColor={palette.tableBodyText.main}
          bodyPrimaryBackgroundColor={palette.tertiary.main}
          redTextColor='red'
          greenTextColor='green'
          dense
        />
      </Box>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

function BasicBorrow (props: {
  submit: (transaction: TransactionCreation) => void
  loan: Loan
}): React.JSX.Element {
  const { submit, loan } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [trader, setTrader] = useState<LiteTrader | null>(null)
  const [account, setAccount] = useState<LiteAccount | null>(null)
  const [strategy, setStrategy] = useState<LiteStrategy | null>(null)
  const [desk, setDesk] = useState<LiteDesk | null>(null)
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [comments, setComments] = useState<string>('')

  // Borrowed Leg
  const [borrowedInstrument, setBorrowedInstrument] = useState<LiteInstrument | null>(null)
  const [borrowedAmtStr, setBorrowedAmtStr] = useState<string>('')
  const [borrowedPriceStr, setBorrowedPriceStr] = useState<string>('')
  const [borrowedRateStr, setBorrowedRateStr] = useState<string>('')
  const [borrowedTopUpStr, setBorrowedTopUpStr] = useState<string>('')
  // Lent Leg
  const [lentInstrument, setLentInstrument] = useState<LiteInstrument | null>(null)
  const [lentAmtStr, setLentAmtStr] = useState<string>('')
  const [lentPriceStr, setLentPriceStr] = useState<string>('')
  const [lentRateStr, setLentRateStr] = useState<string>('')
  const [lentTopUpStr, setLentTopUpStr] = useState<string>('')
  // Fee Leg
  const [feeInstrument, setFeeInstrument] = useState<LiteInstrument | null>(null)
  const [feeAmtStr, setFeeAmtStr] = useState<string>('')

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function handleSubmission (): void {
    if (
      time !== null &&
      account !== null
    ) {
      let assetChanges: TransactionAssetChange[] = []
      let openedLiabilities: TransactionLiability[] = []
      if (
        borrowedAmtStr !== '' &&
        borrowedRateStr !== '' &&
        borrowedInstrument !== null
      ) {
        const assetChangeId = uuidv4()
        const incurredLiabilityId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${assetChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: borrowedInstrument,
            quantity: Math.abs(parseFloat(borrowedAmtStr)),
            price: Math.abs(parseFloat(borrowedPriceStr)),
            isFee: false
          }
        ])
        openedLiabilities = openedLiabilities.concat([
          {
            id: `new-${incurredLiabilityId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: borrowedInstrument,
            quantity: Math.abs(parseFloat(borrowedAmtStr)),
            price: Math.abs(parseFloat(borrowedPriceStr)),
            interestRate: parseFloat(borrowedRateStr) / 100,
            topUpLevel: borrowedTopUpStr === '' ? undefined : parseFloat(borrowedTopUpStr) / 100,
            associatedLoan: loan.id
          }
        ])
      }
      if (
        lentAmtStr !== '' &&
        lentRateStr !== '' &&
        lentInstrument !== null
      ) {
        const assetChangeId = uuidv4()
        const incurredLiabilityId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${assetChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: lentInstrument,
            quantity: -1 * Math.abs(parseFloat(lentAmtStr)),
            price: Math.abs(parseFloat(lentPriceStr)),
            isFee: false
          }
        ])
        openedLiabilities = openedLiabilities.concat([
          {
            id: `new-${incurredLiabilityId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: lentInstrument,
            quantity: -1 * Math.abs(parseFloat(lentAmtStr)),
            price: Math.abs(parseFloat(lentPriceStr)),
            interestRate: parseFloat(lentRateStr) / 100,
            topUpLevel: lentTopUpStr === '' ? undefined : parseFloat(lentTopUpStr) / 100,
            associatedLoan: loan.id
          }
        ])
      }
      if (
        feeAmtStr !== '' &&
        feeInstrument !== null
      ) {
        const assetChangeId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${assetChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            instrument: feeInstrument,
            quantity: -1 * Math.abs(parseFloat(feeAmtStr)),
            price: 0,
            isFee: true
          }
        ])
      }
      const txnId = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: trader ?? undefined,
        expectedSettlementTime: undefined,
        comments,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges,
        openedLiabilities,
        closedLiabilities: []
      }
      submit(txn)
    }
  }

  return (
    <Stack spacing={1}>
      <Grid spacing={1} container sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography>Time</Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <TraderInput
            setTrader={setTrader}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <StrategyInput
            setStrategy={setStrategy}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
            includeSuperStrategyFilter
          />
        </Grid>
        <Grid item xs={6}>
          <DeskInput
            setDesk={setDesk}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <AccountInput
            setAccount={setAccount}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
            includeEntityFilter
          />
        </Grid>
        <Grid item xs={6}>
          <StringInput
            value={comments}
            setValue={setComments}
            title='Comments'
            color={palette.accent.main}
          />
        </Grid>
        <Grid item xs={12}>
          <RatedLegInput
            legName='Borrowed'
            amountStr={borrowedAmtStr}
            setAmountStr={setBorrowedAmtStr}
            priceStr={borrowedPriceStr}
            setPriceStr={setBorrowedPriceStr}
            interestRateStr={borrowedRateStr}
            setInterestRateStr={setBorrowedRateStr}
            topUpLevelStr={borrowedTopUpStr}
            setTopUpLevelStr={setBorrowedTopUpStr}
            setInstrument={setBorrowedInstrument}
          />
        </Grid>
        <Grid item xs={12}>
          <RatedLegInput
            legName='Posted'
            amountStr={lentAmtStr}
            setAmountStr={setLentAmtStr}
            priceStr={lentPriceStr}
            setPriceStr={setLentPriceStr}
            interestRateStr={lentRateStr}
            setInterestRateStr={setLentRateStr}
            topUpLevelStr={lentTopUpStr}
            setTopUpLevelStr={setLentTopUpStr}
            setInstrument={setLentInstrument}
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Fee'
            amountStr={feeAmtStr}
            setAmountStr={setFeeAmtStr}
            setInstrument={setFeeInstrument}
          />
        </Grid>
      </Grid>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

export function AddTransactionToLoanModal (props: {
  submit: (transaction: TransactionCreation) => void
  loan: Loan
}): React.JSX.Element {
  const { submit, loan } = props
  const [viewOption, setViewOption] = useState<LoanTransactionAdditionSelectOption | undefined>(loanTransactionSelectOptions.at(0))

  let content: React.JSX.Element
  switch (viewOption?.type) {
    case 'basicLoan':
      content = <BasicBorrow submit={submit} loan={loan}/>
      break
    default:
      content = <Custom submit={submit} loan={loan}/>
  }

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const newType = event.target.value
    const targetOption = loanTransactionSelectOptions.find((option) => option.type === newType)
    setViewOption(targetOption)
  }

  return (
    <Box
      sx={{
        width: `${viewOption?.width ?? 90}vw`,
        maxHeight: '95vh',
        overflow: 'scroll'
      }}
    >
      <Stack direction={'column'} padding={3} spacing={1}>
        <Box textAlign={'left'} sx={{ width: '100%' }}>
          <Select
            value={viewOption?.type ?? 'basicLoan'}
            onChange={handleTypeChange}
            sx={{ width: '15vw' }}
            color="accent"
          >
            {
              loanTransactionSelectOptions.map(
                (option, i) => {
                  return (
                    <MenuItem value={option.type} key={i}>
                      {option.display}
                    </MenuItem>
                  )
                }
              )
            }
          </Select>
        </Box>
        {content}
      </Stack>
    </Box>
  )
}
