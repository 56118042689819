import { type ColumnDefinition } from '@r40cap/ui'
import type { AggregateExposureAlert } from '@r40cap/alerts-sdk'

import { type AlertRow, InputType } from './types'

export const aggregateExposureAlertColumns: Array<ColumnDefinition<AlertRow, InputType, AggregateExposureAlert, string>> = [
  {
    label: 'Desk',
    id: 'desk',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: InputType.DESK,
    editId: 'deskId',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Cooldown',
    id: 'cooldownMinutes',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: InputType.POS_INTEGER,
    editId: 'cooldownMinutes',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: ' min'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]
