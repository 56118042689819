import React, { useEffect, useState } from 'react'
import {
  TableFooter,
  TableRow,
  TableCell,
  useTheme
} from '@mui/material'
import FunctionsIcon from '@mui/icons-material/Functions'
import {
  type ColumnDefinition,
  ValueCell
} from '@r40cap/ui'
import type { Algo } from '@r40cap/algos-sdk'

import type { AlgoExecutionRow, AlgoExecutionPlusOrders } from './types'
import { getExecutionSummaryRow, getGroupingKeyForAlgoExecution } from './matching'

function getUniqueSummaryGroups (rows: AlgoExecutionRow[]): string[] {
  const uniqueGroups = new Set<string>()
  for (const row of rows) {
    uniqueGroups.add(getGroupingKeyForAlgoExecution(row))
  }
  return Array.from(uniqueGroups.values())
}

function AlgoExecutionFooterRow (props: {
  containedRows: AlgoExecutionPlusOrders[]
  columns: Array<ColumnDefinition<AlgoExecutionRow, any>>
  algoMap: Map<string, Algo>
}): React.JSX.Element {
  const { containedRows, columns, algoMap } = props
  const { palette } = useTheme()

  const rowObject = getExecutionSummaryRow(containedRows, algoMap)

  return (
    <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
      <TableCell>
        <FunctionsIcon />
      </TableCell>
      {
        rowObject.error !== undefined
          ? <TableCell colSpan={columns.length - 2} sx={{ color: 'red' }}>{rowObject.error}</TableCell>
          : columns.map((column, idx) => (
            <ValueCell<AlgoExecutionRow, any>
              column={column}
              item={{
                time: '',
                displayTime: '',
                algo: rowObject.algo,
                sizeDescription: rowObject.totalSizeDescription,
                instrumentDescription: rowObject.instrumentDescription,
                spreadDescription: rowObject.averageSpreadDescription,
                quotePrice: rowObject.averageQuotePrice,
                latencyMs: rowObject.averageLatencyMs,
                sizeSign: rowObject.totalSizeSign,
                quotePriceDecimals: rowObject.quotePriceDecimals
              }}
              key={column.id}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
              dense
            />
          ))
      }
    </TableRow>
  )
}

function AlgoExecutionsSummaryFooter (props: {
  selectedRows: AlgoExecutionPlusOrders[]
  columns: Array<ColumnDefinition<AlgoExecutionRow, any>>
  algoMap: Map<string, Algo>
}): React.JSX.Element {
  const { selectedRows, columns, algoMap } = props
  const [uniqueSummaryKeys, setUniqueSummaryKeys] = useState<string[]>([])
  const { palette } = useTheme()

  useEffect(() => {
    const keys = getUniqueSummaryGroups(selectedRows.map((row) => row.row))
    const sortedKeys = keys.sort()
    setUniqueSummaryKeys(sortedKeys)
  }, [selectedRows])

  return (
    <TableFooter sx={{
      position: 'sticky',
      bottom: 0,
      outline: `1px solid ${palette.tableBodyText.main}`
    }}>
      {
        uniqueSummaryKeys.map((key, i) => {
          const summaryRows = selectedRows.filter((row) => getGroupingKeyForAlgoExecution(row.row) === key)
          return (
            <AlgoExecutionFooterRow
              key={i}
              containedRows={summaryRows}
              columns={columns}
              algoMap={algoMap}
            />
          )
        })
      }
    </TableFooter>
  )
}

export default AlgoExecutionsSummaryFooter
