import React, { useState, useMemo, type Key } from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  Button,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  ValueCell,
  type Order,
  getComparator,
  stableSort,
  TableSkeleton,
  type ColumnDefinition
} from '@r40cap/ui'
import { type PriceAlert } from '@r40cap/alerts-sdk'

import { priceAlertColumns } from './constants'
import type { AlertRow, InputType } from './types'
import SortableHeader from '../SortableHeader'
import TableErrorBody from '../../utils/TableErrorBody'

function PriceAlertsTableBody (props: {
  rows: AlertRow[]
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof PriceAlert
  ) => void
  columns: Array<ColumnDefinition<AlertRow, InputType, PriceAlert, string>>
  proposeDelete: (priceAlertId: string) => void
  toggleLive: (priceAlertId: string) => void
}): React.JSX.Element {
  const {
    rows,
    handleOpenEdit,
    columns,
    proposeDelete,
    toggleLive
  } = props
  const { palette } = useTheme()

  return <TableBody>
    {
      rows.map((row, index) => {
        return (
          <TableRow
            key={row.id}
            sx={{
              cursor: 'pointer',
              backgroundColor: palette.primary.main
            }}
          >
            <TableCell align='center' sx={{ alignContent: 'center' }}>
              <Switch
                sx={{
                  '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: palette.accent.main,
                    opacity: 0.9
                  },
                  '& .MuiSwitch-thumb': { color: palette.primary.main },
                  '& .MuiSwitch-track': { backgroundColor: palette.tableBodyText.main }
                }}
                color='default'
                checked={row.isLive}
                onChange={() => { toggleLive(row.id) }}
              />
            </TableCell>
            {columns.map((column) => (
              <ValueCell<AlertRow, any>
                column={column}
                item={row}
                key={column.id as Key}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                onDoubleClick={() => {
                  if (column.editId !== undefined && column.inputType !== undefined) {
                    handleOpenEdit(
                      row.id,
                      column.inputType,
                      column.label,
                      column.editId
                    )
                  }
                }}
              />
            ))}
            <TableCell align='center' sx={{ alignContent: 'center' }}>
              <Button
                variant='outlined'
                onClick={() => { proposeDelete(row.id) }}
                sx={{
                  backgroundColor: palette.accent.main,
                  color: palette.background.default,
                  '&:disabled': {
                    opacity: 0.5,
                    color: palette.background.default
                  },
                  '&:hover': {
                    opacity: 0.9,
                    backgroundColor: palette.accent.main
                  }
                }}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        )
      })
    }
    {
      rows.length === 0
        ? <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length + 2}
              sx={{ justifyItems: 'center', alignItems: 'center' }}
            >
              <Typography textAlign={'center'}>No Alerts</Typography>
            </TableCell>
          </TableRow>
        : <TableRow sx={{ height: '100%' }} />
    }
  </TableBody>
}

function PriceAlertsTable (props: {
  rows: readonly AlertRow[]
  isFetching: boolean
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof PriceAlert
  ) => void
  proposeDelete: (priceAlertId: string) => void
  toggleLive: (priceAlertId: string) => void
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    rows,
    isFetching,
    handleOpenEdit,
    proposeDelete,
    toggleLive,
    isError,
    error
  } = props
  const { palette } = useTheme()
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof AlertRow>('lastTriggered')

  const handleRequestSort = (property: keyof AlertRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const visibleRows = useMemo(
    () => {
      return stableSort(rows, (a, b) => {
        return getComparator(order, orderBy)({ ...a, lastTriggered: a.lastTriggered ?? '0' }, { ...b, lastTriggered: b.lastTriggered ?? '0' })
      })
    },
    [order, orderBy, rows]
  )

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        borderRadius: '5px',
        height: '100%'
      }}
    >
      <Table
        stickyHeader
        sx={{
          tableLayout: 'fixed',
          height: '100%'
        }}
      >
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={priceAlertColumns}
          aditionalEndingKeys={['delete-button']}
          aditionalStartingKeys={['live-switch']}
        />
        {
          isError
            ? <TableErrorBody
                colsToSpan={priceAlertColumns.length + 2}
                error={error}
              />
            : isFetching
              ? <TableSkeleton
                numRows={10}
                columns={priceAlertColumns}
                frontBuffer={{
                  key: 'live-switch',
                  variant: 'rectangular',
                  alignment: 'center'
                }}
                endBuffer={{
                  key: 'delete- button',
                  variant: 'rectangular',
                  alignment: 'center'
                }}
              />
              : <PriceAlertsTableBody
                rows={visibleRows}
                handleOpenEdit={handleOpenEdit}
                columns={priceAlertColumns}
                proposeDelete={proposeDelete}
                toggleLive={toggleLive}
              />
        }
      </Table>
    </TableContainer>
  )
}

export default PriceAlertsTable
