import React from 'react'
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Switch,
  useTheme
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { DatePicker } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import { RefreshButton } from '@r40cap/ui'

import PushButton from '../utils/PushButton'

function PricingControlsPanel (props: {
  date: Dayjs
  setDate: (day: Dayjs) => void
  showAll: boolean
  setShowAll: (value: boolean) => void
  refreshFunction: () => void
  stuffToPush: boolean
  pushFunction: () => void
  openNewPriceModal: (instrumentId: string | null, prefillPriceStr: string, prefillDeltaStr: string) => void
  openMassManualModal: () => void
}): React.JSX.Element {
  const {
    date,
    setDate,
    showAll,
    setShowAll,
    refreshFunction,
    stuffToPush,
    pushFunction,
    openNewPriceModal,
    openMassManualModal
  } = props
  const { palette } = useTheme()

  const styles = {
    datePickerContainer: {
      height: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        height: '100%',
        width: '100%'
      },
      '& .MuiInputBase-root': {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }
    }
  }

  const handleChangeDate = (newDate: Dayjs | null): void => {
    if (newDate !== null) {
      setDate(newDate)
    }
  }

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={6} sx={{ height: '100%' }}>
        <Stack direction={'row'} alignItems={'center'} spacing={3} sx={{ height: '100%', width: '100%' }}>
          <Box sx={styles.datePickerContainer}>
            <DatePicker
              value={date}
              onChange={(newValue) => { handleChangeDate(newValue) }}
            />
          </Box>
          <FormGroup>
            <FormControlLabel
              control={<Switch
                sx={{
                  '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: palette.accent.main,
                    opacity: 0.9
                  },
                  '& .MuiSwitch-thumb': {
                    color: palette.primary.main
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: palette.tableBodyText.main
                  }
                }}
                color='default'
                checked={showAll}
                onChange={() => { setShowAll(!showAll) }}
              />}
              label='Include Auto Prices?'
              sx={{ color: palette.tableBodyText.main }}
            />
          </FormGroup>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'center'}
        sx={{ height: '100%' }}
      >
        <Grid
          item
          xs={6}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{ height: '100%' }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignContent={'center'}
            sx={{ height: '50%' }}
          >
            { stuffToPush && <PushButton
              pushFunction={pushFunction}
            /> }
            <IconButton
              onClick={() => { openNewPriceModal(null, '', '') }}
            >
              <AddIcon />
            </IconButton>
            <Button
              variant='contained'
              style={{
                width: '100%',
                backgroundColor: palette.accent.main,
                color: palette.primary.main
              }}
              onClick={openMassManualModal}
            >
              Update Manuals
            </Button>
            <RefreshButton
              refreshFunction={refreshFunction}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PricingControlsPanel
