import React from 'react'
import { MenuItem, Select, type SelectChangeEvent } from '@mui/material'

import type { AlertsType } from './types'
import { alertsTypeOptions } from './constants'

function AlertsTypeInput (props: {
  currentType: AlertsType
  typeChangeFunction: (view: AlertsType) => void
}): React.JSX.Element {
  const { currentType, typeChangeFunction } = props

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const newType: AlertsType = event.target.value as AlertsType
    typeChangeFunction(newType)
  }

  return (
    <Select
      value={currentType}
      onChange={handleTypeChange}
      sx={{ width: '100%', height: '100%' }}
      color="accent"
    >
      {alertsTypeOptions.map(
        (option) => (
          <MenuItem key={option.display} value={option.type}>
            {option.display}
          </MenuItem>
        ))}
    </Select>
  )
}

export default AlertsTypeInput
