export interface DeliveryRow {
  id: string
  method: string
  cohort?: string
  methodId?: string
}

export enum InputType {
  STRING
}
