import React, { useEffect, useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Button,
  Box,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
  useTheme
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { RefreshButton } from '@r40cap/ui'
import { AccountInput, type LiteAccount, type PlatformSnapshot, SnapshotInput } from '@r40cap/pms-sdk'

import ReconciliationViewSelection from '../ReconciliationViewSelection'
import { type ReconciliationOption } from '../types'
import { getLastMark } from '../../../utils/times'

const PLATFORM_ID_TO_SECONDARY_SOURCE_NAME = {
  '1eb4d67a-0d47-4666-b7ec-825223292b64': 'StoneX API'
}

function UpdatingControlsPanel (props: {
  refreshFunction: () => void
  logFunction: () => void
  showLogButton: boolean
  viewType: ReconciliationOption
  setViewType: (type: ReconciliationOption) => void
  account: LiteAccount | null
  setAccount: (account: LiteAccount | null) => void
  snapshot: PlatformSnapshot | null
  setSnapshot: (snapshot: PlatformSnapshot | null) => void
  liveSystemTimeOverride: Dayjs | null
  setLiveSystemTimeOverride: (value: Dayjs | null) => void
  snapshotSystemTimeOverride: Dayjs | null
  setSnapshotSystemTimeOverride: (value: Dayjs | null) => void
  snapshotClearSignal: boolean
  useSecondarySource: boolean
  setUseSecondarySource: (value: boolean) => void
}): React.JSX.Element {
  dayjs.extend(utc)
  const {
    refreshFunction,
    showLogButton,
    logFunction,
    viewType,
    setViewType,
    account,
    setAccount,
    snapshot,
    setSnapshot,
    liveSystemTimeOverride,
    setLiveSystemTimeOverride,
    snapshotSystemTimeOverride,
    setSnapshotSystemTimeOverride,
    snapshotClearSignal,
    useSecondarySource,
    setUseSecondarySource
  } = props
  const { palette } = useTheme()
  const [secondarySourceDescription, setSecondarySourceDescription] = useState<string | null>(null)

  const styles = {
    dateTimePickerContainer: {
      height: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        height: '100%',
        width: '100%'
      },
      '& .MuiInputBase-root': {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }
    }
  }

  useEffect(() => {
    setUseSecondarySource(false)
    if (account === null || snapshot !== null) {
      setSecondarySourceDescription(null)
    } else {
      if (account.platform.id in PLATFORM_ID_TO_SECONDARY_SOURCE_NAME) {
        setSecondarySourceDescription(PLATFORM_ID_TO_SECONDARY_SOURCE_NAME[account.platform.id as keyof typeof PLATFORM_ID_TO_SECONDARY_SOURCE_NAME])
      } else {
        setSecondarySourceDescription(null)
      }
    }
  }, [account?.platform.id, snapshot?.id])

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={10} sx={{ height: '100%' }}>
        <Stack direction={'row'} alignItems={'center'} spacing={3} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ height: '100%', width: '10%' }}>
            <ReconciliationViewSelection
              currentType={viewType}
              typeChangeFunction={setViewType}
            />
          </Box>
          <Box sx={{ width: '35%', height: '100%' }}>
            <AccountInput
              setAccount={setAccount}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              enhanced={false}
              includeEntityFilter
            />
          </Box>
          {
            secondarySourceDescription !== null && <Box sx={{ width: '10%', height: '100%' }}>
              <FormControlLabel
                control={<Switch
                  size='small'
                  sx={{
                    '& .Mui-checked+.MuiSwitch-track': {
                      backgroundColor: palette.accent.main,
                      opacity: 0.9
                    },
                    '& .MuiSwitch-thumb': {
                      color: palette.primary.main
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: palette.tableBodyText.main
                    }
                  }}
                  color='default'
                  checked={useSecondarySource}
                  onChange={() => { setUseSecondarySource(!useSecondarySource) }}
                />}
                label={<Typography
                  sx={{ color: palette.tableBodyText.main, fontSize: 12 }}
                >Use {secondarySourceDescription}?</Typography>}
              />
            </Box>
          }
          {
            account !== null && <Box sx={{ width: '20%', height: '100%' }}>
              <SnapshotInput
                accountId={account.id}
                clearSignal={snapshotClearSignal}
                setSnapshot={setSnapshot}
                mainColor={palette.accent.main}
                secondaryColor={palette.tableBodyText.main}
              />
            </Box>
          }
          {
            account !== null && <Box sx={{ width: '25%', height: '100%' }}>
              <Box sx={styles.dateTimePickerContainer}>
                  <DateTimePicker
                    value={snapshot !== null ? snapshotSystemTimeOverride : liveSystemTimeOverride}
                    onChange={snapshot !== null ? setSnapshotSystemTimeOverride : setLiveSystemTimeOverride}
                    format='YYYY-MM-DD HH:mm'
                    closeOnSelect={false}
                    ampm={false}
                    timeSteps={{ hours: 1, minutes: 1 }}
                    label='System Override Time'
                    slotProps={{
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    onOpen={() => {
                      if (snapshot !== null) {
                        if (snapshotSystemTimeOverride === null) {
                          const ssTime = dayjs(snapshot.snapshotTime, 'YYYY-MM-DD HH:mm')
                          setSnapshotSystemTimeOverride(ssTime)
                        }
                      } else {
                        if (liveSystemTimeOverride === null) {
                          setLiveSystemTimeOverride(getLastMark())
                        }
                      }
                    }}
                  />
                </Box>
            </Box>
          }
          {

          }
        </Stack>
      </Grid>
      <Grid
        item
        xs={2}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'center'}
        sx={{ height: '100%' }}
      >
        <Grid
          item
          xs={6}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{ height: '100%' }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignContent={'center'}
            sx={{ height: '50%' }}
          >
            {
              showLogButton && <Button
                variant='outlined'
                onClick={logFunction}
                sx={{
                  outlineColor: palette.accent.main,
                  color: palette.accent.main
                }}
              >
                LOG
              </Button>
            }
            <RefreshButton
              refreshFunction={refreshFunction}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UpdatingControlsPanel
