import React, { useEffect, useState } from 'react'
import { riskApi } from '@r40cap/pms-sdk'

import PlatformBalancesSubTableRow from './PlatformBalancesSubTableRow'
import PlatformPositionsSubTableRow from './PlatformPositionsSubTableRow'
import PlatformOpenOrdersSubTableRow from './PlatformOpenOrdersSubTableRow'
import { accountColumns } from '../constants'

function PlatformRows (props: {
  accountIds: readonly string[]
  includeSmallAmounts: boolean
  smallThreshold: number
  refreshSignal: boolean
}): React.JSX.Element {
  const {
    accountIds,
    includeSmallAmounts,
    smallThreshold,
    refreshSignal
  } = props
  const [priceMap, setPriceMap] = useState<Map<string, number>>(new Map<string, number>())

  const psnsQueries = accountIds.map(accountId => riskApi.useGetPlatformPositionsQuery({ accountId }))
  const collateralQueries = accountIds.map(accountId => riskApi.useGetPlatformCollateralQuery({ accountId }))
  const ordersQueries = accountIds.map(accountId => riskApi.useGetPlatformOpenOrdersQuery({ accountId }))

  const psnsData = psnsQueries.map(query => query.data?.data ?? []).flat()
  const psnsIsFetching = psnsQueries.some(query => query.isFetching)
  const psnsRefetch = async (): Promise<void> => {
    await Promise.all(psnsQueries.map(async query => await query.refetch()))
  }
  const psnsIsError = psnsQueries.some(query => query.isError)
  const psnsError = psnsQueries.map(query => query.error).filter(Boolean)

  const collateralData = collateralQueries.map(query => query.data?.data ?? []).flat()
  const collateralIsFetching = collateralQueries.some(query => query.isFetching)
  const collateralRefetch = async (): Promise<void> => {
    await Promise.all(collateralQueries.map(async query => await query.refetch()))
  }
  const collateralIsError = collateralQueries.some(query => query.isError)
  const collateralError = collateralQueries.map(query => query.error).filter(Boolean)

  const ordersData = ordersQueries.map(query => query.data?.data ?? []).flat()
  const ordersIsFetching = ordersQueries.some(query => query.isFetching)
  const ordersRefetch = async (): Promise<void> => {
    await Promise.all(ordersQueries.map(async query => await query.refetch()))
  }
  const ordersIsError = ordersQueries.some(query => query.isError)
  const ordersError = ordersQueries.map(query => query.error).filter(Boolean)

  useEffect(() => {
    collateralRefetch().catch((error) => { console.error('Error Refreshing Collateral', error) })
    psnsRefetch().catch((error) => { console.error('Error Refreshing Positions', error) })
    ordersRefetch().catch((error) => { console.error('Error Refreshing Open Orders', error) })
  }, [refreshSignal])

  useEffect(() => {
    const newPriceMap = new Map<string, number>()
    collateralData.forEach(item => newPriceMap.set(item.id, item.price))
    setPriceMap(newPriceMap)
  }, [collateralData])

  return <>
    <PlatformBalancesSubTableRow
      colsToSpan={accountColumns.length + 1}
      isFetching={collateralIsFetching}
      containedRows={collateralData}
      includeSmallAmounts={includeSmallAmounts}
      smallThreshold={smallThreshold}
      isError={collateralIsError}
      error={collateralError.length > 0 ? collateralError[0] : undefined}
    />
    <PlatformPositionsSubTableRow
      colsToSpan={accountColumns.length + 1}
      isFetching={psnsIsFetching}
      containedRows={psnsData}
      includeSmallAmounts={includeSmallAmounts}
      smallThreshold={smallThreshold}
      currencyPriceMap={priceMap}
      isError={psnsIsError}
      error={psnsError.length > 0 ? psnsError[0] : undefined}
    />
    <PlatformOpenOrdersSubTableRow
      colsToSpan={accountColumns.length + 1}
      isFetching={ordersIsFetching}
      containedRows={ordersData}
      currencyPriceMap={priceMap}
      isError={ordersIsError}
      error={ordersError.length > 0 ? ordersError[0] : undefined}
    />
  </>
}

export default PlatformRows
