export function getDecimals (price: number, multiplier: number): [number, number] {
  const valuePer = price * multiplier
  let priceDecimals: number = 0
  let quantityDecimals: number = 0
  if (valuePer < 0.00000001) {
    priceDecimals = 10
    quantityDecimals = 0
  } else if (valuePer < 0.000001) {
    priceDecimals = 8
    quantityDecimals = 0
  } else if (valuePer < 0.0001) {
    priceDecimals = 6
    quantityDecimals = 0
  } else if (valuePer < 0.01) {
    priceDecimals = 4
    quantityDecimals = 0
  } else if (valuePer < 1) {
    priceDecimals = 2
    quantityDecimals = 2
  } else if (valuePer < 10) {
    priceDecimals = 2
    quantityDecimals = 2
  } else if (valuePer < 100) {
    priceDecimals = 2
    quantityDecimals = 4
  } else if (valuePer < 10000) {
    priceDecimals = 2
    quantityDecimals = 4
  } else {
    priceDecimals = 2
    quantityDecimals = 4
  }
  return [quantityDecimals, priceDecimals]
}
