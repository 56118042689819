import type { User } from '@r40cap/alerts-sdk'

export interface CohortRow {
  id: string
  name: string
  users: User[]
}

export enum InputType {
  STRING
}

export type RowKeysExceptUsers = Exclude<keyof CohortRow, 'users'>
