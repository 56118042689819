import React from 'react'
import type { PnlAlert } from '@r40cap/alerts-sdk'

import { InputType } from './types'
import {
  DeskEditContent,
  IntegerEditContent,
  StringEditContent
} from '../EditModals'

export function getModalContent (
  inputType: InputType,
  label: string,
  editProperty: keyof PnlAlert,
  handleSubmission: (value: any, alertId: string, property: keyof PnlAlert) => void,
  alertId: string,
  closeModal: () => void
): React.JSX.Element {
  let content: React.JSX.Element = <></>

  function submit (value: any): void {
    handleSubmission(value, alertId, editProperty)
  }

  switch (inputType) {
    case InputType.DESK:
      content = <DeskEditContent
        closeModal={closeModal}
        submit={submit}
      />
      break
    case InputType.POS_INTEGER:
      content = <IntegerEditContent
        title={label}
        closeModal={closeModal}
        submit={(sub: number) => { submit(Math.abs(sub)) }}
      />
      break
    default:
      content = <StringEditContent
        closeModal={closeModal}
        submit={submit}
        title={label}
      />
  }
  return content
}
