import type { AlertThreshold } from '@r40cap/alerts-sdk'

export interface AlertRow {
  id: string
  desk: string
  cooldownMinutes: number
  lookbackMinutes: number
  thresholds: AlertThreshold[]
}

export enum InputType {
  POS_INTEGER,
  DESK
}

export type RowKeysExceptThresholds = Exclude<keyof AlertRow, 'thresholds'>
