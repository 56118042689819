import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  useTheme
} from '@mui/material'
import { RefreshButton } from '@r40cap/ui'

import { REDIRECT_VIEW } from './constants'
import { AlgoView } from './types'
import AlgoViewInput from './AlgoViewInput'

function AlgosControlsPanel (props: {
  refreshFunction: () => void
  isRawBlotterView: boolean
  setIsRawBlotterView: (value: boolean) => void
  includeViewSelection: boolean
}): React.JSX.Element {
  const {
    refreshFunction,
    isRawBlotterView,
    setIsRawBlotterView,
    includeViewSelection
  } = props
  const location = useLocation()
  const navigate = useNavigate()
  const { palette } = useTheme()
  const algoView = (location.pathname.split('/')[2] ?? REDIRECT_VIEW) as AlgoView

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      {
        includeViewSelection
          ? <Box
            sx={{
              width: '20%',
              minWidth: '150px'
            }}
          >
            <AlgoViewInput
              currentView={algoView}
              viewChangeFunction={(newView) => { navigate(`/algos/${newView}`) }}
            />
          </Box>
          : <Box />
      }
      {
        !includeViewSelection || algoView === AlgoView.BLOTTER
          ? <Box
            sx={{
              width: '20%',
              minWidth: '150px'
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={<Switch
                  sx={{
                    '& .Mui-checked+.MuiSwitch-track': {
                      backgroundColor: palette.accent.main,
                      opacity: 0.9
                    },
                    '& .MuiSwitch-thumb': {
                      color: palette.primary.main
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: palette.tableBodyText.main
                    }
                  }}
                  color='default'
                  checked={!isRawBlotterView}
                  onChange={() => { setIsRawBlotterView(!isRawBlotterView) }}
                />}
                label='Group Orders?'
                sx={{ color: palette.tableBodyText.main }}
              />
            </FormGroup>
          </Box>
          : <Box />
      }
      <Stack
        direction={'row'}
        spacing={1}
        alignContent={'center'}
        sx={{ height: '50%' }}
      >
        <RefreshButton
          refreshFunction={refreshFunction}
          iconColor={palette.primary.main}
          buttonColor={palette.accent.main}
        />
      </Stack>
    </Box>
  )
}

export default AlgosControlsPanel
