import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { EditableTable, ModalOpenButton, StringInput } from '@r40cap/ui'
import {
  TraderInput,
  type LiteTrader,
  type TransactionAssetChange,
  TransactionAssetChangeCreator,
  type TransactionCreation
} from '@r40cap/pms-sdk'
import {
  type TransactionType as OnchainTxnType,
  TransactionTypeInput as OnchainTxnTypeInput,
  onchainTransactionCreationApi
} from '@r40cap/onchain-txn-creation-sdk'
import { getErrorMessage } from '../../../../utils/errors'
import { getBlotterEditModalContent } from '../../utils'
import type { AddAssetChangeRow, BlotterInputType } from '../../common/types'
import { transactionAssetChangeColumns } from '../../common/constants'

function EntryModal (props: {
  txnHash: string
  setTxnHash: (hash: string) => void
  txnType: OnchainTxnType
  setTxnType: (type: OnchainTxnType) => void
  searchTransaction: () => void
}): React.JSX.Element {
  const { txnHash, setTxnHash, txnType, setTxnType, searchTransaction } = props
  const { palette } = useTheme()
  return (
    <Stack direction='column' spacing={2} alignItems={'center'}>
      <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
        <Box sx={{ width: '20%' }}>
          <OnchainTxnTypeInput
            type={txnType}
            setType={setTxnType}
            textColor={palette.tableBodyText.main}
            outlineColor={palette.accent.main}
          />
        </Box>
        <Box sx={{ width: '80%' }}>
          <StringInput
            value={txnHash}
            setValue={setTxnHash}
            title='Txn Hash'
            color={txnHash.startsWith('0x') ? 'red' : palette.accent.main}
          />
        </Box>
      </Stack>
      {
        txnHash.startsWith('0x') && <Typography color='error'>
          EVM Not Yet Supported
        </Typography>
      }
      <Button
        variant='contained'
        onClick={searchTransaction}
        sx={{
          backgroundColor: palette.accent.main,
          color: palette.primary.main,
          width: '50%'
        }}
      >
        Build Transaction
      </Button>
    </Stack>
  )
}

function ErrorModal (props: {
  errorMessage: string
  retry: () => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const { errorMessage, retry } = props
  return (
    <Stack direction='column' spacing={2} alignItems={'center'}>
      <Typography color='error'>
        {errorMessage}
      </Typography>
      <Button
        variant='contained'
        onClick={retry}
        sx={{
          backgroundColor: palette.accent.main,
          color: palette.primary.main,
          width: '50%'
        }}
      >
        Back
      </Button>
    </Stack>
  )
}

function ProcessingModal (): React.JSX.Element {
  const { palette } = useTheme()
  return (
    <Box sx={{ width: '100%', height: '20vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress sx={{ color: palette.accent.main }}/>
    </Box>
  )
}

function ConfirmTransaction (props: {
  txnHash: string
  assetChanges: readonly TransactionAssetChange[]
  submit: (transaction: TransactionCreation) => void
}): React.JSX.Element {
  const {
    txnHash,
    assetChanges,
    submit
  } = props
  const { palette } = useTheme()
  const [trader, setTrader] = useState<LiteTrader | null>(null)
  const [comments, setComments] = useState<string>('')
  const [entryPlatform, setEntryPlatform] = useState<string>('onchain-txn')
  const [entryPlatformId, setEntryPlatformId] = useState<string>(txnHash)

  const [editedAssetChanges, setEditedAssetChanges] = useState<readonly TransactionAssetChange[]>(assetChanges)
  const [assetChangeAddModalOpen, setAssetChangeAddModalOpen] = useState<boolean>(false)
  const [selectedAssetChangeIds, setSelectedAssetChangeIds] = useState<readonly string[]>([])

  function appendAssetChange (assetChange: TransactionAssetChange): void {
    setEditedAssetChanges([...editedAssetChanges, assetChange])
    setAssetChangeAddModalOpen(false)
  }

  const getAssetChangePreset = (rowId: string, property: keyof TransactionAssetChange): any => {
    const relevantObject = assetChanges.find((dataRow: TransactionAssetChange) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  const handleAssetChangeEdit = (property: keyof TransactionAssetChange, value: any, selected: readonly string[]): void => {
    const updatedData = editedAssetChanges.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    console.log(updatedData)
    setEditedAssetChanges(updatedData)
  }

  function handleSubmission (): void {
    const id = uuidv4()
    const txn: TransactionCreation = {
      id: `new-${id}`,
      trader: trader ?? undefined,
      expectedSettlementTime: undefined,
      comments,
      entryPlatform,
      entryPlatformId,
      assetChanges: [...editedAssetChanges],
      openedLiabilities: [],
      closedLiabilities: []
    }
    submit(txn)
  }

  return (
    <Stack direction='column' spacing={2} alignItems={'center'}>
      <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
        <Box sx={{ width: '20%' }}>
          <TraderInput
            setTrader={setTrader}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Box>
        <Box sx={{ width: '80%' }}>
          <StringInput
            value={comments}
            setValue={setComments}
            title='Comments'
            color={palette.accent.main}
          />
        </Box>
      </Stack>
      <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
        <Box sx={{ width: '20%' }}>
          <StringInput
            value={entryPlatform}
            setValue={setEntryPlatform}
            title='Entry Platform'
            color={palette.accent.main}
          />
        </Box>
        <Box sx={{ width: '80%' }}>
          <StringInput
            value={entryPlatformId}
            setValue={setEntryPlatformId}
            title='Platform ID'
            color={palette.accent.main}
          />
        </Box>
      </Stack>
      <Box sx={{ width: '100%' }} textAlign={'right'}>
        <ModalOpenButton
          modalOpen={assetChangeAddModalOpen}
          setModalOpen={setAssetChangeAddModalOpen}
          modalContents={<Box
            sx={{
              width: '40vw',
              padding: '40px'
            }}
          >
            <TransactionAssetChangeCreator
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              callColor={palette.success.main}
              putColor={palette.error.main}
              submitFunction={appendAssetChange}
              includeBaseFxFilter
              includeEntityFilter
              includeInstrumentTypeFilter
              includeSuperStrategyFilter
              enhanced
            />
          </Box>}
        >
          <AddIcon />
        </ModalOpenButton>
      </Box>
      <Box
        sx={{ height: '40vh' }}
      >
        <EditableTable<AddAssetChangeRow, TransactionAssetChange, BlotterInputType, string>
          rows={editedAssetChanges.map((assetChange) => {
            return {
              id: assetChange.id,
              time: assetChange.time,
              settledTime: assetChange.settledTime ?? '',
              account: `${assetChange.account.platform.name} - ${assetChange.account.name}`,
              strategy: (
                assetChange.strategy === undefined || assetChange.strategy === null
                  ? ''
                  : `${assetChange.strategy.superStrategy.name} - ${assetChange.strategy.name}`
              ),
              desk: assetChange.desk?.name ?? '',
              instrument: assetChange.instrument.displayTicker,
              quantity: assetChange.quantity,
              price: assetChange.price,
              isFee: assetChange.isFee,
              quantityDecimals: assetChange.instrument.quantityDecimals,
              priceDecimals: assetChange.instrument.priceDecimals,
              transactionType: assetChange.transactionType?.name ?? ''
            }
          })}
          columns={transactionAssetChangeColumns}
          selected={selectedAssetChangeIds}
          setSelected={setSelectedAssetChangeIds}
          defaultOrderBy={'instrument'}
          defaultOrder={'desc'}
          isFetching={false}
          getPreset={getAssetChangePreset}
          getModalContent={(
            inputType: BlotterInputType,
            label: string,
            editProperty: keyof TransactionAssetChange,
            closeModal: () => void,
            newSelected: readonly string[],
            initialValue: any
          ) => {
            return getBlotterEditModalContent<TransactionAssetChange>(
              inputType,
              label,
              editProperty,
              closeModal,
              handleAssetChangeEdit,
              newSelected,
              setSelectedAssetChangeIds,
              initialValue
            )
          }}
          checkboxColor={palette.accent.main}
          backgroundColor={palette.primary.main}
          headerBackgroundColor={palette.background.default}
          headerTextColor={palette.tableHeaderText.main}
          headerActiveTextColor={palette.accent.main}
          bodyDefaultTextColor={palette.tableBodyText.main}
          bodyPrimaryBackgroundColor={palette.tertiary.main}
          redTextColor='red'
          greenTextColor='green'
          dense
        />
      </Box>
      <Button
        variant='contained'
        onClick={handleSubmission}
        sx={{
          backgroundColor: palette.accent.main,
          color: palette.primary.main,
          width: '50%'
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

type Stage = 'entry' | 'processing' | 'failure' | 'confirm'

function OnchainTransaction (props: {
  submit: (transaction: TransactionCreation) => void
}): React.JSX.Element {
  const { submit } = props
  const [stage, setStage] = useState<Stage>('entry')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [txnHash, setTxnHash] = useState<string>('')
  const [txnType, setTxnType] = useState<OnchainTxnType>('swap')
  const [assetChanges, setAssetChanges] = useState<TransactionAssetChange[]>([])
  const [processSolanaTransaction] = onchainTransactionCreationApi.useParseSolanaTransactionMutation()

  function handleSubmission (): void {
    setStage('processing')
    processSolanaTransaction({ txnHash, txnType })
      .then((result) => {
        if (result.error !== undefined) {
          setStage('failure')
          setErrorMessage(getErrorMessage(result.error))
        } else {
          setStage('confirm')
          setAssetChanges(result.data.data)
        }
      })
      .catch(() => {
        setStage('failure')
      })
  }

  return (
    stage === 'entry'
      ? <EntryModal
          txnHash={txnHash}
          setTxnHash={setTxnHash}
          txnType={txnType}
          setTxnType={setTxnType}
          searchTransaction={handleSubmission}
      />
      : stage === 'failure'
        ? <ErrorModal
          errorMessage={errorMessage}
          retry={() => { setStage('entry') }}
        />
        : stage === 'confirm'
          ? <ConfirmTransaction
            txnHash={txnHash}
            assetChanges={assetChanges}
            submit={submit}
          />
          : <ProcessingModal/>
  )
}
export default OnchainTransaction
