import React from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import { Box, Button, Stack, Switch, Tooltip, Typography, useTheme } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import DangerousIcon from '@mui/icons-material/Dangerous'
import type { WorkingSpreadDefinition, AlgoParams } from '@r40cap/warwick-sdk'

import { getSpreadId, getOurSymbol } from './utils'
import { getErrorMessage } from '../../../../utils/errors'

interface AlgoResult {
  algoName: string
  data: AlgoParams[] | undefined
  isFetching: boolean
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}

function SpreadRow (props: {
  workingSpread: WorkingSpreadDefinition
  isApplied: boolean
  toggleSpread: () => void
  enabled: boolean
}): React.JSX.Element {
  const {
    isApplied,
    workingSpread,
    toggleSpread,
    enabled
  } = props
  const { palette } = useTheme()

  const quoteDisplay = getOurSymbol(workingSpread.quoteSymbol)
  const leanSymbol = getOurSymbol(workingSpread.leanSymbol)

  return <Stack direction='row' justifyItems={'center'} alignItems='center' spacing={1}>
    <Typography
       sx={{ color: palette.tableBodyText.main, fontSize: 14 }}
    >{workingSpread.executeQuantity.toFixed(0)} {quoteDisplay} - {leanSymbol}</Typography>
    <Switch
      sx={{
        '& .Mui-checked+.MuiSwitch-track': {
          backgroundColor: palette.accent.main,
          opacity: 0.9
        },
        '& .MuiSwitch-thumb': {
          color: palette.primary.main
        },
        '& .MuiSwitch-track': {
          backgroundColor: palette.tableBodyText.main
        },
        margin: 0
      }}
      color='default'
      checked={isApplied}
      onChange={() => { toggleSpread() }}
      size='small'
      disabled={!enabled}
    />
  </Stack>
}

function AlgoParamRow (props: {
  algoName: string
  data: AlgoParams[] | undefined
  isFetching: boolean
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
  appliedSpreads: string[]
  toggleSpread: (spreadId: string) => void
  configIsLoaded: boolean
}): React.JSX.Element {
  const {
    algoName,
    data,
    isFetching,
    isError,
    error,
    toggleSpread,
    configIsLoaded
  } = props
  const { palette } = useTheme()

  const icon = isFetching
    ? <CircleIcon fontSize='small' sx={{ color: 'grey' }}/>
    : (isError || data === undefined || data.length !== 1)
        ? <DangerousIcon fontSize='small' sx={{ color: 'red' }}/>
        : data[0].tradingEnabled
          ? <CircleIcon fontSize='small' sx={{ color: 'green' }}/>
          : <CircleIcon fontSize='small' sx={{ color: 'red' }}/>
  const toolTipText = isFetching
    ? 'Loading'
    : isError
      ? getErrorMessage(error)
      : data === undefined || data.length > 1
        ? 'Invalid data'
        : data.length === 0
          ? 'No data'
          : data[0].tradingEnabled
            ? 'Enabled'
            : 'Disabled'

  const workingSpreads = isFetching
    ? []
    : (isError || data === undefined || data.length !== 1)
        ? []
        : data[0].workingSpreads

  return <Stack
    direction='row'
    sx={{
      justifyContent: 'space-between',
      alignItems: 'start',
      backgroundColor: palette.tertiary.main,
      padding: '8px',
      borderRadius: '7px'
    }}
    spacing={1}
  >
    <Stack direction='row' spacing={1} alignItems='center'>
      <Tooltip title={toolTipText} placement='left' arrow>
        {icon}
      </Tooltip>
      <Typography sx={{ fontSize: 18, color: palette.tableBodyText.main }}>{algoName}</Typography>
    </Stack>
    <Stack direction='column' spacing={1}>
      {
        workingSpreads.map((spread, i) => {
          const spreadId = getSpreadId(spread)
          return <SpreadRow
            key={i}
            workingSpread={spread}
            isApplied={props.appliedSpreads.includes(spreadId)}
            toggleSpread={() => { toggleSpread(spreadId) }}
            enabled={configIsLoaded && data !== undefined && data.length === 1}
          />
        })
      }
    </Stack>
  </Stack>
}

function AlgoParamsDisplay (props: {
  algoResults: AlgoResult[]
  isAppliedMap: Map<string, string[]>
  toggleSpread: (algoName: string, spreadId: string) => void
  goMaxLong: () => void
  goMaxShort: () => void
  configIsLoaded: boolean
}): React.JSX.Element {
  const {
    algoResults,
    isAppliedMap,
    toggleSpread,
    goMaxLong,
    goMaxShort,
    configIsLoaded
  } = props

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      <Stack
        direction='column'
        spacing={1}
        sx={{
          width: '100%',
          padding: '10px'
        }}
      >
        <Stack direction={'row'} spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
          <Button
            variant='contained'
            onClick={goMaxShort}
            sx={{
              color: 'red',
              width: '80%',
              fontSize: 9
            }}
          >
            Short
          </Button>
          <Button
            variant='contained'
            onClick={goMaxLong}
            sx={{
              color: 'green',
              width: '80%',
              fontSize: 9
            }}
          >
            Long
          </Button>
        </Stack>
        {
          algoResults.filter((res) => (res.data ?? []).length > 0 || res.isFetching || res.isError).map((res, i) => {
            return <AlgoParamRow
              key={i}
              algoName={res.algoName}
              data={res.data}
              isFetching={res.isFetching}
              isError={res.isError}
              error={res.error}
              appliedSpreads={isAppliedMap.get(res.algoName) ?? []}
              toggleSpread={(spreadId) => { toggleSpread(res.algoName, spreadId) }}
              configIsLoaded={configIsLoaded}
            />
          })
        }
      </Stack>
    </Box>
  )
}

export default AlgoParamsDisplay
