import type { AlgoOrder } from '@r40cap/algos-sdk'

export interface AlgoPositionRow {
  rowName: string
  netQuantity?: number
  grossQuantity?: number
  multiplier?: number
  account?: string
  quantityDecimals: number
  colorSign: number
}

export interface AlgoOrderRow {
  orderId: string
  time: string
  displayTime: string
  algo: string
  netQuantity: number
  instrument: string
  price: number
  account: string
  quantityDecimals: number
  priceDecimals: number
}

export interface AlgoExecutionRow {
  time: string
  displayTime: string
  algo: string
  sizeDescription: string
  instrumentDescription: string
  spreadDescription: string
  quotePrice: number
  latencyMs: number
  sizeSign: number
  quotePriceDecimals: number
  error?: string
}

export interface AlgoSummaryRow {
  algo: string
  totalSizeDescription: string
  instrumentDescription: string
  averageSpreadDescription: string
  averageQuotePrice: number
  totalSizeSign: number
  averageLatencyMs: number
  quotePriceDecimals: number
  error?: string
}

export interface AlgoExecutionPlusOrders {
  execId: string
  row: AlgoExecutionRow
  orders: AlgoOrder[]
}

export enum AlgoView {
  POSITIONS = 'positions',
  BLOTTER = 'blotter'
}

export interface AlgoViewOption {
  type: AlgoView
  display: string
}
