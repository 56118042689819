import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { assetExposureAlertsApi, type AlertThreshold } from '@r40cap/alerts-sdk'

import { isApiError } from '../../../utils/errors'
import ThresholdAddition from '../ThresholdAddition'

function AddAssetExposureAlertThresholdModal (): React.JSX.Element {
  const navigate = useNavigate()
  const { alertId } = useParams()
  const { showSnackbar } = useRequestSnackbar()
  const [performAddition] = assetExposureAlertsApi.useAddAssetExposureAlertThresholdMutation()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/alerts/assetExposure')
    }
  }

  function addThreshold (threshold: AlertThreshold): void {
    if (alertId !== undefined) {
      showSnackbar({
        isOpen: true,
        message: 'Adding Threshold',
        status: 'processing'
      })
      performAddition({
        assetExposureAlertId: alertId,
        threshold
      })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Added Threshold',
              status: 'success'
            })
            exitModal()
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to add Threshold',
            status: 'error'
          })
        })
    }
  }

  return (
    <Modal open handleClose={exitModal}>
      <ThresholdAddition addThreshold={addThreshold}/>
    </Modal>
  )
}

export default AddAssetExposureAlertThresholdModal
