import React from 'react'
import { type MarginAlert, MarginStateType } from '@r40cap/alerts-sdk'

import { InputType } from './types'
import {
  AccountEditContent,
  NumberEditContent,
  IntegerEditContent,
  StringEditContent
} from '../EditModals'

export function getModalContent (
  inputType: InputType,
  label: string,
  editProperty: keyof MarginAlert,
  handleSubmission: (value: any, alertId: string, property: keyof MarginAlert) => void,
  alertId: string,
  closeModal: () => void
): React.JSX.Element {
  let content: React.JSX.Element = <></>

  function submit (value: any): void {
    handleSubmission(value, alertId, editProperty)
  }

  switch (inputType) {
    case InputType.ACCOUNT:
      content = <AccountEditContent
        closeModal={closeModal}
        submit={submit}
      />
      break
    case InputType.FLOAT:
      content = <NumberEditContent
        title={label}
        closeModal={closeModal}
        submit={submit}
      />
      break
    case InputType.POS_FLOAT:
      content = <NumberEditContent
        title={label}
        closeModal={closeModal}
        submit={(sub: number) => { submit(Math.abs(sub)) }}
      />
      break
    case InputType.POS_INTEGER:
      content = <IntegerEditContent
        title={label}
        closeModal={closeModal}
        submit={(sub: number) => { submit(Math.abs(sub)) }}
      />
      break
    default:
      content = <StringEditContent
        closeModal={closeModal}
        submit={submit}
        title={label}
      />
  }
  return content
}

export function getParamsString (stateType: MarginStateType, params: Record<string, any> | undefined): string | undefined {
  if (params === undefined || params === null) {
    return undefined
  }
  if (stateType === MarginStateType.PriceShocked) {
    const shockCurrency = params.shockCurrency as string
    const shockSize = params.shockSize as number
    const shockSizeString = `${(shockSize * 100).toFixed(2)}%`
    return `${shockCurrency} ±${shockSizeString}`
  } else if (stateType === MarginStateType.CapacityShocked) {
    const instrument = params.instrument as string
    const quantity = params.quantity as number
    return `${quantity} ${instrument}`
  }
  return undefined
}
