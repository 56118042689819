import React from 'react'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'

function DeletionConfirmation (props: {
  resourceDescription: string
  executeDelete: () => void
  strongWarning: boolean
  cancel: () => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    executeDelete,
    resourceDescription,
    cancel,
    strongWarning
  } = props

  return (
    <Box sx={{ width: '25vw', padding: '20px' }}>
      <Stack direction={'column'} spacing={2}>
        <Typography
          variant='h3'
          sx={{ color: palette.tableBodyText.main }}
        >
          {`Delete ${resourceDescription}?`}
        </Typography>
        {
          strongWarning && <Typography
            variant='h5'
            sx={{ color: palette.tableBodyText.main }}
          >
            This action cannot be undone.
          </Typography>
        }
        <Stack direction={'row'} spacing={1}>
          <Button
            variant='outlined'
            onClick={cancel}
            sx={{
              outlineColor: palette.accent.main,
              color: palette.accent.main,
              width: '100%'
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={executeDelete}
            style={{
              width: '100%',
              backgroundColor: palette.accent.main,
              color: palette.secondary.main
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default DeletionConfirmation
