import React, { useEffect, useState } from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import { Box, Stack, IconButton, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Modal, RefreshButton, useRequestSnackbar } from '@r40cap/ui'
import {
  type AlertThreshold,
  type MarginAlert,
  MarginAlertCreator,
  MarginSide,
  MarginStateType,
  MarginMeasurement,
  marginAlertsApi
} from '@r40cap/alerts-sdk'

import { isApiError } from '../../../utils/errors'
import type { AlertRow, InputType } from './types'
import { getParamsString, getModalContent } from './utils'
import MarginAlertsTable from './MarginAlertsTable'
import DeletionConfirmation from '../DeletionConfirmation'
import ThresholdAddition from '../ThresholdAddition'

function AccountMarginAlertsModalContent (props: {
  accountId: string
  isFetching: boolean
  alerts: readonly MarginAlert[]
  refreshFunction: () => void
  handleEdit: (value: any, alertId: string, property: keyof MarginAlert) => void
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    accountId,
    isFetching,
    refreshFunction,
    alerts,
    handleEdit,
    isError,
    error
  } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<AlertRow[]>([])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<React.JSX.Element>(<></>)
  const { showSnackbar } = useRequestSnackbar()
  const [postAddition] = marginAlertsApi.useAddMarginAlertMutation()
  const [postThresholdAddition] = marginAlertsApi.useAddMarginAlertThresholdMutation()
  const [performDeleteAlert] = marginAlertsApi.useDeleteMarginAlertMutation()
  const [performDeleteThreshold] = marginAlertsApi.useDeleteMarginAlertThresholdMutation()

  useEffect(() => {
    const newRows = alerts.map((alert) => {
      return {
        id: alert.alertId,
        account: alert.accountId,
        cooldownMinutes: alert.cooldownMinutes,
        stateType: alert.stateType === MarginStateType.Live
          ? 'Live'
          : alert.stateType === MarginStateType.PriceShocked
            ? 'Price Shocked'
            : alert.stateType === MarginStateType.CapacityShocked
              ? 'Capacity Shocked'
              : 'Algo Capacity',
        side: alert.side === MarginSide.Under ? 'Under' : 'Over',
        measurement: alert.measurement === MarginMeasurement.MaintenanceMarginRatio ? 'MM Ratio' : 'IM Ratio',
        params: getParamsString(alert.stateType, alert.stateParams),
        thresholds: alert.thresholds ?? undefined,
        thresholdSortDirection: (alert.side === MarginSide.Under ? 'desc' : 'asc') as 'asc' | 'desc'
      }
    })
    setRows(newRows)
  }, [alerts])

  function addMarginAlert (alert: MarginAlert): void {
    showSnackbar({
      isOpen: true,
      message: 'Creating Alert',
      status: 'processing'
    })
    postAddition({ addition: alert })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Created Account',
            status: 'success'
          })
          closeModal()
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to create Account',
          status: 'error'
        })
      })
  }

  function addThreshold (alertId: string, threshold: AlertThreshold): void {
    showSnackbar({
      isOpen: true,
      message: 'Adding Threshold',
      status: 'processing'
    })
    postThresholdAddition({
      marginAlertId: alertId,
      threshold
    })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Added Threshold',
            status: 'success'
          })
          setModalContent(<></>)
          setModalOpen(false)
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to add Threshold',
          status: 'error'
        })
      })
  }

  function deleteAlert (marginAlertId: string): void {
    showSnackbar({
      isOpen: true,
      message: 'Deleting Alert',
      status: 'processing'
    })
    performDeleteAlert({ marginAlertId })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Deleted Alert',
            status: 'success'
          })
          setModalContent(<></>)
          setModalOpen(false)
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to delete Alert',
          status: 'error'
        })
      })
  }

  function deleteThreshold (thresholdId: string): void {
    showSnackbar({
      isOpen: true,
      message: 'Deleting Threshold',
      status: 'processing'
    })
    performDeleteThreshold({ thresholdId })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Deleted Threshold',
            status: 'success'
          })
          setModalContent(<></>)
          setModalOpen(false)
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to delete Threshold',
          status: 'error'
        })
      })
  }

  function openAddModal (): void {
    setModalContent(<Box
      sx={{
        width: '40vw',
        padding: '20px'
      }}
    >
      <MarginAlertCreator
        submitFunction={addMarginAlert}
        mainColor={palette.accent.main}
        secondaryColor={palette.tableBodyText.main}
        spacing={2}
        fixedAccountId={accountId}
      />
    </Box>)
    setModalOpen(true)
  }

  function closeModal (): void {
    setModalContent(<></>)
    setModalOpen(false)
  }

  function proposeDeleteAlert (marginAlertId: string): void {
    setModalContent(<DeletionConfirmation
      resourceDescription='Margin Alert'
      executeDelete={() => { deleteAlert(marginAlertId) }}
      cancel={() => {
        setModalContent(<></>)
        setModalOpen(false)
      }}
      strongWarning={true}
    />)
    setModalOpen(true)
  }

  function proposeDeleteThreshold (thresholdId: string): void {
    setModalContent(<DeletionConfirmation
      resourceDescription='Threshold'
      executeDelete={() => { deleteThreshold(thresholdId) }}
      cancel={() => {
        setModalContent(<></>)
        setModalOpen(false)
      }}
      strongWarning={false}
    />)
    setModalOpen(true)
  }

  function openAddThreshold (marginAlertId: string): void {
    setModalContent(<ThresholdAddition addThreshold={(threshold: AlertThreshold) => { addThreshold(marginAlertId, threshold) }}/>)
    setModalOpen(true)
  }

  return <Stack
    spacing={1}
    direction={'column'}
    width={'100%'}
    sx={{ paddingBottom: '10px', height: 'calc(100% - 10px)' }}
  >
    <Box sx={{ height: '7%' }}>
      <Stack
        direction={'row'}
        spacing={1}
        alignContent={'center'}
        justifyContent={'flex-end'}
        sx={{
          height: '50%'
        }}
      >
        <IconButton
          onClick={openAddModal}
        >
          <AddIcon />
        </IconButton>
        <RefreshButton
          refreshFunction={refreshFunction}
          iconColor={palette.primary.main}
          buttonColor={palette.accent.main}
        />
      </Stack>
    </Box>
    <Box sx={{ height: '93%' }}>
      <MarginAlertsTable
        rows={rows}
        isFetching={isFetching}
        handleOpenEdit={
          (
            itemId: string,
            inputType: InputType,
            label: string,
            editProperty: keyof MarginAlert
          ) => {
            setModalOpen(true)
            setModalContent(
              getModalContent(
                inputType,
                label,
                editProperty,
                handleEdit,
                itemId,
                () => { setModalOpen(false) }
              )
            )
          }
        }
        proposeDelete={proposeDeleteAlert}
        proposeDeleteThreshold={proposeDeleteThreshold}
        openAddThreshold={openAddThreshold}
        showAccount={false}
        isError={isError}
        error={error}
      />
    </Box>
    <Modal
      open={modalOpen}
      handleClose={closeModal}
    >
      {modalContent}
    </Modal>
  </Stack>
}

export default AccountMarginAlertsModalContent
