import React, { useEffect, useMemo, useState } from 'react'
import type { Dayjs } from 'dayjs'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  type Order,
  RowSkeleton,
  getComparator,
  stableSort
} from '@r40cap/ui'
import {
  type Price,
  portfolioApi
} from '@r40cap/pms-sdk'

import { counterpartyColumns } from './constants'
import type {
  ColumnDefinitionWithStaleness,
  FirstLevelDef,
  LiabilityRow,
  LiabilityWithPrice,
  PortfolioRow
} from './types'
import {
  OneLevelTableHead,
  PositionsTableFooter,
  ShallowRowWithRate
} from './rows'
import TableErrorBody from '../../utils/TableErrorBody'

function getUniqueCounterparties (liabilities: LiabilityWithPrice[]): FirstLevelDef[] {
  const filteredObjects = liabilities.reduce((accumulator: Record<string, LiabilityWithPrice>, currentObject: LiabilityWithPrice) => {
    accumulator[currentObject.counterpartyId] = currentObject
    return accumulator
  }, {})
  const uniqueObjects: LiabilityWithPrice[] = Object.values(filteredObjects)
  return uniqueObjects.map((psn) => {
    return {
      id: psn.counterpartyId,
      name: psn.counterpartyName
    }
  })
}

function LiabilitiesByCounterpartyTableBody (props: {
  liabilities: LiabilityWithPrice[]
  referencePrice?: Price
  isLoading: boolean
  order: Order
  orderBy: keyof PortfolioRow
  columns: Array<ColumnDefinitionWithStaleness<LiabilityRow, any>>
  requestedTime?: Dayjs
  openedCounterparties: readonly string[]
  toggleCounterparty: (counterpartyId: string) => void
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    liabilities,
    isLoading,
    order,
    orderBy,
    columns,
    referencePrice,
    requestedTime,
    openedCounterparties,
    toggleCounterparty,
    isError,
    error
  } = props
  const [counterparties, setCounterparties] = useState<FirstLevelDef[]>(getUniqueCounterparties(liabilities))

  const visibleCounterparties = useMemo(
    () => {
      return stableSort(counterparties, (a, b) => {
        const stratARows = liabilities.filter((val) => val.counterpartyId === a.id)
        const psnA = liabilities.find((val) => val.counterpartyId === a.id)
        const psnRowA: PortfolioRow = {
          name: psnA !== undefined ? psnA.counterpartyName : '',
          marketValue: stratARows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          referenceMarketValue: referencePrice !== undefined
            ? stratARows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0) / referencePrice.price
            : stratARows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0),
          priceDecimals: 0,
          quantityDecimals: 0
        }
        const stratBRows = liabilities.filter((val) => val.counterpartyId === b.id)
        const psnB = liabilities.find((val) => val.counterpartyId === b.id)
        const psnRowB: PortfolioRow = {
          name: psnB !== undefined ? psnB.counterpartyName : '',
          marketValue: stratBRows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          referenceMarketValue: referencePrice !== undefined
            ? stratBRows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0) / referencePrice.price
            : stratBRows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0),
          priceDecimals: 0,
          quantityDecimals: 0
        }
        return getComparator(order, orderBy)({ ...psnRowA, requestedTime: undefined, receivedTime: undefined }, { ...psnRowB, requestedTime: undefined, receivedTime: undefined })
      })
    },
    [order, orderBy, liabilities, counterparties]
  )

  useEffect(() => {
    setCounterparties(getUniqueCounterparties(liabilities))
  }, [liabilities])

  if (isError) {
    return (
      <TableErrorBody
        colsToSpan={columns.length + 1}
        error={error}
      />
    )
  } else if (isLoading) {
    const rows = []
    for (let i = 0; i < 5; i++) {
      rows.push(
        <RowSkeleton
          usedKey={i}
          columns={columns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
          key={i}
        />
      )
    }
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  } else {
    const rows = visibleCounterparties.map((counterparty, idx) => {
      return (
        <ShallowRowWithRate
          rowName={counterparty.name}
          bottomRows={liabilities.filter((psn) => psn.counterpartyId === counterparty.id)}
          key={counterparty.id}
          order={order}
          orderBy={orderBy}
          columns={columns}
          referencePrice={referencePrice}
          requestedTime={requestedTime}
          isOpen={openedCounterparties.includes(counterparty.id)}
          toggleOpen={() => { toggleCounterparty(counterparty.id) }}
        />
      )
    })
    if (rows.length > 0) {
      return (
        <TableBody>
          {rows}
          <TableRow sx={{ height: '100%' }} />
        </TableBody>
      )
    } else {
      return (
        <TableBody>
          <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length + 1}
              align='center'
            >
              <Typography sx={{ fontSize: 20 }}>No Data</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
  }
}

function LiabilitiesByCounterpartyTable (props: {
  time?: Dayjs
  referencePrice?: Price
  includeUnsettled: boolean
  includeOwed: boolean
  refreshSignal: boolean
  pxData?: Price[]
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    time,
    includeUnsettled,
    refreshSignal,
    referencePrice,
    includeOwed,
    pxData
  } = props

  const [openedCounterparties, setOpenedCounterparties] = useState<readonly string[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof PortfolioRow>('marketValue')
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [queryParams, setQueryParams] = useState({
    time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
    includeUnsettled,
    includeOwed,
    force: false
  })
  const [combinedData, setCombinedData] = useState<LiabilityWithPrice[]>([])

  const {
    data: liabData,
    refetch: liabRefetch,
    isFetching: liabIsFetching,
    isError: liabIsError,
    error: liabError
  } = portfolioApi.useGetLiabilitiesByCounterpartyQuery(queryParams, {
    skip: isInitialLoad
  })

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isInitialLoad])

  useEffect(() => {
    setQueryParams({
      time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
      includeUnsettled,
      includeOwed,
      force: false
    })
  }, [time, includeUnsettled, includeOwed])

  useEffect(() => {
    if (!isInitialLoad) {
      if (!queryParams.force) {
        setQueryParams((prevParams) => ({
          ...prevParams,
          force: true
        }))
      } else {
        liabRefetch().catch(() => {
          console.error('Error Refreshing')
        })
      }
    }
  }, [refreshSignal])

  useEffect(() => {
    if (liabData !== null && liabData !== undefined) {
      const combined = liabData.data.map(liab => {
        const priceData = (pxData ?? []).find(px => px.instrument.id === liab.instrumentId)
        return {
          ...liab,
          price: priceData?.price,
          beta: priceData?.beta,
          delta: priceData?.delta,
          priceTime: priceData?.time
        }
      })
      setCombinedData(combined)
    }
  }, [liabData, pxData])

  const handleRequestSort = (property: keyof PortfolioRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const columnsToUse = referencePrice !== undefined
    ? counterpartyColumns.map((column) => {
      if (column.id === 'referenceMarketValue') {
        const labelToUse = `MV (${referencePrice.instrument.displayTicker})`
        const decToUse = referencePrice.instrument.quantityDecimals
        const valDetsToUse = {
          ...column.valueDetails,
          suffix: `\u00A0${referencePrice.instrument.displayTicker}`
        }
        return {
          ...column,
          label: labelToUse,
          decimals: decToUse,
          valueDetails: valDetsToUse
        }
      } else {
        return column
      }
    })
    : counterpartyColumns

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        height: '100%',
        borderRadius: '5px'
      }}
    >
      <Table stickyHeader sx={{ height: '100%' }}>
        <OneLevelTableHead
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          columns={columnsToUse}
          closeAll={() => {
            setOpenedCounterparties([])
          }}
          openAll={() => {
            setOpenedCounterparties(Array.from(new Set(combinedData.map((c) => c.counterpartyId))))
          }}
          openDesc='CPs'
          isLoading={liabIsFetching}
        />
        <LiabilitiesByCounterpartyTableBody
          isLoading={liabIsFetching}
          liabilities={combinedData}
          order={order}
          orderBy={orderBy}
          columns={columnsToUse}
          referencePrice={referencePrice}
          requestedTime={time}
          openedCounterparties={openedCounterparties}
          toggleCounterparty={(counterpartyId) => {
            if (openedCounterparties.includes(counterpartyId)) {
              setOpenedCounterparties(openedCounterparties.filter((a) => a !== counterpartyId))
            } else {
              setOpenedCounterparties([...openedCounterparties, counterpartyId])
            }
          }}
          isError={liabIsError}
          error={liabError}
        />
        <PositionsTableFooter
          isLoading={liabIsFetching}
          totalObject={{
            name: 'Fund Total',
            marketValue: combinedData.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0,
            referenceMarketValue: (referencePrice !== undefined)
              ? combinedData.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) / referencePrice.price
              : 0,
            quantityDecimals: 0,
            priceDecimals: 0
          }}
          columns={columnsToUse}
          isError={liabIsError}
          error={liabError}
        />
      </Table>
    </TableContainer>
  )
}

export default LiabilitiesByCounterpartyTable
