import React, { useState } from 'react'
import { Button, Box, Stack, Typography, useTheme } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { type MarginAlert, marginAlertsApi } from '@r40cap/alerts-sdk'
import { Modal, useRequestSnackbar } from '@r40cap/ui'

import AccountMarginAlertsModalContent from './AccountMarginAlertsModalContent'
import { isApiError } from '../../../utils/errors'

function AccountMarginAlertsDisplay (props: { accountId: string }): React.JSX.Element {
  const { accountId } = props
  const { palette } = useTheme()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { showSnackbar } = useRequestSnackbar()

  const {
    data: alertsData,
    refetch: alertsRefetch,
    isFetching: alertsIsFetching,
    isError: alertsIsError,
    error: alertsError
  } = marginAlertsApi.useGetMarginAlertsQuery({ accountId: accountId ?? '' })
  const [postEditMutation] = marginAlertsApi.useEditMarginAlertMutation()

  function refreshFunction (): void {
    void alertsRefetch()
      .catch((error) => { console.error(error) })
  }

  const handleEdit = (value: any, marginAlertId: string, property: keyof MarginAlert): void => {
    const alertToEdit = (alertsData?.data ?? []).find(
      (alert) => alert.alertId === marginAlertId
    )
    if (alertToEdit !== undefined) {
      const editedAlert: Record<string, any> = { ...alertToEdit }
      editedAlert[property] = value
      postEditMutation({ edit: editedAlert as MarginAlert })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Edits Pushed',
              status: 'success'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }

  return <>
    <Button
      sx={{
        width: '100%',
        height: '100%',
        borderWidth: '2px',
        borderColor: palette.tableBodyText.main,
        borderStyle: 'solid',
        borderRadius: '15px'
      }}
      onClick={() => { setModalOpen(true) }}
    >
      <Stack direction={'row'} sx={{ alignItems: 'center' }}>
        <NotificationsIcon
          sx={{
            fontSize: '1rem',
            color: palette.tableBodyText.main,
            alignContent: 'center'
          }}
        />
        <Typography sx={{
          color: palette.tableBodyText.main,
          fontSize: '0.8rem',
          fontWeight: 'bold',
          marginLeft: '5px'
        }}>
          {(alertsData?.data ?? []).length} Margin {(alertsData?.data ?? []).length === 1 ? 'Alert' : 'Alerts'}
        </Typography>
      </Stack>
    </Button>
    <Modal
      open={modalOpen}
      handleClose={() => {
        setModalOpen(false)
      }}
    >
      <Box
        sx={{
          width: '60vw',
          height: '80vh',
          padding: '20px'
        }}
      >
        <AccountMarginAlertsModalContent
          isFetching={alertsIsFetching}
          alerts={alertsData?.data ?? []}
          refreshFunction={refreshFunction}
          accountId={accountId ?? ''}
          handleEdit={handleEdit}
          isError={alertsIsError}
          error={alertsError}
        />
      </Box>
    </Modal>
  </>
}

export default AccountMarginAlertsDisplay
