import { type ColumnDefinition } from '@r40cap/ui'

import type {
  CollateralRow,
  DummyCollateralObject,
  PositionRow,
  DummyPositionObject
} from './types'
import { InputType } from '../types'

export const collateralColumns: Array<ColumnDefinition<CollateralRow, InputType, DummyCollateralObject>> = [
  {
    label: 'Asset',
    id: 'ticker',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Balance',
    id: 'balance',
    columnWidthPercentage: 18,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'balance',
    sortable: true,
    colored: false,
    decimalsId: 'quantityDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Equity',
    id: 'equity',
    columnWidthPercentage: 18,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    decimalsId: 'quantityDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Haircut',
    id: 'haircut',
    columnWidthPercentage: 12,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    decimals: 4,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Spot In Use',
    id: 'spotInUseDesc',
    columnWidthPercentage: 20,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'maxSpotInUse',
    decimalsId: 'quantityDecimals',
    sortable: true,
    colored: false,
    decimals: 4,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Price',
    id: 'price',
    columnWidthPercentage: 18,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'price',
    sortable: true,
    colored: false,
    decimalsId: 'priceDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'MV',
    id: 'marketValue',
    columnWidthPercentage: 17,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    decimals: 0,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]

export const positionColumns: Array<ColumnDefinition<PositionRow, InputType, DummyPositionObject>> = [
  {
    label: 'Position',
    id: 'ticker',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Quantity',
    id: 'quantity',
    columnWidthPercentage: 16,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'quantity',
    sortable: true,
    colored: false,
    decimalsId: 'quantityDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Price',
    id: 'price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: InputType.PRICE_INFO,
    editId: 'price',
    sortable: false,
    colored: false,
    decimalsId: 'priceDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'UPL',
    id: 'unrealizedPnl',
    columnWidthPercentage: 14,
    overflowType: 'scroll',
    sortable: false,
    colored: true,
    decimals: 2,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'FR',
    id: 'annualizedFundingRatePct',
    columnWidthPercentage: 11,
    overflowType: 'scroll',
    sortable: false,
    colored: true,
    decimals: 2,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: '%'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'MV',
    id: 'marketValue',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    decimals: 0,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]
