import { type ColumnDefinition } from '@r40cap/ui'
import type { PriceAlert } from '@r40cap/alerts-sdk'

import { type AlertRow, InputType } from './types'

export const priceAlertColumns: Array<ColumnDefinition<AlertRow, InputType, PriceAlert, string>> = [
  {
    label: 'Tradingview ID',
    id: 'tradingviewId',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: InputType.STRING,
    editId: 'tradingviewId',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Delivery',
    id: 'delivery',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: InputType.DELIVERY,
    editId: 'delivery',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Last Triggered',
    id: 'lastTriggered',
    columnWidthPercentage: 12,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]
