import React, { useEffect, useMemo, useState } from 'react'
import type { Dayjs } from 'dayjs'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import {
  type Order,
  RowSkeleton,
  getComparator,
  stableSort
} from '@r40cap/ui'
import type { BalanceByAccount } from '@r40cap/pms-sdk'

import AccountRow from './rows/AccountRow'
import type {
  BalanceWithPrice,
  ColumnDefinitionWithStaleness,
  FirstLevelDef,
  AccountRowObject
} from './types'
import TableErrorBody from '../../utils/TableErrorBody'

const STONEX_FCM_PLATFORM_ID = '1eb4d67a-0d47-4666-b7ec-825223292b64'
const MERGED_ACCOUNT_ID = 'merged_fcm_account'
const MERGED_ACCOUNT_NAME = 'StoneX FCM - Total'

function getUniqueAccounts (positions: BalanceByAccount[]): FirstLevelDef[] {
  const filteredObjects = positions.reduce((accumulator: Record<string, BalanceByAccount>, currentObject: BalanceByAccount) => {
    accumulator[currentObject.accountId] = currentObject
    return accumulator
  }, {})
  const uniqueObjects: BalanceByAccount[] = Object.values(filteredObjects)
  return uniqueObjects.map((psn) => {
    return {
      id: psn.accountId,
      name: `${psn.platformName} - ${psn.accountName}`
    }
  })
}

function BalancesByAccountTableBody (props: {
  positions: BalanceWithPrice[]
  isLoading: boolean
  order: Order
  orderBy: keyof AccountRowObject
  columns: Array<ColumnDefinitionWithStaleness<AccountRowObject, any>>
  includeSmallAmounts: boolean
  smallThreshold: number
  requestedTime?: Dayjs
  openedList: readonly string[]
  toggleAccount: (accountId: string) => void
  accountIdToRiskEngineSlug: Map<string, string>
  showPlatformOption: boolean
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
  mergeFcmAccounts: boolean
}): React.JSX.Element {
  const {
    positions,
    isLoading,
    order,
    orderBy,
    columns,
    includeSmallAmounts,
    smallThreshold,
    requestedTime,
    openedList,
    toggleAccount,
    accountIdToRiskEngineSlug,
    showPlatformOption,
    isError,
    error,
    mergeFcmAccounts
  } = props
  const [accounts, setAccounts] = useState<FirstLevelDef[]>(getUniqueAccounts(positions))

  const visibleAccounts = useMemo(
    () => {
      const adjustedPositions = mergeFcmAccounts
        ? positions.map((psn) => {
          if (psn.platformId === STONEX_FCM_PLATFORM_ID) {
            return {
              ...psn,
              accountId: MERGED_ACCOUNT_ID,
              accountName: MERGED_ACCOUNT_NAME
            }
          } else {
            return psn
          }
        })
        : positions
      const adjustedAccounts = mergeFcmAccounts
        ? [...accounts, {
            id: MERGED_ACCOUNT_ID,
            name: MERGED_ACCOUNT_NAME
          }]
        : accounts
      const filteredAccounts = includeSmallAmounts
        ? adjustedAccounts
        : adjustedAccounts.filter((acc) => {
          const accRows = adjustedPositions.filter((val) => val.accountId === acc.id)
          const absMv = accRows.reduce((sum, current) => sum + Math.abs(current.multiplier * (current.instrumentIsCoinMargined ? 1 : current.price ?? 0) * current.quantity), 0)
          return absMv >= smallThreshold
        })
      return stableSort(filteredAccounts, (a, b) => {
        const accountABalances = adjustedPositions.filter((row) => row.instrumentIsAsset && a.id === row.accountId)
        const accountACash = adjustedPositions.filter((row) => row.instrumentIsCash && a.id === row.accountId)
        const accountAPositions = adjustedPositions.filter((row) => !row.instrumentIsAsset && a.id === row.accountId)
        const psnA = adjustedPositions.find((val) => val.accountId === a.id)
        const psnRowA: AccountRowObject = {
          id: psnA?.accountId ?? 'no-account',
          accountDisplay: psnA !== undefined ? `${psnA.platformName} - ${psnA.accountName}` : '',
          cashValue: accountACash.reduce((partialSum, a) => partialSum + a.multiplier * (a.instrumentIsCoinMargined ? 1 : a.price ?? 0) * a.quantity, 0) ?? 0,
          assetsValue: accountABalances.reduce((partialSum, a) => partialSum + a.multiplier * (a.instrumentIsCoinMargined ? 1 : a.price ?? 0) * a.quantity, 0) ?? 0,
          grossPositionsValue: accountAPositions.reduce((partialSum, a) => partialSum + Math.abs(a.multiplier * (a.instrumentIsCoinMargined ? 1 : a.price ?? 0) * a.quantity), 0) ?? 0
        }
        const accountBBalances = adjustedPositions.filter((row) => row.instrumentIsAsset && b.id === row.accountId)
        const accountBCash = adjustedPositions.filter((row) => row.instrumentIsCash && b.id === row.accountId)
        const accountBPositions = adjustedPositions.filter((row) => !row.instrumentIsAsset && b.id === row.accountId)
        const psnB = adjustedPositions.find((val) => val.accountId === b.id)
        const psnRowB: AccountRowObject = {
          id: psnB?.accountId ?? 'no-account',
          accountDisplay: psnB !== undefined ? `${psnB.platformName} - ${psnB.accountName}` : '',
          cashValue: accountBCash.reduce((partialSum, a) => partialSum + a.multiplier * (a.instrumentIsCoinMargined ? 1 : a.price ?? 0) * a.quantity, 0) ?? 0,
          assetsValue: accountBBalances.reduce((partialSum, a) => partialSum + a.multiplier * (a.instrumentIsCoinMargined ? 1 : a.price ?? 0) * a.quantity, 0) ?? 0,
          grossPositionsValue: accountBPositions.reduce((partialSum, a) => partialSum + Math.abs(a.multiplier * (a.instrumentIsCoinMargined ? 1 : a.price ?? 0) * a.quantity), 0) ?? 0
        }
        return getComparator(order, orderBy)({
          ...psnRowA,
          requestedTime: undefined,
          oldestCashPriceTime: undefined,
          oldestAssetPriceTime: undefined,
          oldestPositionPriceTime: undefined
        },
        {
          ...psnRowB,
          requestedTime: undefined,
          oldestCashPriceTime: undefined,
          oldestAssetPriceTime: undefined,
          oldestPositionPriceTime: undefined
        })
      })
    },
    [order, orderBy, positions, accounts, includeSmallAmounts, smallThreshold, mergeFcmAccounts]
  )

  useEffect(() => {
    setAccounts(getUniqueAccounts(positions))
  }, [positions])

  if (isError) {
    return (
      <TableErrorBody
        colsToSpan={columns.length + 1}
        error={error}
      />
    )
  } else if (isLoading || positions === undefined) {
    const rows = []
    for (let i = 0; i < 5; i++) {
      rows.push(
        <RowSkeleton
          usedKey={i}
          columns={columns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
          key={i}
        />
      )
    }
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  } else {
    const rows = visibleAccounts.map((account) => {
      return (
        <AccountRow
          accountIds={
            account.id === MERGED_ACCOUNT_ID
              ? positions.filter((psn) => psn.platformId === STONEX_FCM_PLATFORM_ID).map((psn) => psn.accountId)
              : [account.id]
          }
          accountName={account.name}
          containedRows={
            account.id === MERGED_ACCOUNT_ID
              ? positions.filter((psn) => psn.platformId === STONEX_FCM_PLATFORM_ID)
              : positions.filter((psn) => psn.accountId === account.id)
          }
          key={account.id}
          includeSmallAmounts={includeSmallAmounts}
          smallThreshold={smallThreshold}
          requestedTime={requestedTime}
          isOpen={openedList.includes(account.id)}
          toggleOpen={() => { toggleAccount(account.id) }}
          riskEngineSlug={accountIdToRiskEngineSlug.get(account.id)}
          showPlatformOption={showPlatformOption}
        />
      )
    })
    if (rows.length > 0) {
      return (
        <TableBody>
          {rows}
          <TableRow sx={{ height: '100%' }} />
        </TableBody>
      )
    } else {
      return (
        <TableBody>
          <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length + 1}
              align='center'
            >
              <Typography sx={{ fontSize: 20 }}>No Data</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
  }
}

export default BalancesByAccountTableBody
