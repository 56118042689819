import type { WorkingSpreadDefinition } from '@r40cap/warwick-sdk'

export function getSpreadId (spread: WorkingSpreadDefinition): string {
  return `${spread.executeQuantity}${spread.quoteSymbol}${spread.leanSymbol}`
}

export function getOurSymbol (warwickSymbol: string): string {
  if (warwickSymbol.endsWith('_CME')) {
    const minusCme = warwickSymbol.slice(0, -4)
    return `${minusCme.slice(0, -2)}${minusCme.slice(-1)}`
  } else if (warwickSymbol.endsWith('USDTPERP_OKX')) {
    return `${warwickSymbol.slice(0, -12)}-USDT-SWAP`
  } else {
    return warwickSymbol
  }
}

export function getMultiplierForWarwickSymbol (warwickSymbol: string): number {
  if (warwickSymbol.endsWith('_CME')) {
    return warwickSymbol.startsWith('ETH')
      ? 50
      : warwickSymbol.startsWith('BTC')
        ? 5
        : 500
  } else if (warwickSymbol.endsWith('USDTPERP_OKX')) {
    return warwickSymbol.startsWith('ETH')
      ? 0.1
      : warwickSymbol.startsWith('BTC')
        ? 0.01
        : 1
  } else {
    return 1
  }
}

export function getInstrumentTypeForWarwickSymbol (warwickSymbol: string): 'SPOT' | 'SWAP' | 'FUTURES' {
  if (warwickSymbol.endsWith('USDT')) {
    return 'SPOT'
  } else if (warwickSymbol.endsWith('USDTPERP_OKX')) {
    return 'SWAP'
  } else {
    return 'FUTURES'
  }
}

export function getBaseQuoteForWarwickSymbol (warwickSymbol: string): { base: string, quote: string } {
  if (warwickSymbol.endsWith('USDT') || warwickSymbol.endsWith('USDC')) {
    return {
      base: warwickSymbol.slice(0, -4),
      quote: warwickSymbol.slice(-4)
    }
  } else if (warwickSymbol.endsWith('USD')) {
    return {
      base: warwickSymbol.slice(0, -3),
      quote: 'USD'
    }
  } else {
    throw new Error('Got Base/Quote request for SPOT')
  }
}
