import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, Button, Stack, useTheme } from '@mui/material'
import { NumberInput, useRequestSnackbar } from '@r40cap/ui'
import { type AlertDelivery, type AlertThreshold, AlertDeliveryInput } from '@r40cap/alerts-sdk'

function ThresholdAddition (props: {
  addThreshold: (threshold: AlertThreshold) => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const { addThreshold } = props
  const { showSnackbar } = useRequestSnackbar()
  const [thresholdStr, setThresholdStr] = useState('')
  const [delivery, setDelivery] = useState<AlertDelivery | null>(null)

  function handleSubmit (): void {
    if (delivery === null) {
      showSnackbar({
        isOpen: true,
        message: 'Delivery required',
        status: 'failure'
      })
    } else if (thresholdStr === '') {
      showSnackbar({
        isOpen: true,
        message: 'Threshold required',
        status: 'failure'
      })
    } else {
      const id = uuidv4()
      const threshold: AlertThreshold = {
        thresholdId: `new-${id}`,
        delivery,
        threshold: parseFloat(thresholdStr)
      }
      addThreshold(threshold)
    }
  }

  return (
    <Box sx={{ width: '25vw', padding: '20px' }}>
      <Stack direction={'column'} spacing={2}>
        <Stack direction={'row'} spacing={1}>
          <NumberInput
            title={'Threshold'}
            intOnly={false}
            valueString={thresholdStr}
            setValueString={setThresholdStr}
            color={palette.accent.main}
          />
          <AlertDeliveryInput
            setDelivery={setDelivery}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
            allowNone={false}
            setNone={(isNone) => {}}
          />
        </Stack>
        <Button
          variant='contained'
          onClick={handleSubmit}
          style={{
            width: '100%',
            backgroundColor: palette.accent.main,
            color: palette.secondary.main
          }}
        >
          Add
        </Button>
      </Stack>
    </Box>
  )
}

export default ThresholdAddition
