import React, { useEffect, useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  useTheme
} from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from '@mui/icons-material/Check'
import { BooleanInput, Modal, StringInput } from '@r40cap/ui'
import {
  getStrategyReport2 as sdkGetStrategy,
  getSnapshotReport2 as sdkGetSnapshot,
  getCounterpartyPnlReport2 as sdkGetCounterpartyPnl,
  getStrategyAccountReport2 as sdkGetStrategyAccount
} from '@r40cap/pms-sdk'
import { useAuth } from '@r40cap/auth'

import { type DeskOption } from '../../components/common/types'
import { deskOptions } from '../../components/common/constants'
import { getLastMarkDay } from '../../utils/times'
import DeskSelection from '../../components/common/DeskSelection'

dayjs.extend(utc)

type ReportType = 'cp' | 'ss' | 'st' | 'sa'

interface ResponseStatus {
  state: 'pending' | 'success' | 'failure'
  message: string
}

function getReportTypeDesc (reportType: ReportType): string {
  switch (reportType) {
    case 'cp':
      return 'Counterparty'
    case 'ss':
      return 'Snapshot'
    case 'st':
      return 'Strategy'
    case 'sa':
      return 'Strat-Acc'
  }
}

function ProcessingItemComp (props: {
  reportType: ReportType
  desk: DeskOption
  status: ResponseStatus
}): React.JSX.Element {
  const { reportType, desk, status } = props
  const { palette } = useTheme()

  const repDesc = getReportTypeDesc(reportType)

  const totalText = reportType === 'ss'
    ? `${repDesc} Report`
    : `${desk.label}  ${repDesc} Report`

  return (
    <Stack direction={'column'} spacing={1} alignItems={'center'}>
      <Typography sx={{ color: palette.tableBodyText.main }}>{totalText}</Typography>
      {
        status.state === 'pending'
          ? <CircularProgress sx={{ color: palette.accent.main }}/>
          : status.state === 'success'
            ? <CheckIcon sx={{ color: palette.accent.main }} />
            : <ErrorIcon sx={{ color: palette.accent.main }} />
      }
      {
        status.state === 'failure' && <Typography sx={{ color: palette.error.main }}>{status.message}</Typography>
      }
    </Stack>
  )
}

function ProcessingModal (props: {
  reportType: ReportType
  desk: DeskOption
  goBack: () => void
  status: ResponseStatus
}): React.JSX.Element {
  const { reportType, status, desk } = props

  return (
    <Box
      sx={{ width: '20vw', alignContent: 'center' }}
      padding={5}
    >
      <Stack direction={'column'} sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
        <ProcessingItemComp
          reportType={reportType}
          desk={desk}
          status={status}
        />
      </Stack>
    </Box>
  )
}

function ReportTypeInput (props: {
  reportType: ReportType
  setReportType: (reportType: ReportType) => void
}): React.JSX.Element {
  const { reportType, setReportType } = props
  const { palette } = useTheme()

  const radioStyle = {
    color: palette.accent.main,
    opacity: 0.5,
    '&.Mui-checked': {
      opacity: 0.9,
      color: palette.accent.main
    }
  }

  const radioLabelStyle = {
    color: palette.tableBodyText.main,
    opacity: 0.75
  }

  return (
    <FormControl>
      <FormLabel
        id='demo-radio-buttons-group-label'
        sx={{ color: palette.tableBodyText.main }}
      >Report Type</FormLabel>
      <RadioGroup
        aria-labelledby='demo-radio-buttons-group-label'
        value={reportType}
        onChange={(event) => { setReportType(event.target.value as ReportType) }}
        name='radio-buttons-group'
        row
      >
        <FormControlLabel labelPlacement='bottom' sx={radioLabelStyle} value='st' control={<Radio sx={radioStyle} />} label='Strategy' />
        <FormControlLabel labelPlacement='bottom' sx={radioLabelStyle} value='ss' control={<Radio sx={radioStyle} />} label='Snapshot' />
        <FormControlLabel labelPlacement='bottom' sx={radioLabelStyle} value='cp' control={<Radio sx={radioStyle} />} label='Counterparty' />
        <FormControlLabel labelPlacement='bottom' sx={radioLabelStyle} value='sa' control={<Radio sx={radioStyle} />} label='Strat-Acc' />
      </RadioGroup>
    </FormControl>
  )
}

function EntryModal (props: {
  date: Dayjs
  setDate: (time: Dayjs) => void
  timeOverride: Dayjs | null
  setTimeOverride: (time: Dayjs | null) => void
  getLive: boolean
  setGetLive: (getLive: boolean) => void
  reportType: ReportType
  setReportType: (reportType: ReportType) => void
  desk: DeskOption
  setDesk: (desk: DeskOption) => void
  fileName: string
  setFileName: (fileName: string) => void
  aggregateDesks: boolean
  setAggregateDesks: (aggregateDesks: boolean) => void
  startDownload: () => void
}): React.JSX.Element {
  const {
    timeOverride,
    setTimeOverride,
    date,
    setDate,
    getLive,
    setGetLive,
    reportType,
    setReportType,
    desk,
    setDesk,
    fileName,
    setFileName,
    aggregateDesks,
    setAggregateDesks,
    startDownload
  } = props
  const { palette } = useTheme()
  const authConext = useAuth()
  const restDesk = authConext.restrictedDeskId ?? undefined

  const handleChangeDate = (newDate: Dayjs | null): void => {
    if (newDate !== null) {
      setDate(newDate)
    }
  }

  useEffect(() => {
    const timeStr = getLive
      ? timeOverride === null
        ? dayjs().utc().format('YYYY-MM-DD_HH:mm')
        : timeOverride.format('YYYY-MM-DD_HH:mm')
      : `${date.format('YYYY-MM-DD')}_mark`
    if (reportType === 'ss') {
      if (aggregateDesks) {
        setFileName(`${timeStr}_${reportType}_agg`)
      } else {
        setFileName(`${timeStr}_${reportType}`)
      }
    } else {
      setTimeOverride(null)
      setFileName(`${desk.optionId}_${timeStr}_${reportType}`)
    }
  }, [date, getLive, reportType, desk, aggregateDesks, timeOverride])

  const styles = {
    datePickerContainer: {
      height: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        height: '100%',
        width: '100%'
      },
      '& .MuiInputBase-root': {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }
    }
  }

  return (
    <Box
      sx={{ width: '30vw', alignContent: 'center' }}
      padding={5}
    >
      <Stack direction={'column'} sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
        <ToggleButtonGroup
          value={getLive ? 'live' : 'mark'}
          exclusive
          onChange={(_, newVal: string | null) => {
            if (newVal !== null) {
              setGetLive(newVal === 'live')
            }
          }}
          style={{
            width: '100%'
          }}
        >
          <ToggleButton
            value='live'
            style={{ width: '25%' }}
          >
            <Typography>Live</Typography>
          </ToggleButton>
          <ToggleButton
            value='mark'
            style={{ width: '25%' }}
          >
            <Typography>Mark</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
        {
          getLive || <Box sx={styles.datePickerContainer}>
            <DatePicker
              value={date}
              onChange={(newValue) => { handleChangeDate(newValue) }}
            />
          </Box>
        }
        {
          getLive && reportType === 'ss' && <Box sx={styles.datePickerContainer}>
            <DateTimePicker
              value={timeOverride}
              onChange={setTimeOverride}
              format='YYYY-MM-DD HH:mm'
              closeOnSelect={false}
              ampm={false}
              timeSteps={{ hours: 1, minutes: 1 }}
              label='System Override Time'
              slotProps={{
                actionBar: {
                  actions: ['clear']
                }
              }}
              onOpen={() => { setTimeOverride(dayjs().utc()) }}
            />
          </Box>
        }
        <ReportTypeInput
          reportType={reportType}
          setReportType={setReportType}
        />
        {
          restDesk === undefined && reportType !== 'ss' && <DeskSelection
            currentDesk={desk}
            deskChange={setDesk}
          />
        }
        {
          reportType === 'ss' && <BooleanInput
            label='Aggregate Desks?'
            value={aggregateDesks}
            setValue={setAggregateDesks}
            boxColor={palette.accent.main}
            textColor={palette.tableBodyText.main}
          />
        }
        <StringInput
          title='File Name'
          value={fileName}
          setValue={setFileName}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={startDownload}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.primary.main,
            width: '50%'
          }}
        >
          Download
        </Button>
      </Stack>
    </Box>
  )
}

export function ReportButton (): React.JSX.Element {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [date, setDate] = useState<Dayjs>(getLastMarkDay())
  const [timeOverride, setTimeOverride] = useState<Dayjs | null>(null)
  const [getLive, setGetLive] = useState<boolean>(true)
  const [status, setStatus] = useState<ResponseStatus>({
    state: 'pending',
    message: ''
  })

  const [desk, setDesk] = useState<DeskOption>(deskOptions[0])
  const [aggregateDesks, setAggregateDesks] = useState<boolean>(false)
  const [reportType, setReportType] = useState<ReportType>('ss')
  const [fileName, setFileName] = useState<string>(
    reportType === 'ss'
      ? `${dayjs().utc().format('YYYY-MM-DD_HH:MM')}_${reportType}`
      : `${desk.optionId}_${dayjs().utc().format('YYYY-MM-DD_HH:MM')}_${reportType}`
  )

  function closeModal (): void {
    setModalOpen(false)
    setIsProcessing(false)
    setStatus({
      state: 'pending',
      message: ''
    })
  }

  function startDownload (): void {
    setIsProcessing(true)
    if (reportType === 'ss') {
      sdkGetSnapshot(fileName, timeOverride ?? undefined, getLive ? undefined : date, aggregateDesks)
        .then((value) => {
          if (value.success as boolean) {
            setStatus({
              state: 'success',
              message: ''
            })
          } else {
            setStatus({
              state: 'failure',
              message: value.message
            })
          }
        })
        .catch(() => {
          setStatus({
            state: 'failure',
            message: 'Unexpected Error'
          })
        })
    } else {
      const reportFunction = reportType === 'cp'
        ? sdkGetCounterpartyPnl
        : reportType === 'st'
          ? sdkGetStrategy
          : sdkGetStrategyAccount
      reportFunction(fileName, getLive ? undefined : date, desk.deskIds)
        .then((value) => {
          if (value.success as boolean) {
            setStatus({
              state: 'success',
              message: ''
            })
          } else {
            setStatus({
              state: 'failure',
              message: value.message
            })
          }
        })
        .catch(() => {
          setStatus({
            state: 'failure',
            message: 'Unexpected Error'
          })
        })
    }
  }

  return (
    <>
      <IconButton onClick={() => { setModalOpen(true) }}>
        <FileDownloadIcon/>
      </IconButton>
      <Modal
        open={modalOpen}
        handleClose={closeModal}
      >
        {
          isProcessing
            ? <ProcessingModal
                reportType={reportType}
                desk={desk}
                goBack={() => { setIsProcessing(false) }}
                status={status}
              />
            : <EntryModal
                date={date}
                setDate={setDate}
                timeOverride={timeOverride}
                setTimeOverride={setTimeOverride}
                getLive={getLive}
                setGetLive={setGetLive}
                reportType={reportType}
                setReportType={setReportType}
                desk={desk}
                setDesk={setDesk}
                fileName={fileName}
                setFileName={setFileName}
                aggregateDesks={aggregateDesks}
                setAggregateDesks={setAggregateDesks}
                startDownload={startDownload}
              />
        }
      </Modal>
    </>
  )
}
