import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { type LiteDesk } from '@r40cap/pms-sdk'
import {
  type PnlAlert,
  pnlAlertsApi,
  usePermissions
} from '@r40cap/alerts-sdk'

import type { AlertRow, InputType } from './types'
import PnlAlertsTable from './PnlAlertsTable'
import { isApiError } from '../../../utils/errors'
import { getModalContent } from './utils'

function PnlAlerts (props: {
  desks: LiteDesk[]
  refreshSignal: boolean
  pushSignal: boolean
  setHasEdited: (hasEdited: boolean) => void
}): React.JSX.Element {
  const { desks, refreshSignal } = props
  const [rows, setRows] = useState<AlertRow[]>([])
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)
  const { userId } = usePermissions()
  const { showSnackbar } = useRequestSnackbar()
  const navigate = useNavigate()

  const {
    data: alertsData,
    isFetching: alertsIsFetching,
    refetch: alertsRefetch,
    isError: alertsIsError,
    error: alertsError
  } = pnlAlertsApi.useGetPnlAlertsQuery({})
  const [postEditMutation] = pnlAlertsApi.useEditPnlAlertMutation()

  useEffect(() => {
    alertsRefetch()
      .catch((error) => { console.error(error) })
  }, [refreshSignal])

  const handleEdit = (value: any, pnlAlertId: string, property: keyof PnlAlert): void => {
    const alertToEdit = (alertsData?.data ?? []).find(
      (alert) => alert.alertId === pnlAlertId
    )
    if (alertToEdit !== undefined) {
      const editedAlert: Record<string, any> = { ...alertToEdit }
      editedAlert[property] = value
      postEditMutation({ edit: editedAlert as PnlAlert })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Edits Pushed',
              status: 'success'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }

  useEffect(() => {
    const deskIdToDisplay = desks.reduce((acc: Record<string, string>, desk: LiteDesk) => {
      acc[desk.id] = desk.name
      return acc
    }, {})
    const newRows = (alertsData?.data ?? []).map((alert) => {
      return {
        id: alert.alertId,
        desk: deskIdToDisplay[alert.deskId] ?? alert.deskId,
        cooldownMinutes: alert.cooldownMinutes,
        lookbackMinutes: alert.lookbackMinutes,
        thresholds: alert.thresholds
      }
    })
    setRows(newRows)
  }, [alertsData?.data, desks])

  if (userId === undefined || userId === null) {
    return <Box sx={{
      height: '100%',
      width: '100%',
      alignContent: 'center',
      justifyItems: 'center'
    }}>
      <Typography align='center' variant='h4'>Insufficient Permissions</Typography>
    </Box>
  }

  return (
    <Box sx={{ height: '100%' }}>
      <PnlAlertsTable
        rows={rows}
        isFetching={alertsIsFetching}
        handleOpenEdit={
          (
            itemId: string,
            inputType: InputType,
            label: string,
            editProperty: keyof PnlAlert
          ) => {
            setEditModalOpen(true)
            setEditModalContent(
              getModalContent(
                inputType,
                label,
                editProperty,
                handleEdit,
                itemId,
                () => { setEditModalOpen(false) }
              )
            )
          }
        }
        proposeDelete={(pnlAlertId: string) => { navigate(`/alerts/pnl/delete/${pnlAlertId}`) }}
        proposeDeleteThreshold={(thresholdId: string) => { navigate(`/alerts/pnl/threshold/delete/${thresholdId}`) }}
        openAddThreshold={(alertId: string) => { navigate(`/alerts/pnl/addThreshold/${alertId}`) }}
        isError={alertsIsError}
        error={alertsError}
      />
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
      <Outlet />
    </Box>
  )
}

export default PnlAlerts
