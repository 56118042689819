import type {
  OkxPortfolioMarginConfig,
  OkxCurrencyEquityTier,
  OkxPortfolioMarginBalance,
  OkxPortfolioMarginPosition,
  OkxPortfolioMarginRiskUnit,
  OkxContractDetails,
  OkxFeeRates,
  OkxCurrencyBorrowTier
} from '@r40cap/pms-sdk'

import {
  getSettlementCurrencyForTicker,
  getQuoteCurrencyForTicker,
  isInverse
} from './tickerUtils'

export function getOkxCurrencyEquities (balances: OkxPortfolioMarginBalance[], positions: OkxPortfolioMarginPosition[]): Record<string, number> {
  const ccyToEquity: Record<string, number> = balances.reduce((acc: Record<string, number>, balance) => {
    acc[balance.ccy] = balance.balance
    return acc
  }, {})
  positions.forEach(psn => {
    const settlementCurrency = getSettlementCurrencyForTicker(psn.instId)
    if (settlementCurrency in ccyToEquity) {
      ccyToEquity[settlementCurrency] += psn.upl
    } else {
      ccyToEquity[settlementCurrency] = psn.upl
    }
  })
  return ccyToEquity
}

export function getOkxDiscountedEquity (config: OkxPortfolioMarginConfig): number {
  const ccyToEquity = getOkxCurrencyEquities(config.balances, config.riskUnits.flatMap(ru => ru.positions))
  const discountedEquity = Object.entries(ccyToEquity).map(([ccy, equity]) => {
    const effectiveHaircut = overallHaircutForCurrency(equity, config.ccyToEquityTiers[ccy])
    return equity * effectiveHaircut * config.priceMap[`${ccy}-USD`]
  }).reduce((acc, equity) => acc + equity, 0)
  return discountedEquity
}

export function getOkxBorrowImr (config: OkxPortfolioMarginConfig): number {
  const ccyToEquity = getOkxCurrencyEquities(config.balances, config.riskUnits.flatMap(ru => ru.positions))
  const borrowImr = Object.entries(ccyToEquity).map(([ccy, equity]) => {
    if (equity >= 0) {
      return 0
    } else {
      const negativeEquityValue = (equity * -1) * config.priceMap[`${ccy}-USD`]
      const autoborrowLeverage = config.ccyToAutoborrowLeverage[ccy]
      return negativeEquityValue / autoborrowLeverage
    }
  }).reduce((acc, ccyImr) => acc + ccyImr, 0)
  return borrowImr
}

export function getOkxBorrowMmr (config: OkxPortfolioMarginConfig): number {
  const ccyToEquity = getOkxCurrencyEquities(config.balances, config.riskUnits.flatMap(ru => ru.positions))
  const borrowMmr = Object.entries(ccyToEquity).map(([ccy, equity]) => {
    if (equity >= 0) {
      return 0
    } else {
      const effectiveMmr = overallMmrForCurrency(Math.abs(equity), config.ccyToBorrowTiers[ccy])
      return equity * effectiveMmr * config.priceMap[`${ccy}-USD`]
    }
  }).reduce((acc, equity) => acc + equity, 0)
  return borrowMmr
}

type VolShock = 'down' | 'same' | 'up'

const PRICE_STEPS = [-3, -2, -1, 0, 1, 2, 3]
const VOL_SHOCKS: VolShock[] = ['down', 'same', 'up']

function getPositionPerformanceForShockScenario (
  position: OkxPortfolioMarginPosition,
  contractDetails: OkxContractDetails,
  priceMap: Record<string, number>,
  priceMove: number,
  volShock: VolShock
): number {
  if (position.instType === 'SWAP') {
    const quoteCcy = getQuoteCurrencyForTicker(position.instId)
    const notional = isInverse(position.instId)
      ? position.amt * contractDetails.size
      : position.amt * contractDetails.size * priceMap[`${quoteCcy}-USD`] * position.markPrice
    return notional * priceMove
  } else {
    // TODO: Implement futures and options
    return 0
  }
}

function getSpotContributionForShockScenario (
  priceMove: number,
  price: number,
  spotInUseAmt: number
): number {
  if (spotInUseAmt === 0) {
    return 0
  } else {
    return spotInUseAmt * priceMove * price
  }
}

function getPerformanceForShockScenario (
  ccy: string,
  positions: OkxPortfolioMarginPosition[],
  contractToDetails: Record<string, OkxContractDetails>,
  priceMap: Record<string, number>,
  priceMove: number,
  volShock: VolShock,
  spotInUseAmt: number
): number {
  const positionsPerformance = positions.map((psn) => {
    const contractDetails = contractToDetails[psn.instId]
    return getPositionPerformanceForShockScenario(
      psn,
      contractDetails,
      priceMap,
      priceMove,
      volShock
    )
  }).reduce((acc, val) => acc + val, 0)
  const spotPrice = priceMap[`${ccy}-USD`]
  const spotContribution = getSpotContributionForShockScenario(priceMove, spotPrice, spotInUseAmt)
  return positionsPerformance + spotContribution
}

function getSpotShockForRiskUnit (
  riskUnit: OkxPortfolioMarginRiskUnit,
  contractToDetails: Record<string, OkxContractDetails>,
  priceMap: Record<string, number>,
  spotInUseAmt: number
): number {
  const stepSize = riskUnit.maxPxChange / 3
  const mainMatrixBiggestLoss = PRICE_STEPS.map((bucket) => {
    const priceMove = bucket * stepSize
    return VOL_SHOCKS.map((volShock) => {
      return getPerformanceForShockScenario(
        riskUnit.ccy,
        riskUnit.positions,
        contractToDetails,
        priceMap,
        priceMove,
        volShock,
        spotInUseAmt
      )
    }).reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE)
  }).reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE)
  return mainMatrixBiggestLoss * -1
}

function getExtremeMoveForRiskUnit (
  riskUnit: OkxPortfolioMarginRiskUnit,
  contractToDetails: Record<string, OkxContractDetails>,
  priceMap: Record<string, number>,
  spotInUseAmt: number
): number {
  const extremeUpPerformance = getPerformanceForShockScenario(
    riskUnit.ccy,
    riskUnit.positions,
    contractToDetails,
    priceMap,
    riskUnit.maxPxChange * 2,
    'up',
    spotInUseAmt
  )
  const extremeDownPerformance = getPerformanceForShockScenario(
    riskUnit.ccy,
    riskUnit.positions,
    contractToDetails,
    priceMap,
    riskUnit.maxPxChange * -2,
    'up',
    spotInUseAmt
  )
  return Math.abs(Math.min(extremeUpPerformance, extremeDownPerformance)) * 0.5
}

function getBasisParamsForRiskUnit (ccy: string): [number, number] {
  if (ccy === 'ETH' || ccy === 'BTC') {
    return [0.002, 0.05]
  } else if (
    ccy === 'SOL' ||
    ccy === 'DOGE' ||
    ccy === 'PEPE' ||
    ccy === 'XRP' ||
    ccy === 'BNB' ||
    ccy === 'SHIB' ||
    ccy === 'LTC' ||
    ccy === 'ORDI' ||
    ccy === 'WLD' ||
    ccy === 'BCH' ||
    ccy === 'ADA'
  ) {
    return [0.008, 0.15]
  } else {
    return [0.02, 0.3]
  }
}

function getExpTsMsForStr (expDesc: string): number {
  const expYear = parseInt(expDesc.slice(0, 2)) + 2000
  const expMonth = parseInt(expDesc.slice(2, 4))
  const expDay = parseInt(expDesc.slice(4))
  const timestampMs = Date.UTC(expYear, expMonth - 1, expDay, 8, 0, 0, 0)
  return timestampMs
}

function getDaysToExpiryForPosition (position: OkxPortfolioMarginPosition, ts: number): number {
  if (position.instType === 'SWAP') {
    return 0.33
  }
  const expDesc = position.instType === 'FUTURES'
    ? position.instId.split('-')[-1]
    : position.instId.split('-')[-2]
  const expTs = getExpTsMsForStr(expDesc)
  return (expTs - ts) / 86400000
}

function getRollShockForDte (
  positions: OkxPortfolioMarginPosition[],
  contractToDetails: Record<string, OkxContractDetails>,
  priceMap: Record<string, number>,
  dte: number,
  a: number,
  annualizedMoveRisk: number
): number {
  const expPortion = Math.exp(annualizedMoveRisk * dte / 365) - 1
  const part1 = Math.max(expPortion, a)
  const cashDeltaT = positions.reduce((acc, psn) => {
    if (psn.instType === 'SWAP' || psn.instType === 'FUTURES') {
      const ctDetails = contractToDetails[psn.instId]
      const settlementCurrency = getSettlementCurrencyForTicker(psn.instId)
      const portfolioDelta = settlementCurrency === 'USDT' || settlementCurrency === 'USDC'
        ? ctDetails.size * ctDetails.mult * psn.markPrice * priceMap[`${settlementCurrency}-USD`] * psn.amt
        : (1 / (psn.markPrice * 1.0001)) * ctDetails.size * ctDetails.mult * priceMap[`${settlementCurrency}-USD`]
      return acc + portfolioDelta
    } else {
      // TODO: Handle Options
      return acc
    }
  }, 0)
  return part1 * Math.abs(cashDeltaT)
}

function getBasisRiskForRiskUnit (
  riskUnit: OkxPortfolioMarginRiskUnit,
  contractToDetails: Record<string, OkxContractDetails>,
  priceMap: Record<string, number>,
  timestamp: number,
  spotInUseAmt: number
): number {
  const [a, annualisedMoveRisk] = getBasisParamsForRiskUnit(riskUnit.ccy)
  const dteVals = new Set<number>()
  riskUnit.positions.forEach(psn => {
    const dte = getDaysToExpiryForPosition(psn, timestamp)
    dteVals.add(dte)
  })
  const dteList = Array.from(dteVals)
  const overallRollShock = dteList.reduce((acc, dte) => {
    const relevantPositions = riskUnit.positions.filter(psn => getDaysToExpiryForPosition(psn, timestamp) === dte)
    const rollShock = getRollShockForDte(
      relevantPositions,
      contractToDetails,
      priceMap,
      dte,
      a,
      annualisedMoveRisk
    )
    return acc + rollShock
  }, 0)
  if (spotInUseAmt === 0) {
    return overallRollShock
  } else {
    const part1 = Math.max(0, a)
    const spotPortfolioDelta = spotInUseAmt * priceMap[`${riskUnit.ccy}-USD`]
    return (part1 * spotPortfolioDelta) + overallRollShock
  }
}

function getScalingFactor (
  rawMinimumCharge: number,
  base: string
): number {
  if (base === 'ETH' || base === 'BTC') {
    return rawMinimumCharge <= 250_000
      ? 1
      : rawMinimumCharge <= 500_000
        ? 2
        : rawMinimumCharge <= 1_000_000
          ? 4
          : rawMinimumCharge <= 2_000_000
            ? 6
            : rawMinimumCharge <= 3_000_000
              ? 8
              : rawMinimumCharge <= 4_000_000
                ? 10
                : 12
  } else {
    return rawMinimumCharge <= 3000
      ? 1
      : rawMinimumCharge <= 8_000
        ? 2
        : rawMinimumCharge <= 14_000
          ? 3
          : rawMinimumCharge <= 19_000
            ? 4
            : rawMinimumCharge <= 27_000
              ? 5
              : rawMinimumCharge <= 36_000
                ? 6
                : rawMinimumCharge <= 45_000
                  ? 7
                  : rawMinimumCharge <= 54_000
                    ? 8
                    : rawMinimumCharge <= 63_000
                      ? 9
                      : rawMinimumCharge <= 72_000
                        ? 10
                        : rawMinimumCharge <= 81_000
                          ? 11
                          : rawMinimumCharge <= 90_000
                            ? 12
                            : 13
  }
}

function getAdjustedMinimumChargeForRiskUnit (
  riskUnit: OkxPortfolioMarginRiskUnit,
  contractToDetails: Record<string, OkxContractDetails>,
  typeToFees: Record<'FUTURES' | 'SWAP' | 'OPTION', OkxFeeRates>
): number {
  const futMin = riskUnit.positions.filter(psn => psn.instType === 'FUTURES' || psn.instType === 'SWAP').reduce((acc, psn) => {
    const ctDetails = contractToDetails[psn.instId]
    const feeObj = typeToFees[psn.instType]
    const settlementCurrency = getSettlementCurrencyForTicker(psn.instId)
    const relevantTakerFee = settlementCurrency === 'USDC'
      ? feeObj.takerUsdc
      : settlementCurrency === 'USDT'
        ? feeObj.takerU
        : feeObj.taker
    const feePerCt = Math.abs(relevantTakerFee) * ctDetails.size * psn.markPrice
    const slpPerCt = ctDetails.tier1Mmr * ctDetails.size * psn.markPrice
    const rawMinChargePerCt = slpPerCt + feePerCt
    const rawMinCharge = rawMinChargePerCt * Math.abs(psn.amt)
    const scalingFactor = getScalingFactor(rawMinCharge, riskUnit.ccy)
    return acc + rawMinCharge * scalingFactor
  }, 0)
  const optLongMin = riskUnit.positions.filter(psn => psn.instType === 'OPTION' && psn.amt >= 0).reduce((acc, psn) => {
    // TODO: Handle Options
    return acc
  }, 0)
  const optShortMin = riskUnit.positions.filter(psn => psn.instType === 'OPTION' && psn.amt < 0).reduce((acc, psn) => {
    // TODO: Handle Options
    return acc
  }, 0)
  return futMin + optLongMin + optShortMin
}

function sign (x: number): -1 | 1 {
  return x >= 0 ? 1 : -1
}

function getHedgingVolume (baseCashDelta: number, quoteCashDelta: number): number {
  return baseCashDelta === 0 || quoteCashDelta === 0 || sign(baseCashDelta) === sign(quoteCashDelta)
    ? 0
    : Math.min(Math.abs(baseCashDelta), Math.abs(quoteCashDelta))
}

function handleLinearInterp (
  x: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number {
  return y1 + (y2 - y1) / (x2 - x1) * (x - x1)
}

function getPairContribution (
  hedgingVolume: number,
  pairPx: number
): number {
  let total = 0
  if (hedgingVolume > 0) {
    const tierAmt = Math.min(hedgingVolume, 1_000_000)
    const tierFactor = pairPx >= 0.995
      ? 0.005
      : pairPx > 0.99
        ? handleLinearInterp(pairPx, 0.995, 0.005, 0.99, 0.005)
        : pairPx === 0.99
          ? 0.005
          : pairPx > 0.98
            ? handleLinearInterp(pairPx, 0.99, 0.005, 0.98, 0.01)
            : pairPx === 0.98
              ? 0.01
              : pairPx > 0.97
                ? handleLinearInterp(pairPx, 0.98, 0.01, 0.97, 0.02)
                : pairPx === 0.97
                  ? 0.02
                  : pairPx > 0.96
                    ? handleLinearInterp(pairPx, 0.97, 0.02, 0.96, 0.03)
                    : pairPx === 0.96
                      ? 0.03
                      : pairPx > 0.95
                        ? handleLinearInterp(pairPx, 0.96, 0.03, 0.95, 0.05)
                        : pairPx === 0.95
                          ? 0.05
                          : pairPx > 0.94
                            ? handleLinearInterp(pairPx, 0.95, 0.05, 0.94, 0.1)
                            : pairPx === 0.94
                              ? 0.1
                              : pairPx > 0.93
                                ? handleLinearInterp(pairPx, 0.94, 0.1, 0.93, 0.15)
                                : pairPx === 0.93
                                  ? 0.15
                                  : pairPx > 0.92
                                    ? handleLinearInterp(pairPx, 0.93, 0.15, 0.92, 0.2)
                                    : pairPx === 0.92
                                      ? 0.2
                                      : pairPx > 0.91
                                        ? handleLinearInterp(pairPx, 0.92, 0.2, 0.91, 0.25)
                                        : pairPx === 0.91
                                          ? 0.25
                                          : pairPx > 0.9
                                            ? handleLinearInterp(pairPx, 0.91, 0.25, 0.9, 0.3)
                                            : pairPx === 0.9
                                              ? 0.3
                                              : pairPx > 0.8
                                                ? handleLinearInterp(pairPx, 0.9, 0.3, 0.8, 0.4)
                                                : 0.4
    total += tierAmt * tierFactor
  }
  if (hedgingVolume > 1_000_000) {
    const tierAmt = Math.min(hedgingVolume, 5_000_000) - 1_000_000
    const tierFactor = pairPx >= 0.995
      ? 0.01
      : pairPx > 0.99
        ? handleLinearInterp(pairPx, 0.995, 0.01, 0.99, 0.015)
        : pairPx === 0.99
          ? 0.015
          : pairPx > 0.98
            ? handleLinearInterp(pairPx, 0.99, 0.015, 0.98, 0.02)
            : pairPx === 0.98
              ? 0.02
              : pairPx > 0.97
                ? handleLinearInterp(pairPx, 0.98, 0.02, 0.97, 0.03)
                : pairPx === 0.97
                  ? 0.03
                  : pairPx > 0.96
                    ? handleLinearInterp(pairPx, 0.97, 0.03, 0.96, 0.04)
                    : pairPx === 0.96
                      ? 0.04
                      : pairPx > 0.95
                        ? handleLinearInterp(pairPx, 0.96, 0.04, 0.95, 0.06)
                        : pairPx === 0.95
                          ? 0.06
                          : pairPx > 0.94
                            ? handleLinearInterp(pairPx, 0.95, 0.06, 0.94, 0.12)
                            : pairPx === 0.94
                              ? 0.12
                              : pairPx > 0.93
                                ? handleLinearInterp(pairPx, 0.94, 0.12, 0.93, 0.18)
                                : pairPx === 0.93
                                  ? 0.18
                                  : pairPx > 0.92
                                    ? handleLinearInterp(pairPx, 0.93, 0.18, 0.92, 0.21)
                                    : pairPx === 0.92
                                      ? 0.21
                                      : pairPx > 0.91
                                        ? handleLinearInterp(pairPx, 0.92, 0.21, 0.91, 0.27)
                                        : pairPx === 0.91
                                          ? 0.27
                                          : pairPx > 0.9
                                            ? handleLinearInterp(pairPx, 0.91, 0.27, 0.9, 0.3)
                                            : pairPx === 0.9
                                              ? 0.3
                                              : pairPx > 0.8
                                                ? handleLinearInterp(pairPx, 0.9, 0.3, 0.8, 0.4)
                                                : 0.4
    total += tierAmt * tierFactor
  }
  if (hedgingVolume > 5_000_000) {
    const tierAmt = Math.min(hedgingVolume, 10_000_000) - 5_000_000
    const tierFactor = pairPx >= 0.995
      ? 0.015
      : pairPx > 0.99
        ? handleLinearInterp(pairPx, 0.995, 0.015, 0.99, 0.02)
        : pairPx === 0.99
          ? 0.02
          : pairPx > 0.98
            ? handleLinearInterp(pairPx, 0.99, 0.02, 0.98, 0.03)
            : pairPx === 0.98
              ? 0.03
              : pairPx > 0.97
                ? handleLinearInterp(pairPx, 0.98, 0.03, 0.97, 0.04)
                : pairPx === 0.97
                  ? 0.04
                  : pairPx > 0.96
                    ? handleLinearInterp(pairPx, 0.97, 0.04, 0.96, 0.05)
                    : pairPx === 0.96
                      ? 0.05
                      : pairPx > 0.95
                        ? handleLinearInterp(pairPx, 0.96, 0.05, 0.95, 0.1)
                        : pairPx === 0.95
                          ? 0.1
                          : pairPx > 0.94
                            ? handleLinearInterp(pairPx, 0.95, 0.1, 0.94, 0.15)
                            : pairPx === 0.94
                              ? 0.15
                              : pairPx > 0.93
                                ? handleLinearInterp(pairPx, 0.94, 0.15, 0.93, 0.21)
                                : pairPx === 0.93
                                  ? 0.21
                                  : pairPx > 0.92
                                    ? handleLinearInterp(pairPx, 0.93, 0.21, 0.92, 0.24)
                                    : pairPx === 0.92
                                      ? 0.24
                                      : pairPx > 0.91
                                        ? handleLinearInterp(pairPx, 0.92, 0.24, 0.91, 0.3)
                                        : pairPx === 0.91
                                          ? 0.3
                                          : pairPx > 0.9
                                            ? handleLinearInterp(pairPx, 0.91, 0.3, 0.9, 0.3)
                                            : pairPx === 0.9
                                              ? 0.3
                                              : pairPx > 0.8
                                                ? handleLinearInterp(pairPx, 0.9, 0.3, 0.8, 0.4)
                                                : 0.4
    total += tierAmt * tierFactor
  }
  if (hedgingVolume > 10_000_000) {
    const tierAmt = Math.min(hedgingVolume, 30_000_000) - 10_000_000
    const tierFactor = pairPx >= 0.995
      ? 0.02
      : pairPx > 0.99
        ? handleLinearInterp(pairPx, 0.995, 0.02, 0.99, 0.03)
        : pairPx === 0.99
          ? 0.03
          : pairPx > 0.98
            ? handleLinearInterp(pairPx, 0.99, 0.03, 0.98, 0.04)
            : pairPx === 0.98
              ? 0.04
              : pairPx > 0.97
                ? handleLinearInterp(pairPx, 0.98, 0.04, 0.97, 0.05)
                : pairPx === 0.97
                  ? 0.05
                  : pairPx > 0.96
                    ? handleLinearInterp(pairPx, 0.97, 0.05, 0.96, 0.06)
                    : pairPx === 0.96
                      ? 0.06
                      : pairPx > 0.95
                        ? handleLinearInterp(pairPx, 0.96, 0.06, 0.95, 0.12)
                        : pairPx === 0.95
                          ? 0.12
                          : pairPx > 0.94
                            ? handleLinearInterp(pairPx, 0.95, 0.12, 0.94, 0.18)
                            : pairPx === 0.94
                              ? 0.18
                              : pairPx > 0.93
                                ? handleLinearInterp(pairPx, 0.94, 0.18, 0.93, 0.24)
                                : pairPx === 0.93
                                  ? 0.24
                                  : pairPx > 0.92
                                    ? handleLinearInterp(pairPx, 0.93, 0.24, 0.92, 0.3)
                                    : pairPx === 0.92
                                      ? 0.3
                                      : pairPx > 0.91
                                        ? handleLinearInterp(pairPx, 0.92, 0.3, 0.91, 0.3)
                                        : pairPx === 0.91
                                          ? 0.3
                                          : pairPx > 0.9
                                            ? handleLinearInterp(pairPx, 0.91, 0.3, 0.9, 0.3)
                                            : pairPx === 0.9
                                              ? 0.3
                                              : pairPx > 0.8
                                                ? handleLinearInterp(pairPx, 0.9, 0.3, 0.8, 0.4)
                                                : 0.4
    total += tierAmt * tierFactor
  }
  if (hedgingVolume > 30_000_000) {
    const tierAmt = Math.min(hedgingVolume, 50_000_000) - 30_000_000
    const tierFactor = pairPx >= 0.995
      ? 0.03
      : pairPx > 0.99
        ? handleLinearInterp(pairPx, 0.995, 0.03, 0.99, 0.04)
        : pairPx === 0.99
          ? 0.04
          : pairPx > 0.98
            ? handleLinearInterp(pairPx, 0.99, 0.04, 0.98, 0.05)
            : pairPx === 0.98
              ? 0.05
              : pairPx > 0.97
                ? handleLinearInterp(pairPx, 0.98, 0.05, 0.97, 0.06)
                : pairPx === 0.97
                  ? 0.06
                  : pairPx > 0.96
                    ? handleLinearInterp(pairPx, 0.97, 0.06, 0.96, 0.07)
                    : pairPx === 0.96
                      ? 0.07
                      : pairPx > 0.95
                        ? handleLinearInterp(pairPx, 0.96, 0.07, 0.95, 0.15)
                        : pairPx === 0.95
                          ? 0.15
                          : pairPx > 0.94
                            ? handleLinearInterp(pairPx, 0.95, 0.15, 0.94, 0.21)
                            : pairPx === 0.94
                              ? 0.21
                              : pairPx > 0.93
                                ? handleLinearInterp(pairPx, 0.94, 0.21, 0.93, 0.27)
                                : pairPx === 0.93
                                  ? 0.27
                                  : pairPx > 0.92
                                    ? handleLinearInterp(pairPx, 0.93, 0.27, 0.92, 0.3)
                                    : pairPx === 0.92
                                      ? 0.3
                                      : pairPx > 0.91
                                        ? handleLinearInterp(pairPx, 0.92, 0.3, 0.91, 0.3)
                                        : pairPx === 0.91
                                          ? 0.3
                                          : pairPx > 0.9
                                            ? handleLinearInterp(pairPx, 0.91, 0.3, 0.9, 0.3)
                                            : pairPx === 0.9
                                              ? 0.3
                                              : pairPx > 0.8
                                                ? handleLinearInterp(pairPx, 0.9, 0.3, 0.8, 0.4)
                                                : 0.4
    total += tierAmt * tierFactor
  }
  if (hedgingVolume > 50_000_000) {
    const tierAmt = Math.min(hedgingVolume, 80_000_000) - 50_000_000
    const tierFactor = pairPx >= 0.995
      ? 0.04
      : pairPx > 0.99
        ? handleLinearInterp(pairPx, 0.995, 0.04, 0.99, 0.05)
        : pairPx === 0.99
          ? 0.05
          : pairPx > 0.98
            ? handleLinearInterp(pairPx, 0.95, 0.04, 0.98, 0.06)
            : pairPx === 0.98
              ? 0.06
              : pairPx > 0.97
                ? handleLinearInterp(pairPx, 0.98, 0.06, 0.97, 0.07)
                : pairPx === 0.97
                  ? 0.07
                  : pairPx > 0.96
                    ? handleLinearInterp(pairPx, 0.97, 0.07, 0.96, 0.08)
                    : pairPx === 0.96
                      ? 0.08
                      : pairPx > 0.95
                        ? handleLinearInterp(pairPx, 0.96, 0.08, 0.95, 0.17)
                        : pairPx === 0.95
                          ? 0.17
                          : pairPx > 0.94
                            ? handleLinearInterp(pairPx, 0.95, 0.17, 0.94, 0.27)
                            : pairPx === 0.94
                              ? 0.27
                              : pairPx > 0.93
                                ? handleLinearInterp(pairPx, 0.94, 0.27, 0.93, 0.3)
                                : pairPx === 0.93
                                  ? 0.3
                                  : pairPx > 0.92
                                    ? handleLinearInterp(pairPx, 0.93, 0.3, 0.92, 0.3)
                                    : pairPx === 0.92
                                      ? 0.3
                                      : pairPx > 0.91
                                        ? handleLinearInterp(pairPx, 0.92, 0.3, 0.91, 0.3)
                                        : pairPx === 0.91
                                          ? 0.3
                                          : pairPx > 0.9
                                            ? handleLinearInterp(pairPx, 0.91, 0.3, 0.9, 0.3)
                                            : pairPx === 0.9
                                              ? 0.3
                                              : pairPx > 0.8
                                                ? handleLinearInterp(pairPx, 0.9, 0.3, 0.8, 0.4)
                                                : 0.4
    total += tierAmt * tierFactor
  }
  if (hedgingVolume > 80_000_000) {
    const tierAmt = Math.min(hedgingVolume, 120_000_000) - 80_000_000
    const tierFactor = pairPx >= 0.995
      ? 0.05
      : pairPx > 0.99
        ? handleLinearInterp(pairPx, 0.995, 0.05, 0.99, 0.06)
        : pairPx === 0.99
          ? 0.06
          : pairPx > 0.98
            ? handleLinearInterp(pairPx, 0.95, 0.06, 0.98, 0.07)
            : pairPx === 0.98
              ? 0.07
              : pairPx > 0.97
                ? handleLinearInterp(pairPx, 0.98, 0.07, 0.97, 0.08)
                : pairPx === 0.97
                  ? 0.08
                  : pairPx > 0.96
                    ? handleLinearInterp(pairPx, 0.97, 0.08, 0.96, 0.12)
                    : pairPx === 0.96
                      ? 0.12
                      : pairPx > 0.95
                        ? handleLinearInterp(pairPx, 0.96, 0.12, 0.95, 0.2)
                        : pairPx === 0.95
                          ? 0.2
                          : pairPx > 0.94
                            ? handleLinearInterp(pairPx, 0.95, 0.2, 0.94, 0.3)
                            : pairPx === 0.94
                              ? 0.3
                              : pairPx > 0.93
                                ? handleLinearInterp(pairPx, 0.94, 0.3, 0.93, 0.3)
                                : pairPx === 0.93
                                  ? 0.3
                                  : pairPx > 0.92
                                    ? handleLinearInterp(pairPx, 0.93, 0.3, 0.92, 0.3)
                                    : pairPx === 0.92
                                      ? 0.3
                                      : pairPx > 0.91
                                        ? handleLinearInterp(pairPx, 0.92, 0.3, 0.91, 0.3)
                                        : pairPx === 0.91
                                          ? 0.3
                                          : pairPx > 0.9
                                            ? handleLinearInterp(pairPx, 0.91, 0.3, 0.9, 0.3)
                                            : pairPx === 0.9
                                              ? 0.3
                                              : pairPx > 0.8
                                                ? handleLinearInterp(pairPx, 0.9, 0.3, 0.8, 0.4)
                                                : 0.4
    total += tierAmt * tierFactor
  }
  if (hedgingVolume > 120_000_000) {
    const tierAmt = hedgingVolume - 120_000_000
    const tierFactor = pairPx >= 0.995
      ? 0.3
      : pairPx > 0.99
        ? handleLinearInterp(pairPx, 0.995, 0.3, 0.99, 0.3)
        : pairPx === 0.99
          ? 0.3
          : pairPx > 0.98
            ? handleLinearInterp(pairPx, 0.995, 0.3, 0.98, 0.3)
            : pairPx === 0.98
              ? 0.3
              : pairPx > 0.97
                ? handleLinearInterp(pairPx, 0.98, 0.3, 0.97, 0.3)
                : pairPx === 0.97
                  ? 0.3
                  : pairPx > 0.96
                    ? handleLinearInterp(pairPx, 0.97, 0.3, 0.96, 0.3)
                    : pairPx === 0.96
                      ? 0.3
                      : pairPx > 0.95
                        ? handleLinearInterp(pairPx, 0.96, 0.3, 0.95, 0.3)
                        : pairPx === 0.95
                          ? 0.3
                          : pairPx > 0.94
                            ? handleLinearInterp(pairPx, 0.95, 0.3, 0.94, 0.3)
                            : pairPx === 0.94
                              ? 0.3
                              : pairPx > 0.93
                                ? handleLinearInterp(pairPx, 0.94, 0.3, 0.93, 0.3)
                                : pairPx === 0.93
                                  ? 0.3
                                  : pairPx > 0.92
                                    ? handleLinearInterp(pairPx, 0.93, 0.3, 0.92, 0.3)
                                    : pairPx === 0.92
                                      ? 0.3
                                      : pairPx > 0.91
                                        ? handleLinearInterp(pairPx, 0.92, 0.3, 0.91, 0.3)
                                        : pairPx === 0.91
                                          ? 0.3
                                          : pairPx > 0.9
                                            ? handleLinearInterp(pairPx, 0.91, 0.3, 0.9, 0.3)
                                            : pairPx === 0.9
                                              ? 0.3
                                              : pairPx > 0.8
                                                ? handleLinearInterp(pairPx, 0.9, 0.3, 0.8, 0.4)
                                                : 0.4
    total += tierAmt * tierFactor
  }
  return total
}
function getStablecoinDepegRiskForRiskUnit (
  riskUnit: OkxPortfolioMarginRiskUnit,
  contractDetails: Record<string, OkxContractDetails>,
  priceMap: Record<string, number>,
  spotInUseAmt: number
): number {
  const cashTypeToDelta: Record<string, number> = riskUnit.positions.reduce((acc: Record<string, number>, psn) => {
    if (psn.instType === 'SWAP' || psn.instType === 'FUTURES') {
      const ctDetails = contractDetails[psn.instId]
      const quoteCcy = getQuoteCurrencyForTicker(psn.instId)
      if (quoteCcy === 'USDC' || quoteCcy === 'USDT') {
        const cashPx = priceMap[`${quoteCcy}-USD`]
        const cashDelta = ctDetails.size * ctDetails.mult * psn.markPrice * cashPx * psn.amt
        acc[quoteCcy] = (acc[quoteCcy] ?? 0) + cashDelta
      }
    }
    // TODO: Handle Options
    return acc
  }, {})
  if (spotInUseAmt !== 0) {
    const cashDelta = spotInUseAmt * priceMap[`${riskUnit.ccy}-USD`]
    cashTypeToDelta.USD = (cashTypeToDelta.USD ?? 0) + cashDelta
  }
  const usdtUsdHedgingVolume = getHedgingVolume(
    cashTypeToDelta.USDT ?? 0,
    cashTypeToDelta.USD ?? 0
  )
  const usdtUsdResult = getPairContribution(
    usdtUsdHedgingVolume,
    priceMap['USDT-USD']
  )
  const usdtUsdcHedgingVolume = getHedgingVolume(
    cashTypeToDelta.USDT ?? 0,
    cashTypeToDelta.USDC ?? 0
  )
  const usdtUsdcResult = getPairContribution(
    usdtUsdcHedgingVolume,
    priceMap['USDT-USD'] / priceMap['USDC-USD']
  )
  const usdcUsdHedgingVolume = getHedgingVolume(
    cashTypeToDelta.USDC ?? 0,
    cashTypeToDelta.USD ?? 0
  )
  const usdcUsdResult = getPairContribution(
    usdcUsdHedgingVolume,
    priceMap['USDC-USD']
  )
  return usdtUsdResult + usdtUsdcResult + usdcUsdResult
}

export function getSpotInUseAmountForRiskUnit (
  riskUnit: OkxPortfolioMarginRiskUnit,
  contractToDetails: Record<string, OkxContractDetails>,
  relevantBalance: OkxPortfolioMarginBalance
): number {
  const equity = relevantBalance.balance + riskUnit.positions.filter(psn => getSettlementCurrencyForTicker(psn.instId) === riskUnit.ccy).reduce((acc, psn) => {
    return acc + psn.upl
  }, 0)
  const derivativesDelta = riskUnit.positions.reduce((acc, psn) => {
    const ctDetails = contractToDetails[psn.instId]
    return acc + ctDetails.size * ctDetails.mult * psn.amt // TODO: Add in delta here
  }, 0)
  const maxSpotInUse = relevantBalance.maxSpotInUse === undefined || relevantBalance.maxSpotInUse === null
    ? Math.abs(equity)
    : Math.abs(relevantBalance.maxSpotInUse)
  if (equity > 0 && derivativesDelta < 0) {
    return Math.min(Math.abs(equity), Math.abs(derivativesDelta), maxSpotInUse)
  } else if (equity < 0 && derivativesDelta > 0) {
    return -1 * Math.min(Math.abs(equity), Math.abs(derivativesDelta), maxSpotInUse)
  } else {
    return 0
  }
}

function getOkxDerivitivesMaintenanceMarginRequirementForRiskUnit (
  riskUnit: OkxPortfolioMarginRiskUnit,
  contractToDetails: Record<string, OkxContractDetails>,
  typeToFees: Record<'FUTURES' | 'SWAP' | 'OPTION', OkxFeeRates>,
  priceMap: Record<string, number>,
  timestamp: number,
  relevantBalance?: OkxPortfolioMarginBalance
): number {
  const spotInUseAmt = relevantBalance === undefined ? 0 : getSpotInUseAmountForRiskUnit(riskUnit, contractToDetails, relevantBalance)
  const spotShock = getSpotShockForRiskUnit(riskUnit, contractToDetails, priceMap, spotInUseAmt)
  const extremeMove = getExtremeMoveForRiskUnit(riskUnit, contractToDetails, priceMap, spotInUseAmt)
  const basisRisk = getBasisRiskForRiskUnit(riskUnit, contractToDetails, priceMap, timestamp, spotInUseAmt)
  const adjustedMinimumCharge = getAdjustedMinimumChargeForRiskUnit(riskUnit, contractToDetails, typeToFees)
  const stablecoinDepegRisk = getStablecoinDepegRiskForRiskUnit(riskUnit, contractToDetails, priceMap, spotInUseAmt)
  // TODO: Options Handling (vega, theta, etc.)
  return Math.max(
    Math.max(spotShock, extremeMove) + basisRisk + stablecoinDepegRisk,
    adjustedMinimumCharge
  )
}

export function getOkxDerivitivesMaintenanceMarginRequirement (
  config: OkxPortfolioMarginConfig,
  timestamp: number
): number {
  const totalDerivitvesMaintenanceMarginRequirement = config.riskUnits.reduce((acc, ru) => {
    const balance = config.balances.find(bal => bal.ccy === ru.ccy)
    const maintenanceMarginRequirement = getOkxDerivitivesMaintenanceMarginRequirementForRiskUnit(
      ru, config.contractToDetails, config.typeToFees, config.priceMap, timestamp, balance)
    return acc + maintenanceMarginRequirement
  }, 0)
  return totalDerivitvesMaintenanceMarginRequirement
}

export function overallHaircutForCurrency (balance: number, tiers: OkxCurrencyEquityTier[]): number {
  if (balance <= 0) return 1
  const activeTiers = tiers.filter(tier => balance >= tier.minAmount)
  const totalEquityInCcy = activeTiers.reduce((acc, tier) => {
    const tierBalance = tier.maxAmount === undefined || tier.maxAmount === null
      ? balance - tier.minAmount
      : Math.min(balance, tier.maxAmount) - tier.minAmount
    const tierEquity = tierBalance * tier.discountRate
    return acc + tierEquity
  }, 0)
  return totalEquityInCcy / balance
}

export function overallMmrForCurrency (absEquity: number, tiers: OkxCurrencyBorrowTier[]): number {
  const activeTiers = tiers.filter(tier => absEquity >= tier.minSz)
  const totalMmrInCcy = activeTiers.reduce((acc, tier) => {
    const tierBalance = tier.maxSz === undefined || tier.maxSz === null
      ? absEquity - tier.minSz
      : Math.min(absEquity, tier.maxSz) - tier.minSz
    const tierMmr = tierBalance * tier.mmr
    return acc + tierMmr
  }, 0)
  return totalMmrInCcy / absEquity
}
