import { useEffect, useRef } from 'react'

export const useAutoRefresh = (
  refreshInterval: number,
  refreshCallback: () => void,
  dependencies: unknown[] = [],
  userRefreshSignal?: unknown
): void => {
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const dependencyRefs = useRef(dependencies.map(createRef))

  useEffect(() => {
    dependencyRefs.current = dependencies.map(createRef)

    if (timerRef.current !== null) {
      clearInterval(timerRef.current)
    }
    const newTimer = setInterval(refreshCallback, refreshInterval)
    timerRef.current = newTimer

    refreshCallback()

    return () => {
      clearInterval(newTimer)
    }
  }, [refreshInterval, refreshCallback, ...dependencies, userRefreshSignal])
}

function createRef<T> (value: T): T {
  if (typeof value === 'object' && value !== null) {
    return JSON.parse(JSON.stringify(value))
  }
  return value
}
