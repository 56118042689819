import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import {
  type AlertDelivery,
  AlertDeliveryMethod,
  configApi,
  usePermissions
} from '@r40cap/alerts-sdk'

import type { DeliveryRow, InputType } from './types'
import AlertDeliveriesTable from './AlertDeliveriesTable'
import { isApiError } from '../../../utils/errors'
import { getModalContent } from './utils'

function AlertDeliveries (props: {
  refreshSignal: boolean
}): React.JSX.Element {
  const { refreshSignal } = props
  const [rows, setRows] = useState<DeliveryRow[]>([])
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)
  const { userId } = usePermissions()
  const { showSnackbar } = useRequestSnackbar()
  const navigate = useNavigate()

  const {
    data: deliveriesData,
    isFetching: deliveriesIsFetching,
    refetch: deliveriesRefetch,
    isError: deliveriesIsError,
    error: deliveriesError
  } = configApi.useGetAlertDeliveriesQuery(undefined)
  const [postEditMutation] = configApi.useEditAlertDeliveryMutation()

  useEffect(() => {
    deliveriesRefetch()
      .catch((error) => { console.error(error) })
  }, [refreshSignal])

  const handleEdit = (value: any, deliveryId: string, property: keyof AlertDelivery): void => {
    const deliveryToEdit = (deliveriesData?.data ?? []).find(
      (del) => del.deliveryId === deliveryId
    )
    if (deliveryToEdit !== undefined) {
      const editedDelivery: Record<string, any> = { ...deliveryToEdit }
      editedDelivery[property] = value
      postEditMutation({ edit: editedDelivery as AlertDelivery })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Edits Pushed',
              status: 'success'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }

  useEffect(() => {
    const newRows = (deliveriesData?.data ?? []).map((del) => {
      return {
        id: del.deliveryId,
        method: del.deliveryMethod === AlertDeliveryMethod.SlackChannel
          ? 'Slack'
          : del.deliveryMethod === AlertDeliveryMethod.SlackDM
            ? 'Slack DM'
            : del.deliveryMethod === AlertDeliveryMethod.Telegram
              ? 'Telegram Message'
              : 'Call',
        cohort: del.cohort === undefined || del.cohort === null ? undefined : del.cohort.name,
        methodId: del.methodId
      }
    })
    setRows(newRows)
  }, [deliveriesData?.data])

  if (userId === undefined || userId === null) {
    return <Box sx={{
      height: '100%',
      width: '100%',
      alignContent: 'center',
      justifyItems: 'center'
    }}>
      <Typography align='center' variant='h4'>Insufficient Permissions</Typography>
    </Box>
  }

  return (
    <Box sx={{ height: '100%' }}>
      <AlertDeliveriesTable
        rows={rows}
        isFetching={deliveriesIsFetching}
        handleOpenEdit={
          (
            itemId: string,
            inputType: InputType,
            label: string,
            editProperty: keyof AlertDelivery
          ) => {
            setEditModalOpen(true)
            setEditModalContent(
              getModalContent(
                inputType,
                label,
                editProperty,
                handleEdit,
                itemId,
                () => { setEditModalOpen(false) }
              )
            )
          }
        }
        proposeDelete={(deliveryId: string) => { navigate(`/alerts/alertDelivery/delete/${deliveryId}`) }}
        isError={deliveriesIsError}
        error={deliveriesError}
      />
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
      <Outlet />
    </Box>
  )
}

export default AlertDeliveries
