import { AlertsType, type AlertsTypeOption } from './types'

export const alertsTypeOptions: AlertsTypeOption[] = [
  {
    display: 'Margin',
    type: AlertsType.MARGIN
  },
  {
    display: 'Aggregate Exposure',
    type: AlertsType.AGGREGATE_EXPOSURE
  },
  {
    display: 'Asset Exposure',
    type: AlertsType.ASSET_EXPOSURE
  },
  {
    display: 'FCM Limit',
    type: AlertsType.FCM_LIMIT
  },
  {
    display: 'P&L',
    type: AlertsType.PNL
  },
  {
    display: 'Price',
    type: AlertsType.PRICE
  },
  {
    display: 'Deliveries',
    type: AlertsType.ALERT_DELIVERY
  },
  {
    display: 'Cohorts',
    type: AlertsType.COHORT
  }
]
