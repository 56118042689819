export enum AlertsType {
  MARGIN = 'margin',
  AGGREGATE_EXPOSURE = 'aggregateExposure',
  ASSET_EXPOSURE = 'assetExposure',
  FCM_LIMIT = 'fcmLimit',
  PNL = 'pnl',
  PRICE = 'price',
  ALERT_DELIVERY = 'alertDelivery',
  COHORT = 'cohort'
}

export interface AlertsTypeOption {
  type: AlertsType
  display: string
}
