import React, { useState } from 'react'
import { Box, Button, Stack, useTheme } from '@mui/material'
import { useRequestSnackbar } from '@r40cap/ui'
import { type User, UserInput } from '@r40cap/alerts-sdk'

function UserAddition (props: {
  addUser: (userId: string) => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const { addUser } = props
  const { showSnackbar } = useRequestSnackbar()
  const [user, setUser] = useState<User | null>(null)

  function handleSubmit (): void {
    if (user === null) {
      showSnackbar({
        isOpen: true,
        message: 'Delivery required',
        status: 'failure'
      })
    } else {
      addUser(user.userId)
    }
  }

  return (
    <Box sx={{ width: '25vw', padding: '20px' }}>
      <Stack direction={'column'} spacing={2}>
        <UserInput
          setUser={setUser}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          setNone={() => {}}
          allowNone={false}
          hiddenIds={[]}
        />
        <Button
          variant='contained'
          onClick={handleSubmit}
          style={{
            width: '100%',
            backgroundColor: palette.accent.main,
            color: palette.secondary.main
          }}
        >
          Add
        </Button>
      </Stack>
    </Box>
  )
}

export default UserAddition
