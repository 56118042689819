import { type ColumnDefinition } from '@r40cap/ui'
import type { AlertDelivery } from '@r40cap/alerts-sdk'

import { type DeliveryRow, InputType } from './types'

export const alertDeliveryColumns: Array<ColumnDefinition<DeliveryRow, InputType, AlertDelivery, string>> = [
  {
    label: 'Delivery Method',
    id: 'method',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Cohort',
    id: 'cohort',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    label: 'Method ID',
    id: 'methodId',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: InputType.STRING,
    editId: 'methodId',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]
