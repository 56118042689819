import React, { useMemo } from 'react'
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  useTheme
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import {
  type ColumnDefinition,
  type Order,
  ValueCell,
  stableSort,
  getComparator
} from '@r40cap/ui'
import type { AlgoPosition } from '@r40cap/algos-sdk'

import type { AlgoPositionRow } from '../types'

function BaseFxRow (props: {
  bottomRows: AlgoPosition[]
  order: Order
  orderBy: keyof AlgoPositionRow
  columns: Array<ColumnDefinition<AlgoPositionRow, any>>
  isOpen: boolean
  toggleOpen: () => void
  allowance?: number
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    bottomRows,
    columns,
    order,
    orderBy,
    isOpen,
    toggleOpen,
    allowance
  } = props

  const netQuantity = bottomRows.reduce((sum, current) => sum + (current.psn * current.multiplier), 0)
  const grossQuantity = bottomRows.reduce((sum, current) => sum + Math.abs(current.psn * current.multiplier), 0)
  const baseFxRowObject: AlgoPositionRow = {
    rowName: bottomRows.length === 0 ? 'Base FX Row got empty bottoms' : `\u00A0\u00A0\u00A0${bottomRows[0].baseFx}`,
    netQuantity,
    grossQuantity,
    quantityDecimals: 4,
    colorSign: allowance === undefined
      ? 0
      : Math.abs(netQuantity) <= allowance
        ? 0
        : netQuantity
  }

  const visibleRows = useMemo(
    () => {
      const rowObjs = bottomRows.map((row) => {
        return {
          rowName: `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${row.instrument}`,
          netQuantity: row.psn,
          grossQuantity: Math.abs(row.psn),
          quantityDecimals: 4,
          account: row.account,
          multiplier: row.multiplier,
          colorSign: row.psn
        }
      })
      return stableSort(rowObjs, getComparator(order, orderBy))
    },
    [order, orderBy, bottomRows]
  )

  return (
    <>
      <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
        <TableCell sx={{ padding: 1 }}>
          <Box alignItems={'center'}>
            <IconButton
              aria-label="expand row"
              size="small"
              disabled
            >
              <SubdirectoryArrowRightIcon fontSize='small'/>
            </IconButton>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={toggleOpen}
            >
              {
                isOpen
                  ? <KeyboardArrowUpIcon fontSize='small'/>
                  : <KeyboardArrowDownIcon fontSize='small'/>
              }
            </IconButton>
          </Box>
        </TableCell>
        {
          columns.map((column, idx) => (
            <ValueCell<AlgoPositionRow, any>
              column={column}
              item={baseFxRowObject}
              key={column.id}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
              dense
            />
          ))
        }
      </TableRow>
      {
        isOpen && visibleRows.map((row) => {
          return (
            <TableRow
              key={`${row.rowName}-${row.account}-${row.multiplier}`}
              sx={{ backgroundColor: palette.secondary.main }}
            >
              <TableCell sx={{ padding: 1 }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  disabled
                >
                  <SubdirectoryArrowRightIcon fontSize='small'/>
                </IconButton>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  disabled
                >
                  <SubdirectoryArrowRightIcon fontSize='small'/>
                </IconButton>
              </TableCell>
              {
                columns.map((column, columnIndex) => {
                  return (
                    <ValueCell<AlgoPositionRow, any>
                      key={columnIndex}
                      column={column}
                      item={row}
                      defaultTextColor={palette.tableBodyText.main}
                      redTextColor='red'
                      greenTextColor='green'
                      dense
                    />
                  )
                })
              }
            </TableRow>
          )
        })
      }
    </>
  )
}

export default BaseFxRow
