import React from 'react'
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material'
import {
  type ColumnDefinition,
  HeaderCell
} from '@r40cap/ui'

function AlgoBotterTableHeader<T> (props: {
  columns: Array<ColumnDefinition<T, any>>
  includeCheckAllBox: boolean
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void
  allSelected: boolean
  numSelected: number
  disabled: boolean
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    columns,
    includeCheckAllBox,
    handleSelectAll,
    allSelected,
    numSelected,
    disabled
  } = props

  return (
    <TableHead>
      <TableRow>
        {includeCheckAllBox && <TableCell
          sx={{
            backgroundColor: palette.background.default,
            justifyContent: 'start',
            padding: 1
          }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && !allSelected}
            checked={allSelected}
            onChange={handleSelectAll}
            style={{ color: palette.accent.main }}
            size="small"
            disabled={disabled}
          />
        </TableCell>}
        {columns.map((column, idx) => (
          <HeaderCell<T, any>
            column={column}
            isActiveSort={false}
            order={'desc'}
            sortHandler={() => {}}
            key={idx}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
            dense
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

export default AlgoBotterTableHeader
