import type { FilterPiece } from '../common/types'

export type BlotterOption = (
  'transactions' |
  'loans' |
  'unsettled' |
  'expenses' |
  'capitalCalls'
)

export interface BlotterSelectOption {
  type: BlotterOption
  display: string
}

export enum TimingType {
  LIVE,
  TIME
}

export interface TimingOption {
  type: TimingType
  label: string
  value: string
}

export type BlotterFilterType = (
  'account' |
  'desk' |
  'strategy' |
  'instrument' |
  'baseFx' |
  'transactionSuperType'
)

export interface BlotterFilterPiece {
  type: BlotterFilterType
  piece: FilterPiece
}
