import type { AlertThreshold } from '@r40cap/alerts-sdk'

export interface AlertRow {
  id: string
  account: string
  cooldownMinutes: number
  stateType: string
  side: string
  measurement: string
  params?: string
  thresholds: AlertThreshold[]
  thresholdSortDirection: 'asc' | 'desc'
}

export enum InputType {
  STRING,
  FLOAT,
  POS_FLOAT,
  POS_INTEGER,
  ACCOUNT,
  ALERT_TYPE,
  ALERT_SIDE,
  DELIVERY_METHOD
}

export type RowKeysExceptThresholds = Exclude<keyof AlertRow, 'thresholds'>
