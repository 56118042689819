import React, { useMemo } from 'react'
import { Button, IconButton, Stack, Typography, useTheme } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { stableSort, getComparator } from '@r40cap/ui'
import { AlertDeliveryMethod, type AlertThreshold } from '@r40cap/alerts-sdk'

function AlertThresholdsCellContent (props: {
  thresholds: AlertThreshold[]
  proposeDeleteAlert: () => void
  proposeDeleteThreshold: (thresholdId: string) => void
  proposeAddThreshold: () => void
  formatThreshold: (thresh: number) => string
  sortDirection: 'asc' | 'desc'
}): React.JSX.Element {
  const {
    thresholds,
    proposeDeleteAlert,
    proposeDeleteThreshold,
    proposeAddThreshold,
    formatThreshold,
    sortDirection
  } = props
  const { palette } = useTheme()

  const visibleThresholds = useMemo(
    () => stableSort(thresholds, getComparator(sortDirection, 'threshold')),
    [sortDirection, thresholds]
  )

  return (
    <Stack direction='column' spacing={1} alignItems={'center'} width={'80%'}>
      <Stack direction={'row'} spacing={1} width={'100%'}>
        <Button
          onClick={proposeAddThreshold}
          variant='outlined'
          sx={{
            width: '50%',
            color: palette.accent.main,
            borderColor: palette.accent.main,
            padding: '0px',
            fontSize: 9
          }}
        >
          Add Threshold
        </Button>
        <Button
          variant='outlined'
          onClick={proposeDeleteAlert}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            '&:disabled': {
              opacity: 0.5,
              color: palette.background.default
            },
            '&:hover': {
              opacity: 0.9,
              backgroundColor: palette.accent.main
            },
            width: '50%',
            fontSize: 9
          }}
        >
          Delete Alert
        </Button>
      </Stack>
      {
        visibleThresholds.map((threshold) => {
          const methodDescription = threshold.delivery.deliveryMethod === AlertDeliveryMethod.SlackChannel
            ? 'Slack'
            : threshold.delivery.deliveryMethod === AlertDeliveryMethod.SlackDM
              ? 'Slack DM'
              : threshold.delivery.deliveryMethod === AlertDeliveryMethod.Telegram
                ? 'Fink GPT'
                : 'Call'
          const recipientDescription = threshold.delivery.deliveryMethod === AlertDeliveryMethod.SlackChannel
            ? threshold.delivery.methodId ?? 'undefined'
            : threshold.delivery.cohort?.name ?? 'undefined'
          return (
            <Stack
              key={threshold.thresholdId}
              direction={'row'}
              spacing={2}
              sx={{
                backgroundColor: palette.tertiary.main,
                padding: '5px',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%'
              }}
            >
              <Typography sx={{
                fontSize: 12,
                color: palette.tableBodyText.main
              }}>{`${formatThreshold(threshold.threshold)} => ${methodDescription} - ${recipientDescription}`}</Typography>
              <IconButton onClick={() => { proposeDeleteThreshold(threshold.thresholdId) }}>
                <DeleteIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Stack>
          )
        })
      }
    </Stack>
  )
}

export default AlertThresholdsCellContent
