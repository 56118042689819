import { type ColumnDefinition } from '@r40cap/ui'
import type { MarginAlert } from '@r40cap/alerts-sdk'

import { type AlertRow, InputType } from './types'

export const marginAlertColumns: Array<ColumnDefinition<AlertRow, InputType, MarginAlert, string>> = [
  {
    label: 'Account',
    id: 'account',
    columnWidthPercentage: 8,
    overflowType: 'scroll',
    inputType: InputType.ACCOUNT,
    editId: 'accountId',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Type',
    id: 'stateType',
    columnWidthPercentage: 5,
    overflowType: 'scroll',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Side',
    id: 'side',
    columnWidthPercentage: 4,
    overflowType: 'scroll',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Measurement',
    id: 'measurement',
    columnWidthPercentage: 6,
    overflowType: 'scroll',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Params',
    id: 'params',
    columnWidthPercentage: 6,
    overflowType: 'scroll',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Cooldown',
    id: 'cooldownMinutes',
    columnWidthPercentage: 4,
    overflowType: 'scroll',
    inputType: InputType.POS_INTEGER,
    editId: 'cooldownMinutes',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: ' min'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]
