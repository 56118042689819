import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Stack
} from '@mui/material'
import { Modal } from '@r40cap/ui'
import { type Algo } from '@r40cap/algos-sdk'

import AlgoBotterTable from './AlgoBlotterTable'
import AlgosControlsPanel from './AlgosControlsPanel'
// import WorkingSpreadsDisplay from './WorkingSpreadsDisplay'

function AlgoBlotterModal (props: {
  algos?: Algo[]
}): React.JSX.Element {
  const { algos } = props
  const { algoId } = useParams()
  const navigate = useNavigate()
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const [isRawBlotterView, setIsRawBlotterView] = useState<boolean>(false)
  const [activeAlgo, setActiveAlgo] = useState<Algo | null>(algos?.find((algo) => algo.algoId === algoId) ?? null)

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/algos/positions')
    }
  }

  useEffect(() => {
    if (algoId === undefined || algos === undefined) {
      setActiveAlgo(null)
    } else {
      setActiveAlgo(algos.find((algo) => algo.algoId === algoId) ?? null)
    }
  }, [algos, algoId])

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box sx={{
        width: '90vw',
        height: '90vh',
        justifyItems: 'center'
      }}
      >
        <Stack
          spacing={1}
          direction={'column'}
          sx={{
            padding: '40px',
            height: 'calc(100% - 80px)',
            width: 'calc(100% - 80px)'
          }}
        >
          <Box sx={{ height: '7%' }}>
            <AlgosControlsPanel
              refreshFunction={() => { setRefreshSignal(!refreshSignal) }}
              isRawBlotterView={isRawBlotterView}
              setIsRawBlotterView={setIsRawBlotterView}
              includeViewSelection={false}
            />
          </Box>
          <Box sx={{ height: activeAlgo === null ? '93%' : '85%' }}>
            <AlgoBotterTable
              algos={algos}
              refreshSignal={refreshSignal}
              isRawBlotterView={isRawBlotterView}
              algoId={algoId}
            />
          </Box>
        </Stack>
      </Box>
    </Modal>
  )
}

export default AlgoBlotterModal
