import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { Stack } from '@mui/material'
import { useAuth, type AuthContextType, authApi } from '@r40cap/auth'
import { type MenuOption, NavBar } from '@r40cap/ui'
import { usePermissions as useAlertsPermissions } from '@r40cap/alerts-sdk'
import { usePermissions as useAlgoPermissions } from '@r40cap/algos-sdk'

import {
  Alerts,
  Algos,
  Blotter,
  Glossary,
  Portfolio,
  Pricing,
  Reconciliation,
  Login,
  Pnl,
  Loading
} from './pages'
import pages from './data/pages.json'
import { getMode } from './features/theme/themeSlice'
import { ThemeToggle } from './features/theme/ThemeToggle'
import { ReportButton } from './features/reports/ReportButton'
import ToRiskButton from './components/utils/ToRiskButton'

function userCanAccess (menuOption: MenuOption, authConext: AuthContextType): boolean {
  switch (menuOption.slug) {
    case 'portfolio':
      return (
        authConext.hasPositionAccess as boolean ||
        authConext.hasBalanceAccess as boolean ||
        authConext.hasLiabilityAccess as boolean
      )
    case 'pnl':
      return authConext.hasPnlAccess
    case 'blotter':
      return authConext.hasBlotterAccess
    case 'reconciliation':
      return authConext.hasReconciliationAccess
    case 'pricing':
      return authConext.hasPricingAccess
    case 'glossary':
      return authConext.hasGlossaryAccess
    default:
      return (
        authConext.hasPositionAccess as boolean ||
        authConext.hasBalanceAccess as boolean ||
        authConext.hasLiabilityAccess as boolean ||
        authConext.hasPnlAccess
      )
  }
}

function PageHandler (): React.JSX.Element {
  const { palette } = useTheme()
  const mode = useSelector(getMode)

  const { data, error, isLoading, refetch } = authApi.useIsAuthenticatedQuery({})

  useEffect(() => {
    if (data !== undefined) {
      authConext.login(
        data.userId,
        data.isAdminVerified,
        data.restrictedDeskId ?? undefined,
        data.restrictedBlotterDeskId ?? undefined,
        data.hasPositionAccess,
        data.hasBalanceAccess,
        data.hasPnlAccess,
        data.hasLiabilityAccess,
        data.hasBlotterAccess,
        data.hasReconciliationAccess,
        data.hasRiskAccess,
        data.hasPricingAccess,
        data.hasGlossaryAccess,
        data.hasReportAccess,
        data.hasRiskAppAccess
      )
      if (data.status === 'Authenticated') {
        authConext.otpVerify(true)
      }
    } else if (error !== undefined) {
      authConext.otpVerify(false)
    }
  }, [data, error])

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
        .unwrap()
        .catch(() => {
          authConext.otpVerify(false)
          console.warn('Session Expired')
        })
    }, 2 * 60 * 1000)
    return () => { clearInterval(interval) }
  }, [refetch])

  const authConext = useAuth()
  const alertsPermissions = useAlertsPermissions()
  const algoPermissions = useAlgoPermissions()

  if (isLoading as boolean) {
    return (
      <div style={{ backgroundColor: palette.background.paper }}>
        <Loading />
      </div>
    )
  }
  if (!(authConext.otpVerified as boolean)) {
    return (
      <div style={{ backgroundColor: palette.background.paper }}>
        <Login />
      </div>
    )
  } else {
    return (
      <div style={{ backgroundColor: palette.background.paper }}>
        <NavBar
          pages={pages.filter((menuOption) => {
            return menuOption.slug === 'alerts'
              ? alertsPermissions.userId !== undefined && alertsPermissions.userId !== null
              : menuOption.slug === 'algos'
                ? algoPermissions.username !== undefined && algoPermissions.username !== null
                : userCanAccess(menuOption, authConext)
          })}
          mode={mode}
          activeColor={palette.accent.main}
          nonActiveColor={palette.tableBodyText.main}
          color={palette.primary.main}
          logoColor={palette.logoColor.main}
        >
          <Stack direction='row' spacing={1}>
            {
              authConext.hasReportAccess as boolean && <ReportButton />
            }
            {
              authConext.hasRiskAppAccess as boolean && <ToRiskButton />
            }
            <ThemeToggle
              disabled={false}
            />
          </Stack>
        </NavBar>
        <Routes>
          <Route path='/' element={<Navigate to={'portfolio'} replace />}/>
          <Route path='portfolio/*' element={<Portfolio />} />
          <Route path='pnl' element={<Pnl />} />
          <Route path='blotter/*' element={<Blotter />} />
          <Route path='reconciliation/*' element={<Reconciliation />} />
          <Route path='alerts/*' element={<Alerts />} />
          <Route path='algos/*' element={<Algos />} />
          <Route path='pricing' element={<Pricing />} />
          <Route path='glossary/*' element={<Glossary />} />
          <Route path='/*' element={<Navigate to={'portfolio'} replace />}/>
        </Routes>
      </div>
    )
  }
}

export default PageHandler
